import React, { useState, useEffect, useContext } from 'react';
import apiClient from '../../../ApiClient'; // Adjust the import path if necessary
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import defaultImage from '../../../../assets/images/default_user.jpg'; // Import the same default image
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Loader from '../../../Common/Loader';
import { CustomToastContainer } from '../../../CustomToastContainer';

const CompanyJobSeekers = () => {
  const [posts, setPosts] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { user } = useContext(AuthContext);
  const userId = user?.company_id;

  useEffect(() => {
    if (userId) {
      fetchCompanyJobPosts();
    }
  }, [userId]);

  const fetchCompanyJobPosts = async () => {
    setLoading(true)

    try {
      const response = await apiClient.post('/getAllJobProfiles', { command: 'getAllJobProfiles' })
      console.log(response)
      if (response.data.status === 'success') {
        const posts = response.data.response;
        posts.sort((a, b) => b.profile_created_on - a.profile_created_on);
        setPosts(posts);

        const urlsObject = {};
        await Promise.all(
          posts.map(async (post) => {
            if (post.fileKey) {
              try {
                const res = await apiClient.post('/getObjectSignedUrl', {
                  command: 'getObjectSignedUrl',
                  key: post.fileKey,
                });
                const img_url = res.data; // Adjust based on how the signed URL is returned
                if (img_url) {
                  urlsObject[post.user_id] = img_url;
                }
              } catch (error) {
                console.error('Error getting signed URL for post:', error);
              }
            }
          })
        );
        setImageUrls(urlsObject);
      } else {
        console.log('API Error:', response.data.status_message);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false)
    }
  };

  const navigateToDetails = (candidate) => {
    const imageUrl = imageUrls[candidate.user_id] || defaultImage;
    navigate(`/company-dashboard/profile/my-post-jobs-seekers-details/${candidate.user_id}`, { state: { posts: candidate, imageUrl } });
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="mx-4 min-h-screen  md:mx-auto px-4">
      
      <Helmet>
        <title>BME Bharat | Job seekers</title>
        <meta property="og:title" content="BME Bharat | Job seekers"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/profile/my-post-jobs-seekers" />
      </Helmet>
      <h1 className="custom-h1 my-5 text-center">
        Job Seekers
      </h1>
      {posts.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {posts.map((candidate) => (
            <div
              key={candidate.user_id}
              className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-shadow cursor-pointer"
              onClick={() => navigateToDetails(candidate)}
            >
              <div className='flex justify-center items-center mx-auto'>
                <img
                  src={imageUrls[candidate.user_id] || defaultImage}
                  onContextMenu={(e) => e.preventDefault()}
                  alt="Candidate"
                  className="w-32 h-32 shadow-md mb-4 rounded-full object-contain"
                />
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col justify-start mb-2">
                  <span className="font-bold text-lg text-[16px]">{candidate.first_name}{candidate.last_name}</span>
                  <span className="text-sm text-gray-500 -mt-1">{candidate?.user_category}</span>
                </div>
                <div className="flex justify-start mb-2">
                  <span className="font-bold w-28">Expert in</span>
                  <span>: {candidate.expert_in || 'NULL'}</span>
                </div>
                <div className="flex justify-start mb-2">
                  <span className="font-bold w-28">Experience</span>
                  <span>: {candidate.work_experience || 'NULL'}</span>
                </div>
                <div className="flex justify-start mb-2">
                  <span className="font-bold w-28">City</span>
                  <span>: {candidate.city || 'NULL'}</span>
                </div>



                <div className=' flex space-x-2 '>
                  <button
                    className="custom-btn mt-4"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the parent click event
                      navigateToDetails(candidate); // Add this line to handle button click
                    }}
                  >
                    View More
                  </button>
                  <button
                    className=" custom-btn mt-4 mr-2"
                    onClick={() =>
                      window.open(
                        "https://wa.me/+918310491223",
                        "_blank",
                        "noopener noreferrer"
                      )
                    }
                  >
                    Contact
                  </button>

                </div>


              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No candidates found</p>
      )}
      <CustomToastContainer/>
    </div>
  );
};

export default CompanyJobSeekers;
