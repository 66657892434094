import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaPen, FaTrash } from "react-icons/fa";
import { AuthContext } from "../../../AuthContext";
import apiClient from "../../../ApiClient";
import { Helmet } from "react-helmet";
import { CustomToastContainer } from "../../../CustomToastContainer";

const CompanyMyPostedJobs = () => {
  const [posts, setPosts] = useState([]);
  const { user } = useContext(AuthContext);
  const userId = user?.company_id;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanyJobPosts = async () => {
      if (!userId) return;

      try {
        const response = await apiClient.post(
          "/getCompanyAllJobPosts",
          {
            command: "getCompanyAllJobPosts",
            company_id: userId,
          }
        );
        // console.log("getCompanyAllJobPosts" , response)
        if (response.data.status === "success") {
          setPosts(response.data.response);
          toast.success(response.data.status_message);
        } else {
          toast.error(response.data.errorMessage);
        }
      } catch (error) {
        toast.error("Error fetching job posts");
      }
    };

    fetchCompanyJobPosts();
  }, [userId]);

  const handleEditPress = (post) => {
    navigate("/company-dashboard/profile/my-post-jobs-edit", { state: { post } });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const navigateTojobpost = () => {
    navigate('/company-dashboard/jobs/user-job-post')
  }

  const handleDelete = (post) => {
    setPostToDelete(post.post_id);
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    setShowDeleteConfirmation(false);

    try {
      const response = await apiClient.post(
        "/deleteJobPost",
        {
          command: "deleteJobPost",
          company_id: userId,
          post_id: postToDelete,
        }
      );
      // console.log(response)
      // console.log( postToDelete.company_id,postToDelete)
      if (response.data.status === "success") {
        setPosts((prevPosts) => prevPosts.filter((p) => p.post_id !== postToDelete));
        toast.success("Job Post Deleted Successfully");
      } else {
        toast.error(response.data.errorMessage);
        prompt(response.data.errorMessage)
      }
    } catch (error) {
      toast.error("Error deleting job post");
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setPostToDelete(null);
  };

  if (posts.length === 0) {
    return <div className="flex min-h-[60vh] flex-col items-center my-5">
      <p className="text-center text-[16px]">No job has been posted yet.</p>
      <button
        onClick={navigateTojobpost}
        className="custom-btn"
      >
        Post a job
      </button>
    </div>
  }

  return (
    <div className="p-6 mt-10 max-w-full min-h-screen">
      <Helmet>
        <title>BME Bharat | Posted jobs</title>
        <meta property="og:title" content="BME Bharat | Posted jobs"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/profile/my-post-jobs" />
      </Helmet>
      {/* Job Post Cards */}
      <div className="max-w-5xl grid grid-cols-1 sm:grid-cols-2 gap-8 mx-auto">
        {posts.map((post) => (
          <div key={post.post_id} className="bg-white  p-3 md:p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center">
            <div className="flex-1">
              <h4 className="text-[16px] font-bold mb-3">{post.job_title || "No Title"}</h4>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Experience</span>
                <span>: {post.experience_required || "Not Specified"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Industry type</span>
                <span>: {post.industry_type || "Not Specified"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Description</span>
                <span>: {post.job_description || "Not Specified"}</span>
              </div>

              <div className="mt-4 flex gap-4">
                <button
                  onClick={() => navigate('/company-dashboard/profile/my-post-jobs-apply', { state: { post } })}
                  className="custom-btn"
                >
                  All applications
                </button>

              </div>
            </div>
            <div className="flex justify-start md:align-top  space-x-5 mt-3 md:mt-0">
              <button onClick={() => handleEditPress(post)} className="btn-custom mb-5 md:mb-0">
                <FaPen className="" size={18} />
              </button>
              <button onClick={() => handleDelete(post)} className="btn-custom mb-5 md:mb-0">
                <FaTrash size={18} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg">
            <p>Are you sure you want to delete this job post?</p>
            <div className="flex space-x-4 mt-4">
              <button onClick={confirmDelete} className="custom-btn">
                Yes
              </button>
              <button onClick={cancelDelete} className="custom-btn">
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <CustomToastContainer />
    </div>
  );
};

export default CompanyMyPostedJobs;