import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import dummy_pic from '../../assets/images/default_user.jpg';
import { stateCityData } from '../Data';
import apiClient from '../ApiClient';
import { AuthContext } from '../AuthContext';
import { TrialContext } from '../TrialContext';
import LoaderButton from '../Common/LoaderButton';
import {CustomToastContainer} from '../CustomToastContainer';
import axios from 'axios';

const UserSignupScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [defaultDate, setDefaultDate] = useState('');
  const { selectedCategory, selectedProfile, fullPhoneNumber } = location.state;
  const { setTrialVisible } = useContext(TrialContext);
  const { login } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    collage: '',
    selectedState: '',
    selectedCity: '',
    gender: '',
    dateOfBirth: '',
    imageUri: '',
  });

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [loading, setLoading] = useState(false);

  const states = Object.keys(stateCityData);
  const cities = formData.selectedState ? stateCityData[formData.selectedState] : [];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        toast.error('File size exceeds 5 MB. Please upload a smaller image.');
        return;
      }

      setFormData({ ...formData, imageUri: URL.createObjectURL(selectedFile) });
      setFile(selectedFile);
      setFileType(selectedFile.type);
    }
  };

  const handleImagePreviewClick = () => {
    document.getElementById('image-upload-input').click();
  };

  const handleRemoveImage = () => {
    setFormData({ ...formData, imageUri: null });
    setFile(null);
    setFileType('');
  };

  const handleUploadFile = async () => {
    setLoading(true);
    if (!file) {
      return setFile(null);
    }
    const fileSize = file.size
    const fileType = file.type
    try {
      const res = await apiClient.post('/uploadFileToS3', {
        command: 'uploadFileToS3',
        headers: {
          'Content-Type': fileType,
          'Content-Length' : fileSize
        },
      });
      if (res.data.status === "success") {
        let url = res.data.url
        const uploadFile = await axios.put(url, file, {
          headers : {
            "Content-Type" : fileType
          }
        })
        if (uploadFile.status === 200) {
          setFile(file)
          // setFileKey(res.data.fileKey)
          // toast.success('File uploaded successfuly')
          return res.data.fileKey
        }
      } else {
        console.log(res.data.errorMessage)
        toast.error(res.data.errorMessage)
      }
    } catch (error) {
      toast.error('An error occurred during file upload.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
  
    // Detect operating system
    const isWindows = /Windows/.test(userAgent);
    const isMac = /Macintosh/.test(userAgent);
    const isLinux = /Linux/.test(userAgent);
    const isAndroid = /Android/.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/.test(userAgent);
  
    let os = 'Unknown OS';
    if (isWindows) os = 'Windows';
    else if (isMac) os = 'MacOS';
    else if (isLinux) os = 'Linux';
    else if (isAndroid) os = 'Android';
    else if (isIOS) os = 'iOS';
  
    // Detect browser
    const isChrome = /Chrome/.test(userAgent) && !/Edge/.test(userAgent);
    const isFirefox = /Firefox/.test(userAgent);
    const isSafari = /Safari/.test(userAgent) && !isChrome;
    const isEdge = /Edg/.test(userAgent);
  
    let browser = 'Unknown Browser';
    if (isChrome) browser = 'Chrome';
    else if (isFirefox) browser = 'Firefox';
    else if (isSafari) browser = 'Safari';
    else if (isEdge) browser = 'Edge';
  
    return {
      os,
      browser,
      userAgent,
    };
  };
  

  const createUserSession = async (user_id) => {
    const deviceInfo = getDeviceInfo();
    try {
      const res = await apiClient.post ("/createUserSession",{
        command : "createUserSession",
        user_id: user_id,
        deviceInfo : deviceInfo

      })

      if(res.data.status === "success") {
        localStorage.setItem("session_id",res.data.data.session_id)
      }
      
    } catch (error) {
      console.log("error while creating user session" , error)
    }
  }

  const formatDate = (dateString) => {
    // Assuming input date format is YYYY-MM-DD, you can format it like DD/MM/YYYY
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    setLoading(true);

      if (!formData?.firstName?.trim()) {
        toast.error("First Name is required.");
        setLoading(false);
        return;
      }

      if (!formData?.email?.trim()) {
        toast.error("Email id is required.");
        setLoading(false);
        return;
      }

    if (!formData?.selectedState) {
      toast.error("State is required.");
      setLoading(false);
      return;
    }

    if (!formData?.selectedCity) {
      toast.error("City is required.");
      setLoading(false);
      return;
    }

    if (!formData.gender) {
      toast.error("Select your gender");
      setLoading(false);
      return;
    }

    const dob = formatDate(formData?.dateOfBirth)

    try {
      const imageFileKey = await handleUploadFile();
      const payload = {
        command: 'signUpUsers',
        fileKey: imageFileKey,
        date_of_birth: dob ,
        user_phone_number: fullPhoneNumber,
        category: selectedCategory,
        select_your_profile: selectedProfile,
        user_email_id: formData.email,
        state: formData.selectedState,
        city: formData.selectedCity,
        first_name: formData.firstName,
        last_name: formData.lastName,
        college: formData.collage,
        gender: formData.gender
      };

      const res = await apiClient.post('/signUpUsers', payload);
      if (res.data.status === "success") {
        toast.success('Successfully signed up!');
        createUserSession(res.data.user_details.user_id)
        setTimeout(() => {
          setTrialVisible(true);
          login(res.data.user_details);
          navigate('/user-dashboard');
        }, 2000); 
        window.scrollTo(0, 0);
      } else {
        toast.error(res.data.errorMessage);
      }
    } catch (error) {
      toast.error('Error submitting the form');
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };


  // date of birth config..

  const today = new Date();

  const minDate = new Date();
  minDate.setFullYear(today.getFullYear() - 13);
  const minAgeDate = minDate.toISOString().split('T')[0];
 

  useEffect(() => {
    const defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() - 13); 
    setDefaultDate(defaultDate.toISOString().split('T')[0]);
  }, []);

  return (
    <div className="max-w-2xl mx-auto mt-10 p-8 bg-white rounded-lg shadow-md">

      <h2 className="custom-h1 text-center mb-6">Sign Up</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-4 text-center">
          <img
            src={formData.imageUri || dummy_pic}
            alt="Profile"
            onContextMenu={(e) => e.preventDefault()}
            className="h-32 w-32 rounded-full object-cover mb-4 mx-auto cursor-pointer"
            onClick={handleImagePreviewClick}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageSelection}
            className="hidden"
            id="image-upload-input"
          />
          <p className="text-gray-500 ">Click to upload image (Max 5 MB)</p>
        
          {formData.imageUri && (
            <button
              type="button"
              onClick={handleRemoveImage}
              className="text-red-600 hover:underline"
            >
              Remove Image X
            </button>
          )}
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">First Name<span className="text-red-500"> *</span>
          </label>

          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"

          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Last Name </label>


          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Email <span className="text-red-500"> *</span></label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"


          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Gender <span className="text-red-500"> *</span></label>
          <div className="flex gap-4">
            <label>
              <input
                type="radio"
                name="gender"
                value="Male"
                onChange={handleInputChange}
                checked={formData.gender === 'Male'}
              />{' '}
              Male
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="Female"
                onChange={handleInputChange}
                checked={formData.gender === 'Female'}
              />{' '}
              Female
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Date of Birth</label>
          <input
        type="date"
        value={formData.dateOfBirth} 
        onChange={(e) => setFormData({ ...formData, dateOfBirth: e.target.value })}
        max={minAgeDate} 
        className="w-fit p-2 border border-gray-300 rounded-lg"
      />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">State<span className="text-red-500"> *</span></label>
          <select
            name="selectedState"
            value={formData.selectedState}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"

          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">City<span className="text-red-500"> *</span></label>
          <select
            name="selectedCity"
            value={formData.selectedCity}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"

          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Organisation / Institution</label>
          <input
            type="text"
            name="collage"
            value={formData.collage}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"

          />
        </div>

        <div className='flex justify-center items-center'>

          <button type="submit" className="mt-6 custom-btn" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <div className='flex justify-center items-center'>
                <LoaderButton />
                Submitting
              </div>
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </form>
      <CustomToastContainer />
    </div>
  );
};

export default UserSignupScreen;
