

import React, { useState, useEffect, useContext } from "react";
import {useNavigate } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../../ApiClient";
import CustomDropDownMenu from "../../../Common/CustomDropdown";
import {
  DomainStrengthType,
  EmploymentType,
  ExperienceType,
  LanguagesType,
  NoticePeriodType,
  SalaryType,
  industryType,
  ShiftType,
} from "../../../Data";
import { Helmet } from "react-helmet";
import { CustomToastContainer } from "../../../CustomToastContainer";
import axios from "axios";

const MyjobProfileEdit = () => {
  const history = useNavigate();
  const { user } = useContext(AuthContext);
  const userid = user.user_id;
  const navigate = useNavigate()

  const [pdfFile, setPdfFile] = useState(null);
  const [brochureKey, setBrochureKey] = useState(null);
  const [JobProfile, setJobProfile] = useState({});
  const [postData, setPostData] = useState({
    first_name: "",
    last_name: "",
    user_email_id: "",
    user_phone_number: "",
    // state: "",
    gender: "",
    industry_type: "",
    expert_in: "",
    domain_strength: "",
    // employment_type: "",
    work_experience: "",
    preferred_cities: "",
    // preferred_shift: "",
    preferred_salary: "",
    // notice_period: "",
    college: "",
    languages: "",
    resume_key: "",
  });

  useEffect(() => {
    const getJobProfiles = async () => {
      try {
        const response = await apiClient.post(
          "/getJobProfiles",
          {
            command: "getJobProfiles",
            user_id: userid,
          }
        );

        if (response.data.response && response.data.response[0]) {
          const profile = response.data.response[0];
          setJobProfile(profile);
          setPostData((prevState) => ({
            ...prevState,
            ...profile,
            resume_key: profile.resume_key || "",
          }));
        }
      } catch (error) {
        console.error("Error fetching job profiles:", error);
      }
    };

    if (userid) {
      getJobProfiles();
    }
  }, [userid]);

  const handleInputChange = (key, value) => {
    setPostData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file ) {
      if (file.size > 5 * 1024 * 1024) {
        // 200 KB limit
        toast.error("File size must be less than 5 MB.");
        return;
      }
      setPdfFile(file);
    }
  };

  const handleUploadFile = async (file) => {

    if (!file) {
      return;
    }
    const fileSize = file.size
    const fileType = file.type
    try {
      const res = await apiClient.post('/uploadFileToS3', {
        command: 'uploadFileToS3',
        headers: {
          'Content-Type': fileType,
          'Content-Length' : fileSize
        },
      });
      console.log("uploadFileToS3",res)
      if (res.data.status === "success") {
        let url = res.data.url
        const uploadFile = await axios.put(url, file, {
          headers : {
            "Content-Type" : fileType
          }
        })
        console.log("uploadFile", uploadFile)
        if (uploadFile.status === 200) {
          // toast.success('File uploaded successfuly')
          if (res.data.fileKey) {
           setBrochureKey(res.data.fileKey)
          }
          return res.data.fileKey
        }
      } else {
        console.log(res.data.errorMessage)
        toast.error(res.data.errorMessage)
      }
    } catch (error) {
      toast.error('An error occurred during file upload.');
      throw error;
    } 
  };
  
  const deleteFileFromS3 = async (key) => {
    try {
      const response = await apiClient.post('/deleteFileFromS3', {
        command: 'deleteFileFromS3',
        key: key,
      });
      // console.log(response)
    } catch (error) {
      console.error('Error deleting file:', error);
      return false; 
    }
  };


  const handlePostSubmission = async (event) => {
    event.preventDefault();
    if (!postData.domain_strength || !postData.work_experience || !postData.resume_key) {
      toast.error("All fields are required.");
      return;
    }

    if(pdfFile) {
      await deleteFileFromS3(JobProfile.resume_key)
    }

    
    try {
      const documentFileKey = pdfFile
        ? await handleUploadFile(pdfFile)
        : JobProfile.resume_key;
      const postPayload = {
        command: "updateJobProfile",
        user_id: userid,
        ...postData,
        resume_key: documentFileKey || JobProfile.resume_key,
      };
      // console.log(postPayload);
  
      const res = await apiClient.post("/updateJobProfile", postPayload);
      // console.log(res);
  
      if (res.data.status === "success") {
        toast.success("Your profile has been successfully updated.");
        setTimeout(() => {
          navigate("/user-dashboard/profile/my-job-profile");
        }, 2000);
      } else {
        toast.error("Job Profile not Exists!");
      }
    } catch (error) {
      toast.error("Failed to submit post.");
      console.error("Submission error:", error);
    }
  };
  
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this profile?")) {
      try {
        const response = await apiClient.post(
          "/deleteJobProfile",
          {
            command: "deleteJobProfile",
            user_id: userid,
          }
        );

        if (response.data.status === "success") {
          toast.success("Your job profile has been successfully deleted!");
          setTimeout(() => {
          navigate('/user-dashboard/profile/my-job-profile')
          }, 2000);
        } else {
          toast.error("Job profile not exists");
        }
      } catch (error) {
        toast.error("You don't have an internet connection");
      }
    }
  };

  const handleCancel = () => {
    let confirm = window.confirm ("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className="max-w-[1370px] w-full mt-10 p-4">
   <Helmet>
        <title>BME Bharat | Job profile</title>
        <meta property="og:title" content="BME Bharat | Job profile"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/create-job-profile" />
      </Helmet>
      
    <h1 className="custom-h1 my-8 text-center z-[1000]">
      Edit Your Job Profile
    </h1>
  
    <form
      className="max-w-[1370px] w-[80%] md:grid md:grid-cols-2 md:w-[80%] gap-10 mx-auto"
      onSubmit={handlePostSubmission}
    >
      <div>
        <div className="mb-4">
          <label htmlFor="first_name" className="block mb-2 font-medium text-gray-900">
            First Name
          </label>
          <input
            type="text"
            id="first_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={postData.first_name}
            onChange={(e) => handleInputChange("first_name", e.target.value)}
            readOnly
          />
        </div>
  
        <div className="mb-4">
          <label htmlFor="last_name" className="block mb-2 font-medium text-gray-900">
            Last Name
          </label>
          <input
            type="text"
            id="last_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={postData.last_name}
            onChange={(e) => handleInputChange("last_name", e.target.value)}
            readOnly
          />
        </div>
  
        <div className="mb-4">
          <label htmlFor="user_email_id" className="block mb-2 font-medium text-gray-900">
            Email ID
          </label>
          <input
            type="email"
            id="user_email_id"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={postData.user_email_id}
            onChange={(e) => handleInputChange("user_email_id", e.target.value)}
            readOnly
          />
        </div>
  
        <div className="mb-4">
          <label htmlFor="user_phone_number" className="block mb-2 font-medium text-gray-900">
            Phone Number
          </label>
          <input
            type="tel"
            id="user_phone_number"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={postData.user_phone_number}
            onChange={(e) => handleInputChange("user_phone_number", e.target.value)}
            readOnly
          />
        </div>

        <div className="mb-4">
          <label htmlFor="user_phone_number" className="block mb-2 font-medium text-gray-900">
            Gender
          </label>
          <input
            type="tel"
            id="user_phone_number"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={postData.gender}
            // onChange={(e) => handleInputChange("user_phone_number", e.target.value)}
            readOnly
          />
        </div>

        <div className="mb-4">
            <label htmlFor="industryType" className="block mb-2 font-medium text-gray-900">
              Industry Type
            </label>
            <CustomDropDownMenu
              items={industryType}
              value = {postData.industry_type}              
              lastSelected = {postData.industry_type}
              onSelect={(item) => handleInputChange("industry_type", item.label)}
              placeholder="Select industry type"
              id="industryType"
            />
          </div>

        <div className="mb-4">
            <label htmlFor="preferredCities" className="block mb-2 font-medium text-gray-900">
            Expert in
            </label>
            <input
              type="text"
              placeholder="Enter your expertise"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={postData.expert_in}
              onChange={(e) => handleInputChange("expert_in", e.target.value)}
              id="preferredCities"
            />
          </div>


  
  
        
        </div>

<div>
<div className="mb-4">
            <label htmlFor="workExperience" className="block mb-2 font-medium text-gray-900">
              Work Experience
            </label>
            <CustomDropDownMenu
              items={ExperienceType}
              value = {postData.work_experience}
              lastSelected = {postData.work_experience}

              onSelect={(item) => handleInputChange("work_experience", item.label)}
              placeholder="Select Work Experience"
              id="workExperience"
            />
          </div>

  
        <div className="mb-4">
          <label htmlFor="preferred_cities" className="block mb-2 font-medium text-gray-900">
            Preferred Cities
          </label>
          <input
            type="text"
            id="preferred_cities"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={postData.preferred_cities}
            onChange={(e) => handleInputChange("preferred_cities", e.target.value)}
          />
        </div>
  
  <div className="mb-4">
            <label htmlFor="preferredSalary" className="block mb-2 font-medium text-gray-900">
            Expected Salary (LPA)
            </label>
            <CustomDropDownMenu
              items={SalaryType}
              // value ={postData.preferred_salary}
              lastSelected = {postData.preferred_salary}
              onSelect={(item) => handleInputChange("preferred_salary", item.label)}
              placeholder="Select Expected Salary (LPA)"
              id="preferredSalary"
            />
          </div>
        <div className="mb-4">
          <label htmlFor="languages" className="block mb-2 font-medium text-gray-900">
            Languages Known
          </label>
          <input
            type="text"
            id="languages"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={postData.languages}
           
            onChange={(e) => handleInputChange("languages", e.target.value)}
          />
        </div>

        <div className="mb-4">
            <label htmlFor="domainStrength" className="block mb-2 font-medium text-gray-900">
              Domain Strength
            </label>
            <CustomDropDownMenu
              items={DomainStrengthType}
              value ={ postData.domain_strength}
              onSelect={(item) => handleInputChange("domain_strength", item.label)}
              lastSelected = {postData.domain_strength}

              placeholder="Select Domain Strength"
              id="domainStrength"
            />
          </div>
  
        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-900">Upload CV</label>
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleFileChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>
  
      <div className="flex gap-2">
        <button type="submit" className="custom-btn w-fit px-3">
          Update
        </button>
        <button type="button" className="custom-btn w-fit px-3" onClick={handleDelete}>
          Delete
        </button>
        <button className="custom-btn ml-2" onClick={()=>{
            handleCancel()
          }}>
            Cancel
          </button>
      </div>
    </form>
    <CustomToastContainer/>
  </div>
  
  );
};

export default MyjobProfileEdit;
