import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";
import CustomDropdown from "../../../Common/CustomDropdown";
import {
  ExperienceType,
  HireType,
  industryType,
  SalaryType,
} from "../../../Data";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../../ApiClient"; // Adjust the import path if necessary
import LoaderButton from "../../../Common/LoaderButton";
import { Helmet } from "react-helmet";
import { CustomToastContainer } from "../../../CustomToastContainer";

const AdminPostedJobsEdit = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { post } = location.state || {};
  const navigate = useNavigate();

  const [jobEditFormData, setJobEditFormData] = useState({
    job_title: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    city: "",
    state: "",
    preferred_languages: "",
    industry_type: "",
    job_description: "",
    experience_required: "",
    speicializations_required: "",
    Package: "",
    required_expertise:""


  });

  const isFormValid = () => {
    const { job_title, preferred_languages, job_description, experience_required, speicializations_required, Package,industry_type,required_expertise } = jobEditFormData;
    return (
        job_title.trim() !== "" &&
        preferred_languages.trim() !== "" &&
        job_description.trim() !== "" &&
        experience_required.trim() !== "" &&
        speicializations_required.trim() !== "" &&
        Package.trim() !== "" &&
        industry_type.trim() !== ""&&
        required_expertise.trim() !== ""
    );
};

  useEffect(() => {
   
    if (post) {

      setJobEditFormData({
        job_title: post.job_title || "",
        firstName: post.firstName || "",
        lastName: post.lastName || "",
        phoneNumber: post.phoneNumber || "",
        email: post.email || "",
        city: post.city || "",
        state: post.state || "",
        preferred_languages: post.preferred_languages || "",
        industry_type: post.industry_type || "",
        job_description: post.job_description || "",
        experience_required: post.experience_required || "",
        speicializations_required: post.speicializations_required || "",
        Package: post.Package || "",
        required_expertise: post.required_expertise || "",
      });
    }
  }, [post]);

  const handleChange = (key, value) => {
    setJobEditFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const JobEditHandle = async () => {
    setLoading(true);
    if (!isFormValid()) {
      toast.error('All fields are required.');
    setLoading(false);
      return;
  }
    try {
      const response = await apiClient.post("/updateAJobPost", {
        command: "updateJobPost",
        company_id: post?.company_id,
        post_id: post?.post_id,
        ...jobEditFormData,
      });
      // console.log(jobEditFormData)
      console.log(response)
      if(response.data.status == "success"){
        toast.success(response.data.status_message);
        setTimeout(() => {
        navigate("/admin-dashboard/jobs/admin_posted_jobs");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        "Network Error: " +
          (error.response?.data?.errorMessage ||
            error.message ||
            "An error occurred")
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    let confirm = window.confirm ("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className="flex flex-col items-center p-6 bg-white min-h-screen">
      
      <Helmet>
        <title>BME Bharat | Edit job</title>
        <meta property="og:title" content="BME Bharat | Edit job"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/admin-dashboard/profile/my-post-jobs-edit" />
      </Helmet>
      <form className="w-full max-w-lg">
        <h1 className="custom-h1 text-center my-3">Edit job</h1>

        <div className="mb-2">
  <label
    className="block text-gray-700"
    htmlFor="job_title"
    onClick={() => toast.info("Job title cannot be edited")}
  >
    Job Title
  </label>
  <div
    className="w-full px-4 py-2 border border-blue-500 rounded-lg cursor-not-allowed"
    onClick={() => toast.info("Job title cannot be edited")}
  >
    <input
      id="job_title"
      type="text"
      className="w-full bg-transparent cursor-not-allowed"
      placeholder="Job Title"
      value={jobEditFormData.job_title}
      onChange={(e) => handleChange("job_title", e.target.value)}
      disabled
    />
  </div>
</div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-900">
            Langauges required
          </label>
          <input
            id="lang"
            type="text"
            placeholder="Languages required (comma separated)"
            value={jobEditFormData.preferred_languages}
            onChange={(e) =>
              handleChange("preferred_languages", e.target.value)
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
        </div>

                {/* industry type Dropdown */}
                <div className="mb-2">
          <label className="block text-gray-700" htmlFor="Package">
            Industry type
          </label>
          <CustomDropdown
            id="industry_type"
            items={industryType}
            lastSelected={jobEditFormData.industry_type}
            placeholder="Select industry type"
            onSelect={(item) => handleChange("industry_type", item.label)}
          />
        </div>

            {/* Package Dropdown */}
        <div className="mb-2">
          <label className="block text-gray-700" htmlFor="Package">
            Package
          </label>
          <CustomDropdown
            id="Package"
            items={SalaryType}
            lastSelected={jobEditFormData.Package}
            placeholder="Select Package"
            onSelect={(item) => handleChange("Package", item.label)}
          />
        </div>
        

        
        {/* Experience Required Dropdown */}
        <div className="mb-2">
          <label className="block text-gray-700" htmlFor="experience_required">
            Experience Required
          </label>
          <CustomDropdown
            id="experience_required"
            items={ExperienceType}
            lastSelected={jobEditFormData.experience_required}

            placeholder="Select Experience Required"

            onSelect={(item) => handleChange("experience_required", item.label)}
          />
        </div>

        <div className="mb-2">
          <label
            className="block text-gray-700"
            htmlFor="speicializations_required"
          >
            Specializations required
          </label>
          <textarea
            id="speicializations_required"
            className="w-full px-4 py-2 border border-blue-500 rounded-lg h-32"
            placeholder="Specializations Required"
            value={jobEditFormData.speicializations_required}
            onChange={(e) =>
              handleChange("speicializations_required", e.target.value)
            }
          />
        </div>
        <div className="mb-2">
          <label
            className="block text-gray-700"
            htmlFor="speicializations_required"
          >
            Expertise required
          </label>
          <textarea
            id="Expertise required"
            className="w-full px-4 py-2 border border-blue-500 rounded-lg h-32"
            placeholder="Specializations Required"
            value={jobEditFormData.required_expertise}
            onChange={(e) =>
              handleChange("required_expertise", e.target.value)
            }
          />
        </div>

        <div className="mb-2">
          <label className="block text-gray-700" htmlFor="job_description">
            Job Description
          </label>
          <textarea
            id="job_description"
            className="w-full px-4 py-2 border border-blue-500 rounded-lg h-32"
            placeholder="Job Description"
            value={jobEditFormData.job_description}
            onChange={(e) => handleChange("job_description", e.target.value)}
          />
        </div>


        

        <div className="flex gap-2 mt-3">
        <button className="custom-btn" onClick={JobEditHandle} disabled={loading}>

{loading ? (
      <div className='flex justify-center items-center'>
        <LoaderButton />
        Updating
      </div>
    ) : (
      <>Update</>
    )}
  </button>
  <button className="custom-btn ml-2" onClick={(e)=>{
            e.stopPropagation()
            handleCancel()
          }}>
            Cancel
          </button>
        </div>
      </form>
      <CustomToastContainer/>
    </div>
  );
};

export default AdminPostedJobsEdit;
