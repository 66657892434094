import React, { useState, useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../../AuthContext";
import apiClient from "../../../ApiClient";
import defaultImage from "../../../../assets/images/defaultImage.jpg"
import { GrPowerReset } from "react-icons/gr";
import { Helmet } from "react-helmet";
import Loader from "../../../Common/Loader";
import { CustomToastContainer } from "../../../CustomToastContainer";

const JobDetails = () => {
  const location = useLocation();
  const {post_id} = useParams()
  const [isApplying, setIsApplying] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(``)
  const [fileKey, setFileKey] = useState();
  const [job, setJobs] = useState()

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(false)
      try {
        const response = await apiClient.post('/getAllJobPosts', {
          command: "getAllJobPosts",
        });
        const jobData = response.data.response.find(job => job.post_id === post_id);
        setJobs(jobData); 
        setFileKey(jobData?.fileKey); 

      } catch (error) {
        console.log('Error fetching jobs:', error);
      } finally {
        setLoading(false)
      }
    };
    fetchJobs();
  }, []);

  useEffect(() => {
    const handleProfileImage = async (profileKey) => {
      if (!profileKey) {
        return setImageUrl(defaultImage)
        
      }
      setLoading (true)
      try {
        const response = await apiClient.post("/getObjectSignedUrl", {
          command: "getObjectSignedUrl",
          key: profileKey,
        });
        if (response.status === 200) {
          setImageUrl(response.data || defaultImage);
        }
      } catch (error) {
        console.error("Error fetching profile URL:", error);
      }  finally {
        setLoading (false)
      }
    };
    handleProfileImage(fileKey);
  }, [fileKey]);


  if(loading) {
    return <Loader/>
  }

  if (!job) {
      return (
      <div className="flex flex-col items-center justify-center mt-10 gap-3">
        <p className='m-0 text-[16px]'>Job details not available.</p>
        <button onClick={() => navigate('/user-dashboard/jobs')} className='custom-btn'>All Jobs</button>
      </div>
    );
  }

  const applyForJob = async () => {
    if (!user) {
      toast.error("Please log in to apply for the job.");
      return;
    }
    // console.log(user.user_id);
    // console.log(job);
    setIsApplying(true);
    try {
      const res = await apiClient.post(
        "/applyJobs",
        {
          command: "applyJobs",
          company_id: job.company_id,
          user_id: user.user_id,
          job_title: job.job_title,
        }
      );
      // console.log(res.data);
      if (res.data.status === "success") {
        // console.log(res.data);
        toast.success("Job application successful!");
      } else {
        toast.error(
          res.data.errorMessage
        );
      }
    } catch (error) {
      toast.error("An error occurred while applying for the job.");
    } finally {
      setIsApplying(false);
    }
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    }).replace(/ /g, "/");
  };


  // preview zoom in out starts here ✅

  const openModal = () => {
    setModalImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage('');
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false);
  };

  const resetbtn = () => {
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false)
  }

  const handleZoom = (increment) => {
    setZoomLevel(prevZoom => Math.min(Math.max(prevZoom + increment, 1), 3));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startCoords.x;
      const deltaY = e.clientY - startCoords.y;
      setImagePosition(prevPosition => ({
        top: prevPosition.top + deltaY,
        left: prevPosition.left + deltaX,
      }));
      setStartCoords({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };



  // preview zoom in out ends here ✅


  const navigateToCompanyDetails = (company_id) => {
    navigate(`/user-dashboard/company/details/${company_id}`);
  };


  return (
    <div className="mt-6  flex items-center justify-center">
      <Helmet>
        <title>{job.job_title} - {job.company_name}</title>
        <meta property="og:title" content={`${job.job_title} - ${job.company_name}`}></meta>
        <meta property="og:description" content={job.job_description} />
        <meta property="og:url" content={`https://bmebharat.com/user-dashboard/company/details/${job.company_id}`} />
      </Helmet>
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-md p-8">



        <div className="flex items-center justify-center">
          <img
            src={imageUrl || defaultImage}
            alt="Job"
            onContextMenu={(e) => e.preventDefault()}
            className="w-full h-48  object-contain rounded-lg mb-6"
            onClick={() => {
              openModal()
            }}
          />
        </div>

        <h1 className="text-[16px] font-bold text-gray-800 mb-6">
          {job.job_title || "No Title"}
        </h1>

        <div className="space-y-4">
          <div className="flex flex-col justify-start mb-2 cursor-pointer" onClick={(e) => {
            e.stopPropagation()
            navigateToCompanyDetails(job.company_id)
          }}>
            <span className="font-bold text-lg">{job.company_name || "N/A"}</span>
            <span className="text-sm text-gray-500 -mt-1">{job?.category}</span>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-48">Package</span>
            <span>: {job.Package || "Not Specified"}</span>
          </div>

          <div className="flex justify-start mb-2">
            <span className="font-bold w-48">Location</span>
            <span>: {job.company_located_city || "Not Specified"}, {job.company_located_state || "Not Specified"}</span>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-48">Languages required</span>
            <span>: {job.preferred_languages || "Not Specified"}</span>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-48">Industry type</span>
            <span>: {job.industry_type || "Not Specified"}</span>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-48">Experience required</span>
            <span>: {job.experience_required || "Not Specified"}</span>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-48">Expertise required</span>
            <span>: {job.required_expertise || "Not Specified"}</span>
          </div>
          <div className="flex justify-start mb-2">
            <p  style={{ whiteSpace: "pre-wrap" }}> {job.job_description || "Not Specified"}</p>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-48">Specializations required</span>
            <span>: {job.speicializations_required || "Not Specified"}</span>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-48">Posted on</span>
            <span>: {formatDate(job.job_post_created_on) || "Not Specified"}</span>
          </div>
        </div>


        <div className="mt-8 flex items-center justify-center gap-2">
          <button
            onClick={applyForJob}
            disabled={isApplying}
            className={`custom-btn`}
          >
            {isApplying ? "Applying..." : "Apply for Job"}
          </button>
          <button
            onClick={() => {
              navigate(-1)
            }}
            className={`custom-btn`}
          >
            Back
          </button>
        </div>
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Enlarged view"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s',
                width: '90vw',
                height: '90vh',
                objectFit: 'contain',
                position: 'relative',
                top: imagePosition.top,
                left: imagePosition.left,
              }}
              className="cursor-pointer"
              onMouseDown={handleMouseDown}
            />
            <button
              className="absolute top-4 right-4 text-[35px] text-white  text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="md:absolute md:bottom-4 hidden md:left-1/2 md:transform md:-translate-x-1/2 md:flex md:space-x-4">
              <button
                onClick={() => handleZoom(0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom In +
              </button>
              <button
                onClick={() => handleZoom(-0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom Out -
              </button>
              <button
                onClick={() => resetbtn()}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                <GrPowerReset />
              </button>
            </div>
          </div>
        </div>
      )}
      <CustomToastContainer />
    </div>
  );
};

export default JobDetails;
