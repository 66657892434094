import React from 'react';
import { Helmet } from 'react-helmet';
import { FaHandsHelping, FaLock, FaComments, FaFingerprint } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Guidelines = () => {
  const navigate = useNavigate()
  return (
    <><div className="max-w-[900px] mx-auto p-5">
      <Helmet>
        <title>BME Bharat Guidelines </title>
        <meta property="og:title" content=">BME Bharat Guidelines"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/guidelines" />
      </Helmet>
      <h2 className="text-center custom-h1 ">Community guidelines</h2>
      <p className="my-5  text-justify">
        This Platform is a place for sharing knowledge, asking questions, and supporting each other in
        the field of biomedical engineering. Please keep these guidelines in mind to ensure a positive
        experience for everyone:
      </p>
      <div className="flex flex-col gap-5 justify-around">
        <div className="flex-1 bg-gray-100 rounded-lg p-4 shadow-md transition-transform duration-200 hover:translate-y-[-5px]">
          <h3 className="flex items-center text-blue-600 text-[20px]">
            <FaHandsHelping size={25} className="mr-2" /> Be Respectful:
          </h3>
          <p className="mt-2  ">Treat everyone with respect. Healthy debates are welcome, but kindness is required.</p>
        </div>
        <div className="flex-1 bg-gray-100 rounded-lg p-4 shadow-md transition-transform duration-200 hover:translate-y-[-5px]">
          <h3 className="flex items-center text-blue-600 text-[20px] ">
            <FaLock size={25} className="mr-2" /> No Spam:
          </h3>
          <p className="mt-2  ">Avoid posting promotional content or irrelevant links.</p>
        </div>
        <div className="flex-1 bg-gray-100 rounded-lg p-4 shadow-md transition-transform duration-200 hover:translate-y-[-5px]">
          <h3 className="flex items-center text-blue-600 text-[20px]">
            <FaComments size={25} className="mr-2" /> Stay on Topic:
          </h3>
          <p className="mt-2  ">Keep discussions relevant to biomedical engineering and related fields.</p>
        </div>
        <div className="flex-1 bg-gray-100 rounded-lg p-4 shadow-md transition-transform duration-200 hover:translate-y-[-5px]">
          <h3 className="flex items-center text-blue-600 text-[20px]">
            <FaFingerprint size={25} className="mr-2" /> Confidentiality:
          </h3>
          <p className="mt-2  ">Do not share personal or sensitive information about yourself or others.</p>
        </div>
      </div>
    </div><div className="flex w-full items-center justify-center my-4">
        <button
          className="custom-btn bg-white"
          onClick={() => {
            navigate(-1);
          } }
        >
          Back
        </button>
      </div></>
  );
}

export default Guidelines;
