import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CiSearch } from "react-icons/ci";
import defaultImage from "../../../../assets/images/defaultImage.jpg";
import apiClientAdmin from "../../../ApiClientAdmin";
import apiClient from "../../../ApiClient";
import { AuthContext } from "../../../AuthContext";
import { Helmet } from "react-helmet";
import Loader from "../../../Common/Loader";
import { CustomToastContainer } from "../../../CustomToastContainer";


const JobList = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobImageUrls, setJobImageUrls] = useState({});
  const [isApplying, setIsApplying] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState(new Set()); // Track applied jobs
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(false)
      try {
        const res = await apiClient.post('/getAllJobPosts', {
          command: "getAllJobPosts",
        });
        const jobsData = res.data.response || [];
        setJobs(jobsData);
        setFilteredJobs(jobsData);

        const urlsObject = {};
        await Promise.all(jobsData.map(async (job) => {
          if (job.fileKey) {
            try {
              const res = await apiClient.post('/getObjectSignedUrl', {
                command: 'getObjectSignedUrl',
                key: job.fileKey,
              });
              const img_url = await res.data;
              if (img_url) {
                urlsObject[job.post_id] = img_url;
              }
            } catch (error) {
              console.error('Error fetching image URL:', error);
            }
          }
        }));

        setJobImageUrls(urlsObject);
      } catch (error) {
        console.log('Error fetching jobs:', error);
      } finally {
        setLoading(false)
      }
    };
    fetchJobs();
  }, [refresh]);

  useEffect(() => {
    const results = jobs.filter((job) => {
      const jobTitle = job.job_title?.toLowerCase() || "";
      const industryType = job.industry_type?.toLowerCase() || "";
      return (
        jobTitle.includes(searchQuery.toLowerCase()) ||
        industryType.includes(searchQuery.toLowerCase())
      );
    });
    setFilteredJobs(results);
  }, [searchQuery, jobs]);


  const handleSearch = () => {
    if (jobs && jobs.length > 0) {
      const results = jobs.filter((job) => {
        const jobTitle = job.job_title?.toLowerCase() || "";
        const industryType = job.industry_type?.toLowerCase() || "";
        return (
          jobTitle.includes(searchQuery.toLowerCase()) ||
          industryType.includes(searchQuery.toLowerCase())
        );
      });
      setFilteredJobs(results);
    }
  };

  const navigateToCompanyDetails = (company_id) => {
    navigate(`/admin-dashboard/company/details/${company_id}`);
  };

  const navigateToDetails = (post_id) => {
    navigate(`/admin-dashboard/jobs/user-job-detail/${post_id}`);
  };

  const handleDelete = async (post) => {
    const confirm = window.confirm ("Are you sure you want to delete this job post")
    if (confirm) {
      try {
        const response = await apiClientAdmin.post(
          "/deleteJobPostFmAdmin",
          {
            command: "deleteJobPostFmAdmin",
            post_id: post.post_id,
          }
        );
        console.log(response)
        if (response.data.status === "success") {
          toast.success("Job Post Deleted Successfully");
          setRefresh(true)
        } else {
          toast.error(response.data.errorMessage);
        }
      } catch (error) {
        toast.error("Error deleting job post");
      }
    }
  
  };


  if (loading) {
    return <Loader />;
  }

  return (
    <div className="w-[100%] min-h-screen mx-auto py-2 px-4 mt-6 ">
      <Helmet>
        <title>BME Bharat | Jobs</title>
        <meta property="og:title" content="BME Bharat | Jobs"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/jobs" />
      </Helmet>
      <div className="flex justify-center mb-6">
        <div className="relative w-full max-w-sm">
          {!searchQuery && (
            <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
          )}
          <input
            type="text"
            className="w-full pl-10 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="      Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
        </div>
      </div>

      {filteredJobs.length > 0 ? (
        <>
          <p className=" mb-4 text-gray-600">
            {filteredJobs.length} jobs found
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {filteredJobs.map((job, index) => (
              <div
                key={index}
                className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-shadow cursor-pointer"
                onClick={() => {
                  navigateToDetails(job.post_id)
                }}
              >
                <img
                  src={jobImageUrls[job.post_id] || defaultImage}
                  alt={job.job_title}
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-full h-48 object-contain mb-3"
                />
                <div className="flex flex-col">
                  <h2 className="text-[16px] font-semibold text-gray-800 mb-2">
                    {job.job_title.slice(0, 25) || "N/A"} {job.job_title.length > 25 && ".."}
                  </h2>
                  <div className="inline-flex w-fit flex-col justify-start mb-2" onClick={(e)=> {
                      e.stopPropagation()
                      navigateToCompanyDetails(job.company_id)
                    }}>
                      <span className="font-bold text-lg">{job.company_name.slice(0, 25) || "N/A"} {job.company_name.length > 25 && ".."}</span>
                      <span className="text-sm text-gray-500 -mt-1">{job?.category}</span>
                    </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Package</span>
                    <span>: {job.Package || "N/A"}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Location</span>
                    <span>: {job.company_located_city || "N/A"}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Industry Type</span>
                    <span>: {job.industry_type.slice(0, 14) || "N/A"} {job.industry_type.length > 14 && ".."}</span>
                  </div>
                  <div className="flex justify-start space-x-3 mt-3">
                    <button
                      className="custom-btn"
                      onClick={(e) => {e.stopPropagation()
                        navigateToDetails(job.post_id)}}

                    >
                      View More
                    </button>
                    <button
                      className="custom-btn"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDelete(job)}}
                    >
                      Delete
                    </button>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="text-gray-500">No jobs found</p>
      )}
      <CustomToastContainer/>
    </div>
  );
};

export default JobList;
