import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomDropdown from '../Common/ProfileTypeDropdown';
import logo from "../../assets/images/logo-crop.png";
import { ToastContainer } from 'react-toastify';
import {CustomToastContainer} from "../CustomToastContainer"



const ProfileSelect = {
  "Biomedical Engineering Company Manufacturer": [
    "Diagnostic Equipment",
    "Wearable Health Tech",
    "Prosthetics and Implants",
    "Medical Devices",
    "Biotechnology Products",
    "Pharmaceuticals",
    "Laboratory Equipment",
    "Imaging Technology"
  ],
  "Dealer/Distributor": [
    "Medical Devices",
    "Laboratory Supplies",
    "Pharmaceuticals",
    "Healthcare IT Solutions",
    "Surgical Instruments",
    "Medical Imaging Devices",
    "Diagnostic Equipment",
    "Implantable Devices",
    "Wearable Health Monitors"
  ],
  "Biomedical Engineering Company - Service Provider": [
    "Equipment Maintenance",
    "Calibration Services",
    "Medical Imaging Services",
    "Biomedical Waste Management",
    "Installation Services",
    "Clinical Engineering Support",
    "Training and Education Services",
    "Telemedicine Services"
  ],
  "Healthcare Provider - Biomedical": [
    "Hospital Biomedical Department",
    "Clinical Lab",
    "Diagnostic Center",
    "Rehabilitation Center",
    "Home Healthcare"
  ],
  "Academic Institution - Biomedical": [
    "Biomedical Engineering Programs",
    "Research Institutions",
    "Training Centers",
    "Internship and Training Provider",
    "Healthcare Education",
    "Continuing Medical Education"
  ],
  "Regulatory Body": [
    "Medical Device Regulations",
    "Biomedical Ethics and Compliance",
    "Biotechnology Regulations",
    "Pharmaceutical Regulations",
    "Clinical Trial Oversight",
    "Quality Assurance"
  ],
  "Investor/Venture Capitalist": [
    "Medical Devices",
    "Biotechnology",
    "Pharmaceuticals",
    "Healthcare Startups",
    "Research and Development Funding"
  ],
  "Patient Advocate": [
    "Patient Education",
    "Patient Rights",
    "Healthcare Access",
    "Chronic Disease Advocacy",
    "Disability Support"
  ],
  "Healthcare IT Developer": [
    "Electronic Health Records (EHR)",
    "Telemedicine Solutions",
    "Healthcare Apps",
    "AI in Healthcare",
    "Data Analytics in Healthcare"
  ],
  "Biomedical Engineering Student": [
    "Undergraduate Student",
    "Graduate Student",
    "PhD Candidate",
    "Research Intern",
    "Project Collaborator"
  ],
  "Biomedical Engineering Professor/Academic": [
    "Lecturer",
    "Thesis Advisor",
    "Department Head",
    "Laboratory Director"
  ],
  "Biomedical Engineer": [
    "Research & Development Engineer",
    "Clinical Engineer",
    "Product Design Engineer",
    "Quality Assurance Engineer",
    "Regulatory Affairs Specialist",
    "Biomedical Engineer Sales/Service"
  ],
  "Biomedical Researcher/Scientist": [
    "Academic Researcher",
    "Industry Researcher",
    "Clinical Trials",
    "Innovation and Prototyping",
    "Medical Device Innovation",
    "Biomedical Research",
    "Clinical Research",
    "Biotechnology Research",
    "Pharmaceutical Research"
  ],
  "Consultant": [
    "Business Development Consulting",
    "Healthcare IT Consulting",
    "Regulatory Consulting",
    "Product Development Consulting",
    "Market Research Consulting",
    "Clinical Engineering Consulting",
    "Quality Assurance Consulting",
    "Medical Device Consulting"
  ],
  "Medical Professional": [
    "Decision Maker",
    "Doctor - Anaesthetist",
    "Doctor - Cardiologist"
  ],
  "Others": [
    "Others"
  ]
};

const ProfileType = () => {
  const navigate = useNavigate();
  const [userType, setUserType] = useState('');
  const [selectedProfile, setSelectedProfile] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const companyProfiles = Object.keys(ProfileSelect).filter(profile =>
    profile.includes("Company") ||
    profile.includes("Dealer") ||
    profile.includes("Provider") ||
    profile.includes("Regulatory") ||
    profile.includes("Investor") ||
    profile.includes("Advocate") ||
    profile.includes("Academic Institution") ||
    profile.includes("Healthcare IT Developer")
  );

  const normalProfiles = Object.keys(ProfileSelect).filter(profile =>
    !companyProfiles.includes(profile)
  );

  const handleUserTypeSelect = (event) => {
    const type = event.target.value;
    setUserType(type);
    setSelectedProfile('');
    setSelectedCategory('');
    setOpenDropdown(null);
  };

  const profiles = userType === 'company' ? [...companyProfiles, "Others"] : normalProfiles;
  const categories = selectedProfile && ProfileSelect[selectedProfile] ? ProfileSelect[selectedProfile] : [];

  const handleProfileSelect = (event) => {
    const profile = event.target.value;
    setSelectedProfile(profile);
    setSelectedCategory('');
    setOpenDropdown(null);
  };

  const handleCategorySelect = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSubmit = () => {
    if (userType && selectedProfile && selectedCategory) {
      navigate('/enter-phone', { state: { userType, selectedProfile, selectedCategory } });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <CustomToastContainer />
      <div className="bg-white rounded-lg px-8 shadow-md w-full max-w-md">
        <img src={logo} alt="Logo" onContextMenu={(e) => e.preventDefault()} className="mx-auto mb-2 h-[90px]" />
        <h2 className="custom-h1 text-[20px] my-8 text-center">Let's get started</h2>

        <label className="block mb-2">User Type</label>
        <select
          className="block w-full mb-4 p-2 border border-gray-300 rounded"
          onChange={handleUserTypeSelect}
          value={userType}
        >
          <option value="">Select User Type</option>
          <option value="normal">Individual</option>
          <option value="company">Business</option>
        </select>

        <label className="block mb-2">Profile</label>
        <select
          className="block w-full mb-4 p-2 border border-gray-300 rounded"
          onChange={handleProfileSelect}
          value={selectedProfile}
          disabled={!userType}
        >
          <option value="">Select Profile</option>
          {profiles.map(profile => (
            <option key={profile} value={profile}>{profile}</option>
          ))}
        </select>

        <label className="block mb-2">Category</label>
        <select
          className="block w-full mb-4 p-2 border border-gray-300 rounded"
          onChange={handleCategorySelect}
          value={selectedCategory}
          disabled={categories.length === 0}
        >
          <option value="">Select Category</option>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>

 
        <div className="flex items-center mt-4">
          <input
            type="checkbox"
            id="terms"
            checked={isTermsChecked}
            onChange={()=>{
              setIsTermsChecked(!isTermsChecked);
            }}
            className="mr-2"
          />
          <label htmlFor="terms" className="text-gray-700">
          Accept <a href="/privacypolicy" className="text-blue-500 no-underline">Privacy Policy</a> and <a href="/termsandconditions" className="text-blue-500 no-underline">Terms and Conditions</a>
          </label>
        </div>

        <div className='mx-auto flex items-center justify-center'>
          <button
            className={`mt-4 px-10 custom-btn text-bme_blue shadow-md ${!userType || !selectedProfile || !selectedCategory|| !isTermsChecked ? ' cursor-not-allowed' : ''}`}
            onClick={handleSubmit}
            disabled={!userType || !selectedProfile || !selectedCategory|| !isTermsChecked}
          > 
            Submit
          </button>
        </div>
        <p className="mt-2 justify-center text-center">
              Already have an account?<a href="/login" className='text-blue-500 no-underline'> Login </a>
            </p>
      </div>
    </div>
  );
};

export default ProfileType;