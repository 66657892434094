import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import { RiDeleteBin5Line } from "react-icons/ri";
import Banner01 from '../../User/Banner01';
import { toast } from 'react-toastify';
import LoaderButton from '../../../Common/LoaderButton';
import Loader from '../../../Common/Loader';
import { CustomToastContainer } from '../../../CustomToastContainer';
import apiClient from '../../../ApiClient';


const AdminHomeBanner = () => {
  const [bannerImages, setBannerImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingbtn, setLoadingbtn] = useState(false);
  const [error, setError] = useState(null);
  const [imageUri, setImageUri] = useState(null);
  const [fileType, setFileType] = useState('');
  const [fetchAgain, setFetchAgain] = useState(false)

  useEffect(() => {
    const fetchBannerImages = async () => {
      setLoading(true)
      try {
        const response = await apiClient.post(
          'getBannerImages',
          {
            command: 'getBannerImages',
            banners_id: '54321',
          }
        );

        if (response.data.status === 'success') {
          const bannerData = response.data.response;

          console.log(bannerData)

          const urlsObject = {};

          await Promise.all(
            bannerData.map(async (banner) => {
              const fileKeys = banner.files || [];

              await Promise.all(
                fileKeys.map(async (fileKey) => {
                  try {
                    const res = await apiClient.post(
                      'getObjectSignedUrl',
                      {
                        command: 'getObjectSignedUrl',
                        bucket_name: 'bme-app-admin-data',
                        key: fileKey,
                      }
                    );

                    const img_url = res.data;
                    if (img_url) {
                      urlsObject[fileKey] = img_url;
                    }
                  } catch (error) {
                    console.error('Error getting signed URL for banner:', error);
                  }
                })
              );
            })
          );

          setBannerImages(urlsObject);
        } else {
          console.log('API Error:', response.data.status_message);
        }
      } catch (error) {
        toast.error('Error fetching banner images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBannerImages();
    setFetchAgain(false)
  }, [fetchAgain]);

   // delete file from s3
   const deleteFileFromS3 = async (key) => {
    setLoading(true)
    try {
      const response = await apiClient.post('/deleteFileFromS3', {
        command: 'deleteFileFromS3',
        key: key,
        bucket_name : 'bme-app-admin-data'
      });
      // console.log(response)
    } catch (error) {
      console.error('Error deleting file:', error);
      return false;
    } finally {
      setLoading(false)
    }
  };

  const deleteBannerImage = async (key) => {
    setLoadingbtn(true)
    if (window.confirm('Are you sure you want to delete this banner image?')) {
      try {
        const response = await apiClient.post('deleteBannerImageFromS3', {
          command: 'deleteBannerImageFromS3',
          banners_id: '54321',
          key: key,
        });

        if (response.data.statusCode === 200) {
          deleteFileFromS3(key)
          setBannerImages((prevImages) => {
            const newImages = { ...prevImages };
            delete newImages[key];
            
            return newImages;
          });
          toast.success('Banner image deleted successfully!');
        } else {
          alert('Failed to delete banner image.');
        }
      } catch (error) {
        console.error('Error deleting banner image:', error);
        alert('An error occurred while deleting the image.');
      } finally {
        setLoadingbtn(false)
      }
    }
  };

  const selectImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUri(reader.result);
        setFileType(file.type);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (uri) => {
    setLoadingbtn(true)
    try {
      const base64 = uri.split(',')[1];
      console.log(base64)

      const response = await apiClient.post('uploadImages', {
        command: 'uploadImages',
        banners_id: '54321',
        fileBuffer: base64,
        headers: {
          'Content-Type': fileType || 'image/jpeg',
        },
      });
      console.log(response);
      const uploadResponse = response.data
      if (response.data.statusCode === 200) {
        toast.success('Image uploaded successfully!! refresh the page to preview');
        setFetchAgain(true)
        setImageUri(null)
      } else {
        console.error('Image upload failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error uploading image:', error.message);
    } finally {
      setLoadingbtn(false)
    }
  };

  if (loading) {
    return <Loader/>;
  }

  return (
    <div className="p-6 bg-white shadow-lg rounded-md">
      <h2 className="custom-h1 mb-6">Preview : Home Banner</h2>


    <Banner01/>
    <hr/>
      <h2 className="custom-h1 my-10">Upload Banner images</h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {Object.keys(bannerImages).map((item) => (
          <div key={item} className="relative mb-8">
            <img
              src={bannerImages[item]}
              alt="Banner"
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
            <button
              onClick={() => deleteBannerImage(item)}
              className="absolute -top-6 -right-4 "
            >
            <RiDeleteBin5Line style={{fill:"red-500"}} className='w-10 h-10 text-red-500' />
            </button>
          </div>
        ))}
      </div>

      <div className="mt-6">
        <h3 className="text-xl font-medium text-gray-700 mb-4">Upload New Banner</h3>

        <div className="flex space-x-4 mb-4">
          <input
            type="file"
            accept="image/*"
            onChange={selectImage}
            className="py-2 px-4 border border-gray-300 rounded-md"
          />
        </div>

        {imageUri && (
          <div className="flex flex-col items-center mt-4">
            <img
              src={imageUri}
              alt="Selected"
              className="w-96 h-72 object-cover rounded-lg mb-4"
            />
              <button type="submit" onClick={()=>{
                uploadImage(imageUri)
              }} className="custom-btn" disabled={loadingbtn}>
            {loadingbtn ? (
              <div className='flex justify-center items-center'>
                <LoaderButton />
                uploading
              </div>
            ) : (
              <>Upload Image</>
            )}
          </button>
          </div>
        )}
      </div>
      <CustomToastContainer/>

    </div>
  );
};

export default AdminHomeBanner;
