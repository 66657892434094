import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import defaultImage from "../../../../assets/images/defaultImage.jpg"
import { FaComment } from 'react-icons/fa';
import { GrPowerReset } from 'react-icons/gr';
import { Helmet } from 'react-helmet';
import apiClient from '../../../ApiClient';

const CompanyForumDetails = () => {
  const {forum_id} = useParams()
  const navigate = useNavigate()

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ top: 0, left: 0 });

  const [imageUrl, setImageUrl] = useState(``)
  const [fileKey, setFileKey] = useState();
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState();



  const fetchPosts = async () => {
    setLoading(true)
    try {
      // Fetch all forums from the API
      const response = await apiClient.post('/getAllForumPosts', {
        command: 'getAllForumPosts',
      });
      // console.log("getAllForumPosts",response)
      const forumData = response.data.response.find(post => post.forum_id === forum_id);
        setPost(forumData); 
      // console.log("forumData",forumData)
      // console.log(forumData.fileKey)
      if (forumData?.fileKey === null || forumData?.fileKey === "" || forumData?.fileKey === undefined) {
        setFileKey('')
      } else {
        setFileKey(forumData?.fileKey || " "); 
      }

        

    } catch (error) {
      console.error('Error fetching forum posts:', error);
    } finally {
      setLoading (false)
    }
  };

  useEffect(() => {

    fetchPosts();
  }, [forum_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [post]);

 

  useEffect(() => {
    const handleProfileImage = async (profileKey) => {
      if (!profileKey) return;
      setLoading (true)
      try {
        const response = await apiClient.post("/getObjectSignedUrl", {
          command: "getObjectSignedUrl",
          key: profileKey,
        });
        if (response.status === 200) {
          setImageUrl(response.data || defaultImage);
          // console.log("setImageUrl",response.data)
        }
      } catch (error) {
        console.error("Error fetching profile URL:", error);
      }  finally {
        setLoading (false)
      }
    };
    handleProfileImage(fileKey);
  }, [fileKey]);

  const isVideo = post?.fileKey && post?.fileKey?.endsWith('.mp4');


  const openModal = () => {
    setModalImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage('');
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false);
  };

  const resetbtn = () => {
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false)
  }

  const handleZoom = (increment) => {
    setZoomLevel(prevZoom => Math.min(Math.max(prevZoom + increment, 1), 3));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startCoords.x;
      const deltaY = e.clientY - startCoords.y;
      setImagePosition(prevPosition => ({
        top: prevPosition.top + deltaY,
        left: prevPosition.left + deltaX,
      }));
      setStartCoords({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const navigateToUserDetails = (user_id, user_type) => {
    const path = user_type === 'company'
      ? `/company-dashboard/company/details/${user_id}`
      : `/company-dashboard/users/user_details/${user_id}`;
    
    navigate(path);
  };



  return (
    <div className="p-6  min-h-screen">
      <Helmet>
        <title>{`${post?.forum_body?.slice(0,25)} - ${post?.author}`}</title>
        <meta property="og:title" content={`${post?.title}-${post?.author}`}></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content={`https://bmebharat.com/company-dashboard/forums/details/${forum_id}`} />

      </Helmet>
      <div className="max-w-4xl  mx-auto">
        <div className="bg-white border rounded-lg shadow-md p-6">

        <div className="mb-6 mx-auto flex justify-center posts-center">

           
            {isVideo ? (
              <video
                src={imageUrl || defaultImage}
                className="h-auto w-full object-fit rounded-lg shadow-md"
                controls
                muted
              >
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={imageUrl || defaultImage}
                alt={post?.title}
                onContextMenu={(e) => e.preventDefault()}
                className="h-auto w-full object-fit rounded-lg shadow-md"
                onClick={openModal}

              />
            )}
          </div>
      

          {/* Author and Date */}
          <div className="inline-flex flex-col justify-start mb-2 cursor-pointer" >
                <span onClick={(e)=> {
                      e.stopPropagation()
                      navigateToUserDetails(post?.user_id, post?.user_type)
                    }}
                     className="font-bold text-lg">{post?.author || "N/A"}</span>
                <span onClick={(e)=> {
                      e.stopPropagation()
                      navigateToUserDetails(post?.user_id, post?.user_type)
                    }} className="text-sm text-gray-500 -mt-1">{post?.author_category}</span>
              </div>

      

    
              <p className="mb-1 "  style={{ whiteSpace: "pre-wrap" }}>{post?.forum_body}</p>
                      
          <div className="flex justify-start mb-2">
            <span className=" w-24 text-sm text-gray-500">Posted on</span>
            <span className='text-sm text-gray-500'>: {post?.posted_on ? new Date(post?.posted_on * 1000).toLocaleDateString() : "N/A"}</span>
          </div>



          {/* Media (Image or Video) */}
          

          {/* Comments and Views */}
          <div className="flex justify-between items-center text-sm text-gray-500">
            <Link
              to={`/company-dashboard/forums/comments/${post?.forum_id}`}
              className="flex items-center text-blue-600 hover:text-blue-800 no-underline"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="flex items-center text-blue-500 mt-3">
                <FaComment className="mr-1 " /> {post?.comments_count || 0}{" "}
                Comments
              </span>
            </Link>
            <span>{post?.viewsCount || '0'} Views</span>
          </div>
        </div>
        <div className="w-full flex items-center justify-center mx-auto mt-3">
          <button className="custom-btn" onClick={() => {
            navigate('/company-dashboard/profile/my-forum-list')
          }}>
            Back
          </button>
        </div>
      </div>

      {/* modal for image preview with zoom in and out starts here ✅ */}

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Enlarged view"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s',
                width: '90vw',
                height: '90vh',
                objectFit: 'contain',
                position: 'relative',
                top: imagePosition.top,
                left: imagePosition.left,
              }}
              className="cursor-pointer"
              onMouseDown={handleMouseDown}
            />
            <button
              className="absolute top-4 right-4 text-[35px] text-white  text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="md:absolute md:bottom-4 hidden md:left-1/2 md:transform md:-translate-x-1/2 md:flex md:space-x-4">
              <button
                onClick={() => handleZoom(0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom In +
              </button>
              <button
                onClick={() => handleZoom(-0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom Out -
              </button>
              <button
                onClick={() => resetbtn()}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                <GrPowerReset />
              </button>
            </div>
          </div>
        </div>
      )}
      {/* modal for image preview with zoom in and out starts here ✅ */}

    </div>
  );
};

export default CompanyForumDetails;
