import React from "react";
import { ColorRing, ThreeDots } from "react-loader-spinner";
import LoaderButton from "./LoaderButton";

function LogoutLoader() {
  return (
    <div className="flex h-[100vh] items-center justify-center">
        <div className="bg-white p-4 rounded-lg shadow-lg flex items-center space-x-2">
          <LoaderButton />
          <span className="text-lg text-gray-700">Logging Out...</span>
        </div>
    </div>
  );
}

export default LogoutLoader;
