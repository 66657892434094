import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import { FaEdit, FaTrash } from 'react-icons/fa';
import apiClient from '../../../ApiClient'; // Import your API client
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import defaultImageUser from "../../../../assets/images/default_user.jpg"
import defaultImageCompany from "../../../../assets/images/default_company.jpg"
import { CustomToastContainer } from '../../../CustomToastContainer';

const UserComments = () => {
  const { forum_id } = useParams();
  const { user } = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const [postData, setPostData] = useState({ text: '' });
  const [author, setAuthor] = useState()
  const [selectedComment, setSelectedComment] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [imageUrls, setImageUrls] = useState({});
  const navigate = useNavigate();

  const fetchComments = async () => {
    try {
      const response = await apiClient.post('/listAllComments', {
        command: "listAllComments",
        forum_id: forum_id
      });
      if (response.data.status === 'success') {
        const comments = response.data.response.sort((a, b) => b.commented_on - a.commented_on);
        setComments(comments);
        console.log(comments)

        const urlsObject = {};
        await Promise.all(comments.map(async (comment) => {
          if (comment.fileKey) {
            try {
              const res = await apiClient.post('/getObjectSignedUrl', {
                command: 'getObjectSignedUrl',
                key: comment.fileKey,
              });
              if (res.data) {
                urlsObject[comment.comment_id] = res.data;
              }
            } catch (error) {
              console.error("Error getting signed URL for post:", error);
            }
          }
        }));
        setImageUrls(urlsObject);
      } else {
        console.error('API Error:', response.data.status_message);
      }
    } catch (error) {
      console.log("Error fetching posts", error);
    }
  };

  const getUsersAllForumPosts = async () => {
    try {
      const response = await apiClient.post("/getUsersAllForumPosts", {

        command: "getUsersAllForumPosts",
        user_id: user.user_id
      })


      if (response.data.status === "success") {
        const filteredPosts = response.data.response.filter(post => post.forum_id === forum_id);

        if (filteredPosts.length > 0) {
          setAuthor(filteredPosts[0]);
        }
      }


    } catch (error) {
      console.log("getUsersAllForumPosts", error)
    }

  }

  useEffect(() => {
    fetchComments();
    getUsersAllForumPosts()
  }, [forum_id]);

  const handleSubmitComment = async () => {
    if (!user.user_id) {
      toast.error('Error: User ID is not set');
      return;
    }

    if (!postData.text.trim()) {
      toast.error('Comment cannot be empty.');
      return;
    }

    try {
      const response = await apiClient.post('/addComments', {
        command: "addComments",
        user_id: user.user_id,
        forum_id: forum_id,
        ...postData
      });

      if (response.data.status === 'success') {
        setPostData({ text: "" });
        fetchComments();
        toast.success('Your comment has been posted successfully!');
      } else {
        toast.error('Failed to add the comment.');
      }
    } catch (err) {
      toast.error('An unexpected error occurred.');
    }
  };

  const handleEditComment = async () => {
    if (!selectedComment || !selectedComment.comment_id) return;

    try {
      const response = await apiClient.post('/updateComment', {
        command: "updateComment",
        user_id: user.user_id,
        comment_id: selectedComment.comment_id,
        text: postData.text,
      });
      console.log(response)
      if (response.data.status === 'SUCCESS') {
        toast.success('Comment updated successfully!');
        fetchComments();
        setModalVisible(false);
      } else {
        toast.error('Failed to update comment.');
      }
    } catch (error) {
      toast.error('An unexpected error occurred.');
    }
  };

  const handleDeleteComment = async () => {
    if (!selectedComment || !selectedComment.comment_id) return;

    try {
      let apiEndpoint = '/deleteComment';
      let requestBody = {
        command: "deleteComment",
        user_id: user.user_id,
        comment_id: selectedComment.comment_id,
      };
      console.log(user)

      if (author) {
        apiEndpoint = '/AuthorDeleteComment';
        requestBody = {
          command: "AuthorDeleteComment",
          forum_id: forum_id,
          comment_id: selectedComment.comment_id,
        };
      }

      const response = await apiClient.post(apiEndpoint, requestBody);

      if (response.data.status === 'success') {
        toast.success('Comment deleted successfully!');
        setComments(prevComments =>
          prevComments.filter(comment => comment.comment_id !== selectedComment.comment_id)
        );
        setModalVisible(false);
      } else {
        toast.error('Failed to delete comment.');
      }
    } catch (error) {
      toast.error('An unexpected error occurred.');
    }
  };


  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      })
      .replace(/ /g, "/");
  };

  const navigateToUserDetails = (user_id, user_type) => {
    const path = user_type === 'company'
      ? `/admin-dashboard/company/details/${user_id}`
      : `/admin-dashboard/users/user_details/${user_id}`;

    navigate(path);
  };

  const renderComment = (comment) => (
    <div className="flex p-4 border-b border-gray-200 md:w-[50%] mx-auto" key={comment.comment_id}>
      {/* Profile Image */}
      <img 
        src={imageUrls[comment.comment_id] || (comment.user_type === "company" ? defaultImageCompany : defaultImageUser)} 
        alt="Profile" 
        className="w-14 h-14 rounded-full object-cover mr-4 cursor-pointer transition-transform transform hover:scale-110"
        onClick={(e) => {
          e.stopPropagation();
          navigateToUserDetails(comment.user_id, comment.user_type);
        }} 
        onContextMenu={(e) => e.preventDefault()} 
      />
      
      <div className="flex flex-col w-full">
        {/* Author, Date, and Action Buttons */}
        <div className="flex items-center justify-between mb-2">
          <div 
            className="flex flex-col cursor-pointer" 
            onClick={(e) => {
              e.stopPropagation();
              navigateToUserDetails(comment.user_id, comment.user_type);
            }}
          >
            <span className="font-semibold text-lg text-gray-800">{comment.author || "N/A"}</span>
            <span className="text-sm text-gray-500 -mt-1">{comment?.user_category}</span>
          </div>
  
          {/* Date and Action Buttons */}
          <div className="flex flex-col items-center space-x-2">
            <span className="text-gray-500 text-sm">{formatDate(comment.commented_on)}</span>
  
            {/* Edit and Delete buttons */}
            {comment.user_id === user.user_id ? (
              <div className='flex space-x-2'>
                <button
                  className="text-blue-600 hover:text-blue-800 transition duration-200 flex items-center space-x-1"
                  onClick={() => {
                    setSelectedComment(comment);
                    setPostData({ text: comment.text });
                    setModalAction('edit');
                    setModalVisible(true);
                  }}
                >
                  <FaEdit size={16} /> 
                </button>
                <button
                  className="text-red-600 hover:text-red-800 transition duration-200 flex items-center space-x-1"
                  onClick={() => {
                    setSelectedComment(comment);
                    setModalAction('delete');
                    setModalVisible(true);
                  }}
                >
                  <FaTrash size={16} />
                </button>
              </div>
            ) : author ? (
              <button
                className="text-red-600 hover:text-red-800 transition duration-200 flex items-center space-x-1"
                onClick={() => {
                  setSelectedComment(comment);
                  setModalAction('delete');
                  setModalVisible(true);
                }}
              >
                <FaTrash size={16} />
              </button>
            ) : null}
          </div>
        </div>
  
        {/* Comment Text */}
        <p style={{ whiteSpace: "pre-wrap" }} className="text-gray-700 text-base mb-4">{comment.text}</p>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center  p-4">
      <Helmet>
        <title>BME Bharat | Post</title>
        <meta property="og:title" content="BME Bharat | Post"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content={`https://bmebharat.com/admin-dashboard/forums/comments/${forum_id}`} />
      </Helmet>
      <h1 className="custom-h1 mb-4 text-center">Comments</h1>
      <div className="space-y-4 w-full">
        {comments.map(renderComment)}
      </div>
      <div className="mt-6 flex flex-col max-w-[600px] w-full">
        <textarea
          className="p-2 mt-4 border border-gray-300 rounded-md"
          placeholder="Add a comment..."
          value={postData.text}
          onChange={(e) => setPostData({ text: e.target.value })}
          rows="4"
        />
        <div className=' flex gap-2'>
          <button
            onClick={handleSubmitComment}
            className="mt-2 custom-btn w-fit"
          >
            Submit
          </button>
          <button className="custom-btn mt-2 w-fit " onClick={() => {
        navigate(`/admin-dashboard/forums/details/${forum_id}`)
       
          }}>
            Back
          </button>
        </div>
      </div>

      {modalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
            {modalAction === 'edit' && selectedComment && (
              <div>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded-md active:border-none"
                  value={postData.text}
                  onChange={(e) => setPostData({ text: e.target.value })}
                  rows="4"
                />
                <button
                  className="mt-2 custom-btn"
                  onClick={handleEditComment}
                >
                  Save Changes
                </button>
                <button
                  className="mt-2 custom-btn"
                  onClick={() => {
                    // navigate(-1)
                    setModalVisible(false)
                  }}

                >
                  Close
                </button>
              </div>
            )}
            {modalAction === 'delete' && selectedComment && (
              <div>
                <p className="mb-4">Are you sure you want to delete this comment?</p>
                <button
                  className="mr-2 custom-btn"
                  onClick={handleDeleteComment}
                >
                  Delete
                </button>
                <button
                  className="custom-btn"
                  onClick={() => setModalVisible(false)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <CustomToastContainer />
    </div>
  );
};

export default UserComments;
