import React, { useState, useContext } from "react";
import { AuthContext } from "../../../AuthContext";
import CustomDropDownMenu from "../../../Common/CustomDropdown";
import {
  DomainStrengthType,
  EmploymentType,
  ExperienceType,
  LanguagesType,
  NoticePeriodType,
  SalaryType,
  industryType,
  ShiftType,
} from "../../../Data";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import apiClient from "../../../ApiClient";
import { Helmet } from "react-helmet";
import { CustomToastContainer } from "../../../CustomToastContainer";
import axios from "axios";

const MyJobProfileCreate = () => {
  const { user } = useContext(AuthContext);
  const userId = user.user_id;
  const navigate = useNavigate();
  const [pdfFile, setPdfFile] = useState(null);
  const [postData, setPostData] = useState({
    command: "createJobProfile",
    user_id: userId,
    city: "",
    state: "",
    expert_in: "",
    industry_type: "",
    domain_strength: "",
    employment_type: "",
    work_experience: "",
    preferred_cities: "",
    preferred_shift: "",
    preferred_salary: "",
    notice_period: "",
    college: "",
    languages: "",
    resume_key: "",
  });

  const handleInputChange = (key, value) => {
    setPostData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        // 200 KB limit
        toast.error("File size must be less than 5 MB.");
        return;
      }
      uploadFile(file)
      setPdfFile(file);
    } else {
      toast.error("Please upload a valid file.");
    }
  };

  const uploadFile = async (file) => {

    if (!file) {
      return;
    }
    const fileSize = file.size
    const fileType = file.type
    try {
      const res = await apiClient.post('/uploadFileToS3', {
        command: 'uploadFileToS3',
        headers: {
          'Content-Type': fileType,
          'Content-Length' : fileSize
        },
      });
      console.log("uploadFileToS3",res)
      if (res.data.status === "success") {
        let url = res.data.url
        const uploadFile = await axios.put(url, file, {
          headers : {
            "Content-Type" : fileType
          }
        })
        console.log("uploadFile", uploadFile)
        if (uploadFile.status === 200) {
          // toast.success('File uploaded successfuly')
          if (res.data.fileKey) {
            setPostData((prevState) => ({
              ...prevState,
              resume_key: res.data.fileKey,
            }));
          }
          return res.data.fileKey
        }
      } else {
        console.log(res.data.errorMessage)
        toast.error(res.data.errorMessage)
      }
    } catch (error) {
      toast.error('An error occurred during file upload.');
      throw error;
    } 
  };
   

  const handlePostSubmission = async (e) => {
    e.preventDefault();
    // console.log(postData)

    if (!postData.domain_strength || !postData.languages ||  !postData.work_experience || !postData.resume_key) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const res = await apiClient.post("/createJobProfile", postData);
      // console.log(res)
      if (res.data.status === "success") {
        toast.success("Job profile created successfully!");
        setTimeout(() => {
          navigate(`/user-dashboard/profile/my-job-profile`)
        }, 2000);
      } else {
        toast.error("Your Job Profile Already Exists!");
      }
    } catch (error) {
      toast.error("Error! Please check your internet connection.");
    }
  };

  const handleCancel = () => {
    let confirm = window.confirm ("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className="max-w-[1370px] w-full mt-10">
    <Helmet>
        <title>BME Bharat | Job profile</title>
        <meta property="og:title" content="BME Bharat | Job profile"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/create-job-profile" />
      </Helmet>
      {/* <Link to={"/user-dashboard/profile/my-job-profile-edit"}>
        <button className="fixed z-50 bottom-6 right-6 bg-[#e5e7eb] text-white px-6 py-3 rounded-full shadow-lg flex items-start space-x-2 hover:bg-blue-600 transition-transform transform hover:scale-105">
          <FaPen className="text-lg" />
          <span className="text-lg">Edit Job Profile</span>
        </button>
      </Link> */}
      <h1 className="custom-h1 my-8 text-center z-[1000]">
        Create your job profile
      </h1>

      <form
        className="max-w-[1370px] w-[80%] md:grid md:grid-cols-2 md:w-[80%] gap-10 mx-auto"
        onSubmit={handlePostSubmission}
      >
        <div>
          <div className="mb-4">
            <label htmlFor="industryType" className="block mb-2 font-medium text-gray-900">
              Industry Type
            </label>
            <CustomDropDownMenu
              items={industryType}
              onSelect={(item) => handleInputChange("industry_type", item.label)}
              placeholder="Select industry type"
              id="industryType"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="domainStrength" className="block mb-2 font-medium text-gray-900">
              Domain Strength
            </label>
            <></>
            <CustomDropDownMenu
              items={DomainStrengthType}
              onSelect={(item) => handleInputChange("domain_strength", item.label)}
              placeholder="Select Domain Strength"
              id="domainStrength"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="workExperience" className="block mb-2 font-medium text-gray-900">
              Work Experience
            </label>
            <CustomDropDownMenu
              items={ExperienceType}
              onSelect={(item) => handleInputChange("work_experience", item.label)}
              placeholder="Select Work Experience"
              id="workExperience"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="preferredSalary" className="block mb-2 font-medium text-gray-900">
            Expected Salary (LPA)
            </label>
            <CustomDropDownMenu
              items={SalaryType}
              onSelect={(item) => handleInputChange("preferred_salary", item.label)}
              placeholder="Select Expected Salary (LPA)"
              id="preferredSalary"
            />
          </div>

        
        </div>

        <div>
        <div className="mb-4">
            <label htmlFor="preferredCities" className="block mb-2 font-medium text-gray-900">
            Expert in
            </label>
            <input
              type="text"
              placeholder="Enter your expertise"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={postData.expert_in}
              onChange={(e) => handleInputChange("expert_in", e.target.value)}
              id="preferredCities"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="preferredCities" className="block mb-2 font-medium text-gray-900">
              Preferred Cities
            </label>
            <input
              type="text"
              placeholder="Preferred Cities (comma separated)"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={postData.preferred_cities}
              onChange={(e) => handleInputChange("preferred_cities", e.target.value)}
              id="preferredCities"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="languages" className="block mb-2 font-medium text-gray-900">
              Languages known
            </label>
            <input
              type="text"
              placeholder="Languages known (comma separated)"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={postData.languages}
              onChange={(e) => handleInputChange("languages", e.target.value)}
              id="languages"
            />
          </div>

          {/* <div className="mb-4">
            <label htmlFor="languages" className="block mb-2 font-medium text-gray-900">
              College
            </label>
            <input
              type="text"
              placeholder="College"
              disabled
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={postData.college}
              onChange={(e) => handleInputChange("college", e.target.value)}
            />
          </div> */}

          <div className="mb-4">
            <label htmlFor="resume" className="block mb-2 font-medium text-gray-900">
              Upload CV
            </label>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="resume"
            />
            {pdfFile && <p className="mt-2 text-gray-500">{pdfFile.name}</p>}
          </div>

         
        </div>

        <div className="flex gap-2">
          <button type="submit" className="custom-btn w-fit px-3">
            Submit Profile
          </button>
          {/* <Link to={"/user-dashboard/profile/my-job-profile-edit"}>
            <button className="custom-btn w-fit px-3 items-end">Edit</button>
          </Link> */}
          <Link to={"/user-dashboard/profile"}>
          <button className="custom-btn ml-2" onClick={()=>{
            handleCancel()
          }}>
            Cancel
          </button>
          </Link> 
        </div>
      </form>
      <CustomToastContainer/>
    </div>
  );
};

export default MyJobProfileCreate;
