import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../ApiClient";
import logo from "../../assets/images/logo-removebg-preview.png";
import login_bg from "../../assets/images/login_bg.png";
import { CountryCodes } from "../Data";
import LoaderButton from "../Common/LoaderButton";
import {CustomToastContainer} from "../CustomToastContainer";

const LoginForm = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const registerHandle = () => {
    navigate("/profile-type");
  };

  const validatePhoneNumber = (number) => /^[0-9]{10}$/.test(number);

  const sendOTPHandle = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;

    if (validatePhoneNumber(phoneNumber)) {
      setLoading(true);
      try {
        const res = await apiClient.post("/loginUser", {
          command: "loginUser",
          user_phone_number: fullPhoneNumber,
        });

        if (res.data?.status === "success") {
          const userId = res.data.login_user_details?.user_id;

          const otpRes = await apiClient.post(
            "/sendVerifyOtpMsg91",
            {
              command: "sendVerifyOtpMsg91",
              user_phone_number: fullPhoneNumber,
            },
            {
              headers: {
                "x-api-key": "YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg",
              },
            }
          );

          if (otpRes.data?.type === "success") {
            navigate("/login-verify", { state: { fullPhoneNumber, userId } });
          }
        } else {
          toast.error("Oops!! You don't have an account");
        }
      } catch (error) {
        console.error(error);
        toast.error("You don't have an internet connection");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Phone number is not valid");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative">
    <div
      className="absolute inset-0 bg-cover bg-center"
      style={{
        backgroundImage: `url(${login_bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        opacity: 1,
      }}
    />
  
    <div className="relative lg:right-[-30%] bg-white shadow-2xl rounded-3xl p-8 max-w-lg max-h-screen h-[80%] md:w-[370px] z-10 mx-4 transition-transform transform hover:scale-105  hover:drop-shadow-2xl">
      <img
        src={logo}
        alt="Logo"
        
        onContextMenu={(e) => e.preventDefault()}
        className="mx-auto mb-2 h-[100px] md:h-[170px]"
      />
      <h1 className="text-2xl custom-h1 text-center mb-4">Login</h1>
      <form onSubmit={sendOTPHandle} className="space-y-6">
        <div className="flex items-center justify-center space-x-2">
        <select
  id="countryCode"
  value={countryCode} // This will store only the country code (e.g., +91)
  onChange={(e) => setCountryCode(e.target.value)} // Updates countryCode state on change
  className="block text-center w-[40%] px-1 py-3 mr-1 border border-gray-300 bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
>
  {CountryCodes.map((code, index) => (
    <option key={index} value={code.value}>
      {code.label} ({code.value})
    </option>
  ))}
</select>

          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter 10-digit phone number"
            required
            className="block w-full rounded-lg px-3 py-3 border bg-none border-gray-300 bg-transparent shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
  
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        <div className="flex justify-center items-center">
        <button type="submit" className=" custom-btn" disabled={loading}>
          {loading ? (
            <div className='flex justify-center items-center'>
              <LoaderButton />
              Sending OTP
            </div>
          ) : (
            <>Send OTP</>
          )}
        </button>
        </div>
  
        <p className="text-gray-600 text-center">
          Not registered?{" "}
          <span
            className="text-indigo-600 hover:underline cursor-pointer"
            onClick={registerHandle}
          >
            Register here
          </span>
        </p>
  
        <p className="text-gray-600 text-center flex gap-2 mt-10 mx-auto items-center justify-center">
          <span
            className="text-indigo-600 hover:underline cursor-pointer text-sm"
            onClick={() => {
              navigate("/termsandconditions");
            }}
          >
            Terms and conditions
          </span>
          <span className="text-indigo-600">|</span>
          <span
            className="text-indigo-600 hover:underline cursor-pointer text-sm"
            onClick={() => {
              navigate("/privacypolicy");
            }}
          >
            Privacy policy
          </span>
        </p>
      </form>
    </div>
  
    <CustomToastContainer />
  </div>
  
  );
};

export default LoginForm;
