import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CustomToastContainer } from '../../../CustomToastContainer';


const MyjobsAppliedDetails = () => {
  const location = useLocation();
  const { jobDetails } = location.state || {};
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!jobDetails) {
    return <div className="text-center text-gray-500">No job details available.</div>;
  }

  return (
    
    <div className=" mx-auto mt-5 bg-white">
<Helmet>
  <title>{jobDetails.job_title} - {jobDetails.company_name}</title>
  <meta property="og:title" content={`BME Bharat - ${jobDetails.job_title} - ${jobDetails.company_name}`} />
  <meta property="og:description" content={jobDetails.job_description} />
  <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/details" />
</Helmet>

      <h1 className="custom-h1 mb-6 text-center">
        {jobDetails.job_title} - {jobDetails.company_name} ({jobDetails?.company_category})
      </h1>

      <div className="grid grid-cols-1 max-w-xl w-full mx-auto sm:grid-cols-2 gap-2 p-6 shadow-lg rounded-lg">
        <div>
          <p className="  font-bold text-gray-800">Package:</p>
          <p className="text-gray-600">{jobDetails.Package}</p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">Website:</p>
          <p className="text-gray-600">{jobDetails.Website}</p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">Applied On:</p>
          <p className="text-gray-600">
            {new Date(jobDetails.applied_on * 1000).toLocaleDateString()}
          </p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">Applied Status:</p>
          <p className="text-gray-600">{jobDetails.applied_status}</p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">Company Address:</p>
          <p className="text-gray-600">{jobDetails.company_address}</p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">City & State:</p>
          <p className="text-gray-600">
            {jobDetails.company_located_city}, {jobDetails.company_located_state}
          </p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">Experience Required:</p>
          <p className="text-gray-600">{jobDetails.experience_required}</p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">Hiring Type:</p>
          <p className="text-gray-600">{jobDetails.hiring_type}</p>
        </div>

        <div className="sm:col-span-2">
          <p className="  font-bold text-gray-800">Job Description:</p>
          <p className="text-gray-600">{jobDetails.job_description}</p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">Specializations Required:</p>
          <p className="text-gray-600">{jobDetails.speicializations_required}</p>
        </div>

        <div>
          <p className="  font-bold text-gray-800">Job Post Created On:</p>
          <p className="text-gray-600">
            {new Date(jobDetails.job_post_created_on * 1000).toLocaleDateString()}
          </p>
        </div>

        <div>
         <button className='custom-btn' onClick={()=>{
          navigate(-1)
         }}>Back</button>
        </div>
      </div>
      <CustomToastContainer/>
    </div>
  );
};

export default MyjobsAppliedDetails;
