import React, { useContext, useEffect, useRef, useState } from "react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import logo from "../../assets/images/logo-crop.png"
import logoTop from "../../assets/images/logoTop.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import uparrow from "../../assets/images/up-arrow.png"
import { IoIosArrowDown, IoIosLogOut } from "react-icons/io";
import Loader from "./Loader";
import apiClient from "../ApiClient";
import { IoNotifications } from "react-icons/io5";
import NotificationsAdmin from "./NotificationsAdmin";

const NavbarAdmin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser, loading } = useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [scrolled, setScrolled] = useState(false);



  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response =await apiClient.post('/getUnreadNotificationCount', {
       
            command: 'getUnreadNotificationCount',
            user_id: user.user_id
        });

        const data = response.data;

        if (data.statusCode === 200) {
          setUnreadCount(data.count);
        } else {
          console.error('Failed to fetch unread count');
        }
      } catch (error) {
        console.error('Error fetching unread notifications count:', error);
      }
    };

    fetchUnreadCount();
  }, [notificationModalOpen,isOpen,activeDropdown,user.user_id]);

  
  const handleNotifications = () => {
    setNotificationModalOpen(!notificationModalOpen)
    setIsOpen(false)
    setActiveDropdown('')
  }

  // scroll to top btn starts ✅
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    if (window.scrollY > 50) {
      setScrolled(true); 
    } else {
      setScrolled(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    setActiveDropdown("");
    setIsOpen(false)
    setNotificationModalOpen(false)
  };


  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown("");
        setIsOpen(false)
        setNotificationModalOpen(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);

    };
  }, []);


  const handleDropdownClick = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? "" : dropdown);
  };

  const handleItemClick = () => {
    setIsOpen(false); 
    setActiveDropdown("");
    setNotificationModalOpen(false)

  };

  const isActive = (path) => location.pathname.startsWith(path);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (!user && storedUser) {
      setUser(JSON.parse(storedUser));
    }

    if (!loading && !user && !storedUser) {
      navigate("/");
    }
  }, [user, loading, navigate, setUser]);

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');

    if (confirmLogout) {
      localStorage.removeItem('user');
      setUser(null);
      navigate('/');
    }
  };

  if (loading) {
    return <div><Loader /></div>;
  }


  return (
    <><div className="bg-white shadow-md fixed mt-0  w-[100%] z-50">
      <button
        className={`fixed z-50 bottom-24 right-10 rounded-full p-3 transition-opacity duration-300 ease-in-out transform ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-75'}`}
        onClick={scrollToTop}
        aria-label="Scroll to top"
        title="Scroll to Top!"
      >
        <img src={uparrow} alt="scroll to top icon "
          onContextMenu={(e) => e.preventDefault()}
          className="w-12 h-12" />
      </button>

      {/* scroll to top ends ❎ */}

      {/* First Row: Logo item Logout Button ✅ */}

      <div className="flex items-center justify-between px-4 py-2 md:px-8 md:py-4 border-b mx-3 md:mx-10">
      <Link to="/">
      {scrolled ? (
        <img
          src={logoTop}
          alt="Logo"
          onContextMenu={(e) => e.preventDefault()}
          className="transition-all duration-300 ease-in-out h-[50px] md:h-[60px]"  // Transition when scrolled
        />
      ) : (
        <img
          src={logo}
          alt="Logo"
          onContextMenu={(e) => e.preventDefault()}
          className="transition-all duration-300 ease-in-out h-[90px] md:h-[90px]"  // Default larger logo size
        />
      )}

          </Link>

        <div className=" justify-center items-center gap-2 hidden lg:flex">
          <div
            ref={dropdownRef}
            className={`lg:flex `}

          >


            {/* Companies Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("companies")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/admin") ? "bg-gray-200" : ""}`}
              >
                Companies <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />

              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "companies" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/company"
                  onClick={handleItemClick}
                >
                  Companies
                </Link>
              </div>
            </div>

              {/* Jobs Dropdown */}
              <div className="relative" onMouseEnter={() => setActiveDropdown("jobs")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/jobs") ? "bg-gray-200" : ""}`}
              >
                Jobs <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />

              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "jobs" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/jobs"
                  onClick={handleItemClick}
                >
                  Latest jobs
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/jobs/admin_posted_jobs"
                  onClick={handleItemClick}
                >
                  Admin Posted jobs
                </Link>
              </div>
            </div>

            {/* Forum Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("forums")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/forums") ? "bg-gray-200" : ""}`}
              >
                Forums <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "forums" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/forums"
                  onClick={handleItemClick}
                >
                  Latest posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile/my-forum-list"
                  onClick={handleItemClick}
                >
                  My posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/forums/post"
                  onClick={handleItemClick}
                >
                  Post a query
                </Link>
              </div>
            </div>

               {/* Users Dropdown */}
               <div className="relative" onMouseEnter={() => setActiveDropdown("users")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/users/list") ? "bg-gray-200" : ""}`}
              >
                Users <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "users" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/users/list"
                  onClick={handleItemClick}
                >
                  All users 
                </Link>

              </div>
            </div>

            {/* Banners Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("banners")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/banners") ? "bg-gray-200" : ""}`}
              >
                Banners <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "banners" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/banners/home_banner"
                  onClick={handleItemClick}
                >
                  Home banner
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/banners/ads_banner"
                  onClick={handleItemClick}
                >
                  Advertisement banner 01
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/banners/ads_banner02"
                  onClick={handleItemClick}
                >
                  Advertisement banner 02
                </Link>
              </div>
            </div>


               {/* Administrator Access */}
               <div className="relative" onMouseEnter={() => setActiveDropdown("administrator")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/administrator") ? "bg-gray-200" : ""}`}
              >
                Administrator <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "administrator" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="administrator/create_user"
                  onClick={handleItemClick}
                >
                  Create User
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="administrator/create_company"
                  onClick={handleItemClick}
                >
                  Create Company
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="administrator/create_job_post"
                  onClick={handleItemClick}
                >
                  Create Job
                </Link>
              </div>
            </div>

            {/* Profile Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("profile")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/profile") ? "bg-gray-200" : ""}`}
              >
                Profile <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg grid  gap-2 ${activeDropdown === "profile" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile"
                  onClick={handleItemClick}
                >
                  Profile
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/send_notification"
                  onClick={handleItemClick}
                >
                  Send notification
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile/my-post-jobs-seekers"
                  onClick={handleItemClick}
                >
                  Teams
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile/my-forum-list"
                  onClick={handleItemClick}
                >
                  My posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile/transaction_list"
                  onClick={handleItemClick}
                >
                  Transaction List
                </Link>
                <Link
                  className={`flex items-center px-4 py-2 text-red-500 hover:bg-gray-100 no-underline border-b border-blue-200`}

                  onClick={handleLogout}

                >
                  <IoIosLogOut style={{ fill: "red" }} className='mr-2' /> Sign Out
                </Link>

              </div>
            </div>

            <button ref={dropdownRef} onClick={() => {
                handleNotifications()
              }} className="relative ml-3 hover:bg-blue-50 p-2 rounded-full ">
                <IoNotifications  style={{ fill: "#075cab" }} className="w-7 h-7" />
                {unreadCount > 0 && (
                  <div className="absolute top-0 right-0 text-xs text-white bg-green-500 rounded-full w-5 h-5 flex items-center justify-center">
                    {unreadCount}
                  </div>
                )}
              </button>


          </div>
        </div>

        <div className="lg:hidden flex items-center space-x-4">
          <button onClick={() => {
                handleNotifications()
              }} className="relative ml-3 hover:bg-blue-50 p-2 rounded-full ">
                <IoNotifications style={{ fill: "#075cab" }} className="w-8 h-8" />
                {unreadCount > 0 && (
                  <div className="absolute top-0 right-0 text-xs text-white bg-green-500 rounded-full w-5 h-5 flex items-center justify-center">
                    {unreadCount}
                  </div>
                )}
              </button>

            <button onClick={() => {setIsOpen(!isOpen)
            setNotificationModalOpen(false)}} aria-label="Menu">
              {isOpen ? (
                <XIcon className="h-10 w-10 text-blue-700" />
              ) : (
                <MenuIcon className="h-9 w-9 text-blue-700" />
              )}
            </button>
          </div>
      </div>


      {/* small screen starts here */}
      {isOpen && (
        <>
          <div
            ref={dropdownRef}
            className={`flex justify-center items-center flex-col gap-6 my-2 ml-5`}
          >
        
            {/* Companies Dropdown */}
            <div className="relative" >
              <button
                onClick={() => setActiveDropdown("companies")}

                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/admin") ? "bg-gray-200" : ""}`}
              >
                Companies <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "companies" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/company"
                  onClick={handleItemClick}
                >
                  Companies
                </Link>
              </div>
            </div>

            <div className="relative" onMouseEnter={() => setActiveDropdown("jobs")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/jobs") ? "bg-gray-200" : ""}`}
              >
                Jobs <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />

              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "jobs" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/jobs"
                  onClick={handleItemClick}
                >
                  Latest jobs
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/jobs/admin_posted_jobs"
                  onClick={handleItemClick}
                >
                  Admin Posted jobs
                </Link>
              </div>
            </div>

            {/* Forum Dropdown */}
            <div className="relative">
              <button
                onClick={() => setActiveDropdown("forums")}

                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/forums") ? "bg-gray-200" : ""}`}
              >
                Forums <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "forums" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/forums"
                  onClick={handleItemClick}
                >
                  Posts
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile/my-forum-list"
                  onClick={handleItemClick}
                >
                  My posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/forums/post"
                  onClick={handleItemClick}
                >
                  Post a query
                </Link>
              </div>
            </div>

            {/* Users Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("users")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/users/list") ? "bg-gray-200" : ""}`}
              >
                Users <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "users" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/users/list"
                  onClick={handleItemClick}
                >
                  All users 
                </Link>

              </div>
            </div>

            {/* Banners Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("banners")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/banners") ? "bg-gray-200" : ""}`}
              >
                Banners <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "banners" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/banners/home_banner"
                  onClick={handleItemClick}
                >
                  Home banner
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/banners/ads_banner"
                  onClick={handleItemClick}
                >
                  Advertisement banner 01
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/forums/ads_banner"
                  onClick={handleItemClick}
                >
                  Advertisement banner 02
                </Link>
              </div>
            </div>

            {/* Administrator Access */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("administrator")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/administrator") ? "bg-gray-200" : ""}`}
              >
                Administrator <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-gray-200 rounded shadow-lg ${activeDropdown === "administrator" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="administrator/create_user"
                  onClick={handleItemClick}
                >
                  Create User
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="administrator/create_company"
                  onClick={handleItemClick}
                >
                  Create Company
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="administrator/create_job_post"
                  onClick={handleItemClick}
                >
                  Create Job
                </Link>
              </div>
            </div>

            {/* Profile Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("profile")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-gray-100 rounded ${isActive("/admin-dashboard/profile") ? "bg-gray-200" : ""}`}
              >
                Profile <IoIosArrowDown style={{ fill: "#075CAB" }} className="h-5 w-5 ml-2" />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 md:w-96 bg-white border border-gray-200 rounded shadow-lg grid md:grid-cols-2 gap-2 ${activeDropdown === "profile" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile"
                  onClick={handleItemClick}
                >
                  Profile
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/send_notification"
                  onClick={handleItemClick}
                >
                  Send notification
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile/my-post-jobs-seekers"
                  onClick={handleItemClick}
                >
                  Teams
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile/my-forum-list"
                  onClick={handleItemClick}
                >
                  My posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-gray-200`}
                  to="/admin-dashboard/profile/transaction_list"
                  onClick={handleItemClick}
                >
                  Transaction List
                </Link>
                <Link
                  className={`flex items-center font-bold px-4 py-2 text-red-500 hover:bg-gray-100 no-underline border-b border-blue-200`}

                  onClick={handleLogout}

                >
                  <IoIosLogOut style={{ fill: "red" }} className='mr-2' /> Sign Out
                </Link>

              </div>
            </div>


          </div>

        </>

      )}

      {notificationModalOpen && (
        <div ref={dropdownRef}>
          <NotificationsAdmin setNotificationModalOpen={setNotificationModalOpen} />
          </div>
        )}

      {/* ends here */}
    </div><div className="pt-[84px] md:pt-[105px] "></div></>
  );
};

export default NavbarAdmin;
