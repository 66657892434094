import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from "../../../AuthContext";
import Loader from '../../../Common/Loader';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../../ApiClient';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { CustomToastContainer } from '../../../CustomToastContainer';

const MySubscriptionList = () => {
  const [userid, setUserid] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      alert("Please log in to view your Subscription.");
      return;
    }
    setUserid(user.user_id);
  }, [user]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (userid) {
        // console.log(userid)
        setLoading(true);
        try {
          await axios.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/deleteDueTransactions', {
            command: "deleteDueTransactions", 
            user_id: userid  
          },{
            headers : {
              'x-api-key': 'YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg',
            }
          });

          const response = await apiClient.post('/getUsersTransactions', {
            command: "getUsersTransactions",
            user_id: userid 
          }
        );

          setTransactions(response.data.response || []);
        } catch (err) {
          setError("An error occurred while fetching subscriptions.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTransactions();
  }, [userid]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="loader"><Loader/></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="mx-auto p-4">
    <Helmet>
        <title>BME Bharat | Subscription List</title>
        <meta property="og:title" content="BME Bharat | Subscription List"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/subscription" />
      </Helmet>
      {transactions.length === 0 ? (
        <div className="h-[70vh] flex flex-col justify-center items-center">
          <h3 className="text-center">No subscriptions found.</h3>
          <br />
          <button
            onClick={() => navigate('/user-dashboard/profile/subscription')}
            className="text-center custom-btn"
          >
            Subscribe
          </button>
        </div>
      ) : (
          <><h1 className="custom-h1 my-4 text-center col-span-2">Your Subscriptions</h1>
          <div className="grid grid-cols-1 sm:grid-cols-3 justify-center mx-auto  gap-4">

            {transactions.map((item) => (
              <div
                key={item.transaction_id}
                className="p-6 max-w-md w-full flex flex-col justify-center mx-auto bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105"
              >
                <h2 className="font-semibold text-lg text-blue-600 mb-2">
                  Package Name: <span className="font-normal">{item.plan_type}</span>
                </h2>
                <div className="border-b border-gray-200 mb-4"></div>
                <p className="font-semibold text-gray-800">
                  Amount: <span className="font-normal text-blue-500">{item.amount} {item.currency}</span>
                </p>
                <p className="font-semibold text-gray-800">
                  Expires On: <span className="font-normal">{new Date(item.transaction_on * 1000).toLocaleDateString()}</span>
                </p>
                <p className="font-semibold text-gray-800">
                  Duration: <span className="font-normal">{item.transaction_duration}</span>
                </p>
                <p className="font-semibold text-gray-800">
                  Email: <span className="font-normal">{item.transaction_by}</span>
                </p>
                <p className="font-semibold text-gray-800">
                  Status: <span className="font-normal">{item.transaction_status}</span>
                </p>
                <p className="font-semibold text-gray-800">
                  Phone Number: <span className="font-normal">{item.user_phone_number}</span>
                </p>
              </div>
            ))}
          </div></>
      )}
      <CustomToastContainer/>
    </div>
  );
  
  
};

export default MySubscriptionList;
