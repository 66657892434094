import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../ApiClient';
import { AuthContext } from '../AuthContext';
import LoaderButton from './LoaderButton';

const timeAgo = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp * 1000;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    
    if (seconds < 60) return 'Just now';
    if (minutes < 60) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    if (hours < 24) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (days < 30) return `${days} day${days > 1 ? 's' : ''} ago`;

    return new Date(timestamp * 1000).toLocaleDateString();
};

const NotificationsUser = ({ setNotificationModalOpen }) => {
    const { user } = useContext(AuthContext);
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAllNotifications = async () => {
            try {
                const response = await apiClient.post("/getUserNotifications", {
                    command: 'getUserNotifications',
                    user_id: user.user_id
                });

                if (response.data && Array.isArray(response.data.data)) {
                    const sortedNotifications = response.data.data.sort((a, b) => b.timestamp - a.timestamp);
                    setNotifications(sortedNotifications);
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAllNotifications();
    }, [user.user_id]);

    const handleNotificationClick = async (notification) => {
        if (!notification.read) {
            try {
                const response = await apiClient.post(
                    '/updateReadStatus',
                    {
                        command: 'updateReadStatus',
                        notification_id: notification.notification_id
                    }
                );

                setNotifications((prevNotifications) =>
                    prevNotifications.map((notif) =>
                        notif.notification_id === notification.notification_id
                            ? { ...notif, read: true }
                            : notif
                    )
                );
            } catch (error) {
                console.error("Error marking notification as read:", error);
            }
        }

        setNotificationModalOpen(false);

        if (notification.notificationType === 'comment') {
            navigate(`/admin-dashboard/forums/comments/${notification.forum_id}`);
        } else if (notification.notificationType === 'forum_post') {
            navigate(`/admin-dashboard/forums/details/${notification.forum_id}`);
        }
    };

    return (
        <div className="fixed top-28 right-1  mx-2 sm:mx-0 sm:right-4 w-auto max-h-[400px] bg-white border rounded-lg shadow-lg overflow-hidden">
            <div className="flex justify-between items-center bg-bme_blue text-white px-4 py-2">
                <h3 className="text-lg font-semibold mb-0">Notifications</h3>
            </div>

            <div className="p-4 overflow-y-auto max-h-[300px]">
                {loading ? (
                    <div className="flex justify-center items-center">
                        <LoaderButton />
                    </div>
                ) : (
                    <>
                        {notifications.length > 0 ? (
                            <ul className="space-y-2 pl-0">
                                {notifications.map((notification, index) => (
                                    <li
                                        key={index}
                                        className="text-sm p-1 py-2 border-b border-gray-200 cursor-pointer flex items-start space-x-3"
                                        onClick={() => handleNotificationClick(notification)}
                                    >
                                        {!notification.read && (
                                            <span className="w-2.5 h-2.5 rounded-full bg-green-500 mr-2 mt-1"></span>
                                        )}

                                        <div className="flex-1">
                                            <span className={notification.read ? 'text-gray-600' : 'text-gray-900 font-semibold'}>
                                                {notification.message}
                                            </span>

                                            <div className={notification.read ? 'text-xs text-gray-500' : 'text-gray-800 font-semibold text-xs'}>
                                                {timeAgo(notification.timestamp)}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-sm text-gray-500">You have no notifications at the moment.</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default NotificationsUser;
