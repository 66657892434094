import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import defaultImage from "../../../assets/images/defaultImage.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Banner02 from "./Banner02";
import Banner01 from "./Banner01";
import apiClient from "../../ApiClient";
import { AuthContext } from "../../AuthContext";
import { FaArrowCircleRight } from "react-icons/fa";
import NavbarHome from "../../Common/NavbarHome";
import FooterHome from "../../Common/FooterHome";
import { Helmet } from "react-helmet";
import Banner03 from "./Banner03";
import LazyLoad from "react-lazyload";
import appDownload from "../../../assets/images/app_download.jpg"
import logo_title from "../../../assets/images/logo_title.png"
import videoSource1 from "../../../assets/videos/introVideo01.mp4"

function UserHomeLoginPage() {
  const [companies, setCompanies] = useState([]);
  const [forums, setForums] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [articles, setArticles] = useState([]);
  const [companyImageUrls, setCompanyImageUrls] = useState({});
  const [forumImageUrls, setForumImageUrls] = useState({});
  const [articleImageUrls, setArticleImageUrls] = useState({});
  const [jobImageUrls, setJobImageUrls] = useState({});
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [usertype, setUsertype] = useState();
  const location = useLocation()

  useEffect(() => {
    setUsertype(user?.user_type);
    // console.log(user.user_type)
  }, [user]);

  const videoRef = useRef(null);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await apiClient.post("/listAllCompanies", {
        command: "listAllCompanies",
      });

      const companies = res.data.response || [];
      const sortedCompanies = companies.sort((a, b) => {
        return new Date(b.company_created_on) - new Date(a.company_created_on); // Sort by createdAt descending
      });
      setCompanies(sortedCompanies);
      const urlsObject = {};
      await Promise.all(
        companies.map(async (companies) => {
          if (companies.fileKey) {
            const imgRes = await apiClient.post("/getObjectSignedUrl", {
              command: "getObjectSignedUrl",
              key: companies.fileKey,
            });
            const imgUrl = await imgRes.data;
            if (imgUrl) {
              // console.log("compnay img url"+ imgUrl);
              urlsObject[companies.company_id] = imgUrl;
              setCompanyImageUrls(urlsObject);
              // console.log("company", urlsObject);
            }
          }
        })
      );

    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchForum = async () => {
    try {
      const res = await apiClient.post("/getAllForumPosts", {
        command: "getAllForumPosts",
      });

      const forums = res.data.response || [];
      setForums(forums);
      const urlsObject = {};
      await Promise.all(
        forums.map(async (forum) => {
          // console.log(forum.fileKey)
          if (forum.fileKey) {
            const imgRes = await apiClient.post("/getObjectSignedUrl", {
              command: "getObjectSignedUrl",
              key: forum.fileKey,
            });
            const imgUrl = await imgRes.data;
            if (imgUrl) {
              // console.log("forum img url"+ imgUrl);
              urlsObject[forum.forum_id] = imgUrl;
            }
          }
        })
      );
      setForumImageUrls(urlsObject);
      // console.log("forum"+ urlsObject);s
    } catch (error) {
      // console.error("Error fetching Forums:", error);
    }
  };

  const fetchJobs = async () => {
    try {
      const res = await apiClient.post("/getAllJobPosts", {
        command: "getAllJobPosts",
      });

      const jobs = res.data.response || [];
      setJobs(jobs);
      const urlsObject = {};
      await Promise.all(
        jobs.map(async (job) => {
          if (job.fileKey) {
            const imgRes = await apiClient.post("/getObjectSignedUrl", {
              command: "getObjectSignedUrl",
              key: job.fileKey,
            });
            const imgUrl = await imgRes.data;
            if (imgUrl) {
              // console.log("article img url"+ imgUrl);
              urlsObject[job.post_id] = imgUrl;
            }
          }
        })
      );
      setJobImageUrls(urlsObject);
      // console.log("Job", urlsObject);
    } catch (error) {
      // console.error("Error fetching Jobs:", error);
    }
  };

  const fetchArticles = async () => {
    try {
      const res = await apiClient.post("/getAllArticles", {
        command: "getAllArticles",
      });

      const articles = res.data.response || [];
      setArticles(articles);
      const urlsObject = {};
      await Promise.all(
        articles.map(async (article) => {
          // console.log(article.fileKey)
          if (article.fileKey) {
            const imgRes = await apiClient.post("/getObjectSignedUrl", {
              command: "getObjectSignedUrl",
              key: article.fileKey,
            });
            const imgUrl = await imgRes.data;
            if (imgUrl) {
              // console.log("article img url"+ imgUrl);
              urlsObject[article.article_id] = imgUrl;
            }
          }
        })
      );
      setArticleImageUrls(urlsObject);
      // console.log("article"+ urlsObject);
    } catch (error) {
      // console.error("Error fetching articles:", error);
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchForum();
    fetchJobs();
    fetchArticles();
  }, []);

  const renderCompanyCard = (item) => {

    return (
      <div
        className="border rounded-lg p-4 m-2 hover:shadow-lg cursor-pointer bg-white"
        onClick={() => {
          navigateToCompanyDetails(item.company_id);
        }}
      >
        {/* {console.log(imageUrl)} */}
        <img
          src={companyImageUrls[item.company_id] || defaultImage}
          alt={item.company_name}
          onContextMenu={(e) => e.preventDefault()}
          className="h-[200px] w-[280px] mx-auto object-contain rounded"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImage;
          }}
        />
        <div className="mt-2">
          <div className="flex flex-col justify-start mb-2">
            <span className="font-bold text-lg">{item.company_name?.slice(0, 25) || "N/A"} {item.company_name?.length > 25 && ".."}</span>
            <span className="text-sm text-gray-500 -mt-1">{item?.category}</span>
          </div>
          <div className="flex justify-start ">
            <span className="font-bold w-20">Website</span>
            <span>: {item.Website?.slice(0, 25) || "N/A"} {item.Website?.length > 25 && ".."} </span>
          </div>

          <div className="flex justify-start ">
            <span className="font-bold w-20">City</span>
            <span>: {item.company_located_city || "N/A"}</span>
          </div>

          <div className="flex justify-start mb-2">
            <span className="font-bold w-20">State</span>
            <span>: {item.company_located_state?.value || item.company_located_state || "N/A"}</span>
          </div>
          <button

            className="custom-btn"
          >
            View more
          </button>
        </div>
      </div>
    );
  };

  const navigateToCompanyDetails = () => {
    navigate(`/login`);
  };

  const navigateToForumDetails = (forum_id) => {
    navigate(`/login`);

  };

  const renderForumCard = (item) => {
    const formattedPostedTime = new Date(
      item.posted_on * 1000
    ).toLocaleDateString("en-GB");
    const imageUrl = forumImageUrls[item.forum_id] || "placeholderImageUri";
    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm"];
    const isVideo = item.fileKey && videoExtensions.some(ext => item.fileKey.endsWith(ext));
    // console.log("forumfinalimages", imageUrl);
    return (
      <div
        className="border rounded-lg p-4 m-2 hover:shadow-lg cursor-pointer bg-white"
        onClick={() => navigateToForumDetails(item.forum_id)}
      >
        {/* Set image dimensions and center it like in the company card */}
        {isVideo ? (
              <video
                src={imageUrl}
                className="w-full h-64 object-contain rounded-t-lg"
                controls
                muted
                onError={(e) => { e.target.src = defaultImage; }}
              />
            ) : (
              <img
                src={imageUrl}
                alt={item.body?.slice(0,15)}

                onContextMenu={(e) => e.preventDefault()}
                className="w-full h-64 object-contain rounded-t-lg"
                onError={(e) => { e.target.src = defaultImage; }}
              />
            )}
          
        <div className="mt-2">
          <div className="flex flex-col justify-start mb-2">
            <span className="font-bold text-lg">{item.author?.slice(0, 25) || "N/A"} {item.author?.length > 25 && ".."}</span>
            <span className="text-sm text-gray-500 -mt-1">{item?.author_category}</span>
          </div>

 
          <p className="mb-1 ">{item.forum_body?.slice(0, 25)} ..</p>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-20 text-sm text-gray-500 ">Posted on</span>
            <span className="text-sm text-gray-500 ">: {formattedPostedTime || "N/A"}</span>
          </div>
   
          <button className="custom-btn">View more</button>
        </div>

      </div>
    );
  };

  const navigateToJobDetails = (post_id) => {
    navigate(`/login`);
  };

  const renderJobCard = (item) => {
    // console.log(item)
    const formattedPostedTime = new Date(
      item.job_post_created_on * 1000
    ).toLocaleDateString("en-GB");
    const imageUrl = jobImageUrls[item.post_id] || "placeholderImageUri";

    return (
      <div
        className="border rounded-lg p-3 m-2 hover:shadow-lg cursor-pointer bg-white"
        onClick={() => navigateToJobDetails(item.post_id)}
      >
        {/* Set image dimensions and center it like in the company card */}
        <img
          src={imageUrl}
          alt={item.company_name}
          onContextMenu={(e) => e.preventDefault()}
          className="h-[200px] w-[280px] mx-auto object-contain rounded"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImage;
          }}
        />

        <div className="mt-2">
          <div className="flex justify-start mb-2">
            <span className="font-bold">{item.job_title?.slice(0, 20) || "N/A"} {item.job_title?.length > 20 && ".."}</span>



          </div>
          <div className="flex flex-col justify-start mb-2">
            <span className="font-bold text-lg">{item.company_name.slice(0, 25) || "N/A"} {item.company_name.length > 25 && ".."}</span>
            <span className="text-sm text-gray-500 -mt-1">{item?.category}</span>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-28">Package</span>
            <span>: {item.Package || "N/A"}</span>
          </div>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-24">Posted On</span>
            <span>: {formattedPostedTime || "N/A"}</span>
          </div>
          <button className="custom-btn">View more</button>
        </div>

      </div>
    );
  };


  const handlejobsClick = () => {
    navigate(`/login`);


  };

  const handleforumClick = () => {
    navigate(`/login`);


  };

  const handlecompanyClick = () => {
    navigate(`/login`);
  };

  return (
    <>
      <Helmet>
        <title>BME Bharat</title>
        <meta property="og:title" content="BME Bharat"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com" />
      </Helmet>
      <NavbarHome />

      <div className="max-w-[1370px] w-[95%] md:w-[98%] mx-auto ">
        {/* Banner Comes here ✅ */}
        <Banner01 />
        {/* Banner Ends here ❎ */}

      {/* App-Intro-Home Section starts Here ✅*/}
       
      <div className="App-Intro-Home max-w-[1370px] flex flex-col flex-wrap justify-center items-center p-2 mt-5 width-[80%] md:w-11/12 mx-auto md:flex-row">
        <div className="flex-1 text-center mx-2">
          <img
            src={logo_title}
            alt="logo"
            className=" max-w-[248px] w-3/3 md:w-2/3 mx-auto"
          />
 
            <h2 className="text-[14px] text-gray-600 mt-4">
              Your Gateway to the Future of Biomedical Engineering
            </h2>
            <p className="mt-6 text-gray-700 w-full  mx-auto">
              At BME Bharat, we are dedicated to fostering innovation,
              collaboration, and knowledge-sharing in the field of biomedical
              engineering. Our mission is to inspire the next generation of
              biomedical engineers and contribute to a healthier and more advanced
              world{" "}
              <Link
                to={`/about`}

                className="text-blue-500 no-underline hover:underline"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                ..Read more
              </Link>
            </p>
          <div className="mx-0 mt-3">
            <a href='https://play.google.com/store/apps/details?id=com.bmebharat.newapp&hl=en' target='_blank' >
              <img
                src={appDownload}
                alt="logo"
                className="md:max-w-[496px] mx-auto"
              />
              </a>
          </div>
        </div>

        <div className="flex-1 text-center mx-3 ">
          <LazyLoad height={600} offset={100}>
            <video
              ref={videoRef}
              src={videoSource1}
              autoPlay
              loop
              muted
              className="sm:max-w-[95%] mx-auto w-auto h-auto rounded-lg cursor-pointer mt-12 sm:mt-0"
              onClick={handleVideoClick}
            />
          </LazyLoad>
        </div>
      </div>
       {/* App-Intro-Home Section Ends Here ❎ */}

        {/* companies Title for Cards ✅ */}
        <h1 className="text-[20px] font-bold text-gray-800 mt-7 text-left ml-3">
          Companies
        </h1>
        {/*Companies card for small screen section ✅ */}
        <div className="relative sm:hidden">
          <div className="flex  justify-end mr-9 mt-[-20px]">
            <p
              onClick={handlecompanyClick}
              className="text-end m-0 text-blue-500 underline cursor-pointer"
            >
              See all
            </p>
          </div>
          <Swiper
            modules={[Pagination]}
            //  // Matches "centeredSlides: true"
            loop={true} // Matches "loop: true"
            spaceBetween={30} // Matches "spaceBetween: 30"
            pagination={{ clickable: true }} // Matches pagination setup
            breakpoints={{
              // Matches "breakpoints"
              1920: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1028: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              990: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
            slideToClickedSlide={true} // Matches "slideToClickedSlide: true"
            className="centered-slide-carousel"
          >
            {Array.isArray(companies) &&
              companies?.slice(0, 8)
                .map((company) => (
                  <SwiperSlide key={company.company_id}>
                    {renderCompanyCard(company)}
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
        {/*Companies card for large screen section ✅ */}
        <div className="relative hidden sm:block">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
            {Array.isArray(companies) &&
              companies?.slice(0, 7).map((company) => (
                <div key={company.company_id} className="company-card">
                  {renderCompanyCard(company)}
                </div>
              ))}

            {/* Explore All Companies Card */}
            <div className="flex justify-center items-center">
              <div className="company-card flex items-center justify-center border rounded-lg p-3 w-[100%] h-[96%] m-2 hover:shadow-lg cursor-pointer bg-[#f4f4f4]">
                <p
                  onClick={handlecompanyClick}
                  className="m-0 flex items-center text-[16px] text-bme_blue underline cursor-pointer"
                >
                  Explore All Companies <FaArrowCircleRight className="ml-2 text-xl" />
                </p>
              </div>
            </div>

          </div>
        </div>
        {/*Companies card section Ends ❎*/}


        {/* Banner Comes here ✅ */}
        <Banner02 />
        {/* Banner Ends here ❎ */}


        {/* Forums Title for Cards ✅ */}
        <h1 className="text-[20px] font-bold text-gray-800 mt-7 text-left ml-3">
          Forums
        </h1>
        {/* Forum Cards Starts Here */}
        <div className="relative sm:hidden">
          <div className="flex justify-end mr-9 mt-[-20px]">
            <p
              onClick={handleforumClick}
              className="text-end m-0 text-blue-500 underline cursor-pointer"
            >
              {" "}
              See all
            </p>
          </div>
          <Swiper
            modules={[Pagination]}
            // Matches "centeredSlides: true"
            loop={true} // Matches "loop: true"
            spaceBetween={30} // Matches "spaceBetween: 30"
            pagination={{ clickable: true }} // Matches pagination setup
            breakpoints={{
              // Matches "breakpoints"
              1920: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1028: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              990: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
            slideToClickedSlide={true}
            className="centered-slide-carousel"
          >
            {Array.isArray(forums) &&
              forums?.slice(0, 8)
                .map((forum) => (
                  <SwiperSlide key={forum.forum_id}>
                    {renderForumCard(forum)}
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
        {/*Companies card for large screen section ✅ */}
        <div className="relative hidden sm:block">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
            {Array.isArray(forums) &&
              forums?.slice(0, 7).map((forum) => (
                <div key={forum.forum_id} className="company-card">
                  {renderForumCard(forum)}
                </div>
              ))}

            {/* Explore All Posts Card */}
            <div className="flex justify-center items-center">
              <div className="company-card flex items-center justify-center border rounded-lg p-3 w-[100%] h-[96%] m-2 hover:shadow-lg cursor-pointer bg-[#f4f4f4]">
                <p
                  onClick={handleforumClick}
                  className="m-0 flex items-center text-[16px] text-blue-500 underline cursor-pointer"
                >
                  Explore All Posts <FaArrowCircleRight className="ml-2 text-xl" />
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Forum Cards Ends Here */}

        {/* banner 03 for ads */}
        <Banner03 />
        {/* banner 03 for ads */}

        {/* Jobs Title for Cards ✅ */}
        <h1 className="text-[20px] font-bold text-gray-800 mt-7 text-left ml-3">
          Jobs
        </h1>
        {/* Jobs Cards Starts Here */}
        <div className="relative sm:hidden">
          <div className="flex justify-end mr-9 mt-[-20px]">
            <p
              onClick={handlejobsClick}
              className="text-end m-0 text-blue-500 underline cursor-pointer"
            >
              See all
            </p>
          </div>
          <Swiper
            modules={[Pagination]}
            // Matches "centeredSlides: true"
            loop={true} // Matches "loop: true"
            spaceBetween={30} // Matches "spaceBetween: 30"
            pagination={{ clickable: true }} // Matches pagination setup
            breakpoints={{
              // Matches "breakpoints"
              1920: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1028: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              990: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
            slideToClickedSlide={true} // Matches "slideToClickedSlide: true"
            className="centered-slide-carousel"
          >
            {Array.isArray(jobs) &&
              jobs?.slice(0, 8)
                .map((job) => (
                  <SwiperSlide key={job.post_id}>
                    {renderJobCard(job)}
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>

        <div className="relative hidden sm:block">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
            {Array.isArray(jobs) &&
              jobs?.slice(0, 7).map((job) => (
                <div key={job.post_id} className="job-card">
                  {renderJobCard(job)}
                </div>
              ))}

            {/* Explore All Jobs Card */}
            <div className="flex justify-center items-center">
              <div className="company-card flex items-center justify-center border rounded-lg p-3 w-[100%] h-[96%] m-2 hover:shadow-lg cursor-pointer bg-[#f4f4f4]">
                <p
                  onClick={handlejobsClick}
                  className="m-0 flex items-center text-[16px] text-blue-500 underline cursor-pointer"
                >
                  Explore All Jobs <FaArrowCircleRight className="ml-2 text-xl" />
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Jobs Cards Ends Here */}

     
      </div>


      {/* footer starts ✅ */}
      <FooterHome />
    </>
  );
}

export default UserHomeLoginPage;
