import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import apiClient from '../../../ApiClient';
import defaultImage from "../../../../assets/images/defaultImage1.jpg"
import LoaderButton from '../../../Common/LoaderButton';
import { Helmet } from 'react-helmet';
import { CustomToastContainer } from '../../../CustomToastContainer';
import { ImRocket } from 'react-icons/im';


const ForumPostScreen = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [fileKey, setFileKey] = useState(null);
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const { postText } = location.state || {}
  console.log("postText", postText)
  const [postData, setPostData] = useState({
    // title: postText || '',
    body: postText || '',
    conclusion: '',
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const { naturalWidth, naturalHeight } = event.target;
    console.log(selectedFile.naturalWidth)
    console.log("event.target", event.target)
    if (selectedFile) {
      const sizeLimit = selectedFile.type.startsWith('image') ? 5 * 1024 * 1024 : 10 * 1024 * 1024; // 200 KB for images, 5 MB for videos
      if (selectedFile.size > sizeLimit) {
        toast.error(selectedFile.type.startsWith('image') ? 'Image size limit exceeds 5 MB' : 'Video size limit exceeds 10 MB.');
        setFile(null);
        return;
      }
      setFile(selectedFile);
      setFileType(selectedFile.type);
    }
  };

  const handleUploadFile = async () => {
    setLoading(true);
    if (!file) {
      return setFile(null);
    }
    const fileSize = file.size
    try {
      const res = await apiClient.post('/uploadFileToS3', {
        command: 'uploadFileToS3',
        headers: {
          'Content-Type': fileType,
          'Content-Length': fileSize
        },
      });
      console.log(res.data.url)
      if (res.data.status === "success") {
        console.log(res.data.url)
        let url = res.data.url
        const uploadFile = await axios.put(url, file, {
          headers: {
            "Content-Type": fileType
          }
        })
        console.log(uploadFile)
        if (uploadFile.status === 200) {
          setFile(file)
          setFileKey(res.data.fileKey)
          // toast.success('File uploaded successfuly')
          return res.data.fileKey
        }
      } else {
        console.log(res.data.errorMessage)
        toast.error(res.data.errorMessage)
      }
    } catch (error) {
      toast.error('An error occurred during file upload.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handlePostSubmission = async (event) => {
    setLoading(true)

    event.preventDefault(); // Prevent default form submission
    try {
      const uploadedFileKey = await handleUploadFile();

      // if (!uploadedFileKey) {
      //   throw new Error('File upload failed or fileKey is missing.');
      // }

      const postPayload = {
        command: 'postInForum',
        user_id: user.user_id,
        // title: postData.title,
        forum_body: postData.body,
        conclusion: postData.conclusion,
        fileKey: uploadedFileKey,
      };

      const res = await apiClient.post(
        '/postInForum',
        postPayload
      );
      // console.log(res)
      if (res.data.status === "success") {
        toast.success("The forum post has been uploaded.");
        setTimeout(() => {
          navigate("/admin-dashboard/forums");
        }, 1000);
      } else {
        toast.error(res.data.message || res.data.errorMessage);
      }
    } catch (error) {
      console.error('Error in handlePostSubmission:', error.message);
      toast.error('An error occurred while submitting the post.'); // General error toast
    } finally {
      setLoading(false)
    }
  };

  const clearImage = () => {
    setFile(null)
    setFileKey(null);
    const fileInput1 = document.getElementById('file-upload');


    if (fileInput1) fileInput1.value = '';
  };

  const handleCancel = () => {
    let confirm = window.confirm("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className='max-w-[1370px] w-full mt-10'>

      <Helmet>
        <title>BME Bharat | Post</title>
        <meta property="og:title" content="BME Bharat | Latest posts"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/admin-dashboard/forums/post" />
      </Helmet>
      <form className="max-w-3xl w-[90%] md:w-full mx-auto" onSubmit={handlePostSubmission}>
        {/* <div className="mb-4 w-full ">
          <label htmlFor="title" className="block mb-2   text-gray-900 ">
            Title
          </label>
          <input
            id="title"
            type="text"
            value={postData.title}
            onChange={(e) => setPostData({ ...postData, title: e.target.value })}
            className="bg-gray-50 block border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 "
            required
          />
        </div> */}
        <div className="my-4">
          <label htmlFor="body" className="block mb-2   text-gray-900 ">
            Description
          </label>
          <textarea
            id="body"
            value={postData.body}
            onChange={(e) => setPostData({ ...postData, body: e.target.value })}
            className="bg-gray-50 h-52 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            required
          />
        </div>
        
        <div className="mb-4">
          <label htmlFor="file-upload" className="block text-gray-900 ">
            Upload a file
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*,video/*"
            onChange={handleFileChange}
            className="text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 hidden w-full p-2.5 "
          />
        </div>
        <div className=" flex justify-center items-center mx-auto">


          {/* starts */}
          {defaultImage && !file && (
            <div className="relative inline-block">
              {/* Check if the file is a video */}
              {defaultImage &&
                // Default case if it's an image or other supported type
                <img
                  src={defaultImage}
                  alt="Uploaded Media"
                  onContextMenu={(e) => e.preventDefault()}
                  className=" rounded cursor-pointer"
                  onClick={() => document.getElementById("file-upload").click()} // Clicking on the image opens file picker
                />
              }

              {/* Pencil Icon to change the video or image */}
              <button
                type="button"
                className="absolute top-0 right-0 bg-white p-1 rounded-full shadow"
                onClick={() => document.getElementById("file-upload").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>
            </div>
          )}

          {/* For newly selected files */}
          {file && (
            <div className="relative mt-4 inline-block">
              {/* Show image if it's an image file */}
              {file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(file)}
                  onContextMenu={(e) => e.preventDefault()}
                  alt="Selected File"
                  className=" rounded cursor-pointer"
                  onClick={() => document.getElementById("file-upload").click()} // Clicking on the image opens file picker
                />
              ) : (
                // Show video player if it's a video file
                <video controls className=" rounded">
                  <source src={URL.createObjectURL(file)} type={file.type} />
                  Your browser does not support the video tag.
                </video>
              )}

              {/* Pencil Icon to change the file */}
              <button
                type="button"
                className="absolute top-0 right-0 bg-white p-1 rounded-full shadow"
                onClick={() => document.getElementById("file-upload").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>

              {/* Remove Image Button */}
              <button
                type="button"
                onClick={clearImage}
                className="text-red-500 hover:underline mt-2 block"
              >
                Remove file <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>
            </div>
          )}


          {/* ends */}
        </div>





        <div className="flex gap-2 mt-3">
          <button type="submit" className="custom-btn" disabled={loading}>
            {loading ? (
              <div className='flex justify-center items-center'>
                <LoaderButton />
                Posting
              </div>
            ) : (
              <>Post </>
            )}
          </button>
          <button type='button' className="custom-btn ml-2" onClick={(e) => {
            e.stopPropagation()
            handleCancel()
          }}>
            Cancel
          </button>
        </div>
      </form>
      <CustomToastContainer />
    </div>
  );
};

export default ForumPostScreen;
