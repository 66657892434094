import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { CiSearch } from 'react-icons/ci';
import Loader from "../../../Common/Loader"
import apiClient from '../../../ApiClient';

const PLAN_TYPES = ['All', 'Standard', 'Basic', 'Premium', 'Deleted Account Transactions'];

const AllTransactionListScreen = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [planType, setPlanType] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false)

  const tableRef = useRef(null);

  const columns = [
    { id: 'transaction_id', label: 'ID', minWidth: 50 },
    { id: 'transaction_by', label: 'User', minWidth: 150 },
    { id: 'plan_type', label: 'Plan Type', minWidth: 80 },
    { id: 'transaction_on', label: 'Date', minWidth: 80 },
    { id: 'amount', label: 'Amount', minWidth: 50 },
    { id: 'currency', label: 'Currency', minWidth: 50 },
    { id: 'transaction_duration', label: 'Duration', minWidth: 100 },
    { id: 'transaction_status', label: 'Status', minWidth: 100 },
    { id: 'user_phone_number', label: 'Phone', minWidth: 100 },
  ];

  const [columnWidths, setColumnWidths] = useState(columns.reduce((acc, col) => {
    acc[col.id] = col.minWidth;
    return acc;
  }, {}));

  // Fetch data from API
  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await apiClient.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/getAllUsersTransactionsFmAdminSide', {
        command: 'getAllUsersTransactionsFmAdminSide',
        bme_Admin_email_id: 'bmebharat@gmail.com',
        plan_type: planType,
      });
      setTransactions(response.data.response || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setTransactions([]); // Handle errors by setting an empty array
    } finally {
      setLoading(false)
    }
  }, [planType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let filtered = transactions;
    if (planType !== 'All') {
      filtered = transactions.filter(transaction => transaction.plan_type === planType);
    }
    if (searchQuery) {
      filtered = filtered.filter(transaction =>
        (transaction.transaction_by?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ?? false) ||
        (transaction.transaction_id?.toString().includes(searchQuery) ?? false) ||
        (new Date(transaction.transaction_on * 1000).toLocaleDateString().includes(searchQuery) ?? false) ||
        (transaction.currency?.toLowerCase()?.includes(searchQuery.toLowerCase()) ?? false) ||
        (transaction.transaction_status?.toLowerCase()?.includes(searchQuery.toLowerCase()) ?? false) ||
        (transaction.transaction_duration?.toLowerCase()?.includes(searchQuery.toLowerCase()) ?? false) ||
        (transaction.user_phone_number?.includes(searchQuery) ?? false) ||
        (transaction.amount?.toString().includes(searchQuery) ?? false)

      );
    }
    setFilteredTransactions(filtered.reverse()); // Reverse the filtered list so newest data comes first
  }, [transactions, planType, searchQuery]);

  // Handle column resizing
  const startResizing = (e, columnId) => {
    const startX = e.clientX;
    const startWidth = columnWidths[columnId];

    const handleMouseMove = (moveEvent) => {
      const newWidth = Math.max(startWidth + moveEvent.clientX - startX, columns.find(col => col.id === columnId).minWidth);
      setColumnWidths((prev) => ({
        ...prev,
        [columnId]: newWidth,
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const renderItem = (item) => (
    <tr key={item.transaction_id} className="border-b hover:bg-blue-100">
      {columns.map((col) => (
        <td
          key={col.id}
          className="px-6 py-4 text-center border-l border-r"
          style={{ minWidth: `${columnWidths[col.id]}px`, maxWidth: `${columnWidths[col.id]}px`, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        >
          {col.id === 'transaction_on'
            ? new Date(item[col.id] * 1000).toLocaleDateString()
            : item[col.id]}
        </td>
      ))}
    </tr>
  );

  const renderHeader = () => (
    <div className="mb-6 w-[100%]">
      {/* Search Bar and Filter Buttons */}
      <div className="flex flex-col md:flex-row gap-4 py-2 justify-between items-center mb-6 w-full">
        <div className="relative w-full max-w-sm">
          {!searchQuery && (
            <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
          )}
          <input
            type="text"
            className="w-full pl-10 py-2 text-base border border-bme_blue rounded-lg focus:outline-none focus:ring-2 focus:ring-bme_blue"
            placeholder="       Search .."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && fetchData()}
          />
        </div>

        {/* Filter Buttons */}
        <div className="flex justify-center gap-2">
          {PLAN_TYPES.map(type => (
            <button
              key={type}
              onClick={() => setPlanType(type)}
              className={`custom-btn text-white ${planType === type ? 'bg-bme_blue' : 'bg-blue-500'} hover:bg-bme_blue transition`}
            >
              {type}
            </button>
          ))}
        </div>
      </div>

      {/* Table with Horizontal Scrolling */}
      <div className="overflow-x-auto relative shadow-md rounded-lg border border-gray-300">
        <table className="table-auto w-full">
          <thead className="bg-bme_blue text-white sticky top-0 z-10">
            <tr>
              {columns.map((col) => (
                <th
                  key={col.id}
                  className="px-6 py-3 text-center font-semibold cursor-ew-resize relative text-sm text-gray-100 border-l border-r"
                  onMouseDown={(e) => startResizing(e, col.id)}
                  style={{ minWidth: `${col.minWidth}px`, width: `${columnWidths[col.id]}px` }}
                >
                  {col.label}
                  <div
                    className="absolute right-0 top-0 h-full w-2 cursor-ew-resize bg-bme_blue"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.length > 0 ? filteredTransactions.map(renderItem) : (
              <tr>
                <td colSpan={columns.length} className="text-center py-10 text-gray-500">
                  No Transactions Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  if (loading) {
    return <Loader />
  }

  return (
    <div className="w-[98%] mx-auto">
      {renderHeader()}
    </div>
  );
};

export default AllTransactionListScreen;
