import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from "../../../AuthContext";
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../Common/Loader';
import { useNavigate } from 'react-router-dom';
import { FaArrowTrendUp } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import { CustomToastContainer } from '../../../CustomToastContainer';

const UserSubscription = () => {
  const [orderId, setOrderId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [signatureId, setSignatureId] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const navigate = useNavigate()
  
  const { user, setUser } = useContext(AuthContext);
  const [userId, setuserId] = useState('');
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (!user) {
      toast.error("Please log in to view your articles.");
      return;
    }
    setuserId(user.user_id);

   

    // Load Razorpay script dynamically if not already loaded
    if (!window.Razorpay) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [user]);

  const deleteDueTransactions = async () => {
    if (userId) {
      setLoading(true);
      try {
       const res = await axios.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/deleteDueTransactions', {
          command: "deleteDueTransactions", 
          user_id: userId  
        },{
          headers : {
            'x-api-key': 'YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg',
          }
        });
        // console.log(res)
      } catch (err) {
        console.error("An error occurred while deleting due transactions subscriptions.");
      } finally {
        setLoading(false);
      }
    }
  };

  const packages = [
    {
      name: 'Basic (30 Days)',
      price: 'Rs. 59',
      amount: 59,
      validity: '30',
      features: [true, true, false, true, true, false, true, true],
    },
    {
      name: 'Pro (180 Days)',
      price: 'Rs. 336',
      amount: 336,
      validity: '180',
      features: [true, true, true, true, true, true, true, true],
    },
    {
      name: 'Elite (365 Days)',
      price: 'Rs. 637',
      amount: 637,
      validity: '365',
      features: [true, true, true, true, true, true, true, true],
    },
  ];




  const featuresList = [
    'Job updates',
    'Premium Knowledge Resources',
    'Access to companies and product information',
    'Unlimited access to forum',
    'Enhanced Job Portal Features',
    'Priority customer support',
    'Regular updates on biomedical engineering',
    'Professional Networking',
  ];

  const initiatePayment = async (pkg) => {
    setSelectedPackage(pkg);
    try {
      const response = await axios.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/razorpay', {
        command: 'razorpay',
        user_id: userId,
        amount: pkg.amount,
        currency: 'INR',
        plan_type: pkg.name,
      },{
        headers : {
          'x-api-key': 'YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg',
        }
      });
      console.log(response)
      const order = response.data.order;
      console.log("order", order)
      setOrderId(order.id);

      const options = {
        key: 'rzp_live_l3vOFR4C3UPqLa', // Use your Razorpay live/test key here
        amount: order.amount,
        currency: order.currency,
        name: 'BME BHARAT',
        description: 'BME BHARAT Transaction',
        image: 'https://bmebharat.com/logo.png',
        order_id: order.id,
        prefill: {
          name: user?.name || 'Your Name',
          email: user?.email || 'email@example.com',
          contact: user?.user_phone_number || '9999999999'
        },
        notes: {
          address: 'BME BHARAT Office Address',
        },
        theme: {
          color: '#3399cc',
        },
        handler: (response) => {
          // Handle successful payment
          setPaymentId(response.razorpay_payment_id);
          setSignatureId(response.razorpay_signature);
          verifyPayment(response);
        },
        modal: {
          ondismiss: function () {
            toast.error("Payment cancelled.");
            deleteDueTransactions()
          }
        }
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

    } catch (error) {
      console.error("Error during payment initiation:", error);
      toast.error("Failed to create order. Please try again.");
      deleteDueTransactions()
    }
  };

  const verifyPayment = async (paymentData) => {
    try {
      const verifyResponse = await axios.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/verifyPayment', {
        command: 'verifyPayment',
        razorpay_order_id: paymentData.razorpay_order_id,
        razorpay_payment_id: paymentData.razorpay_payment_id,
        razorpay_signature: paymentData.razorpay_signature,
      },{
        headers : {
          'x-api-key': 'YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg',
        }
      });
      if (verifyResponse.data.statusCode === 200) {
        toast.success("Payment Successful. Thank you for your purchase!");
        setTimeout(() => {
        toast.success("Please sign in again to proceed.");
          
        }, 1000);
        setTimeout(() => {
          localStorage.removeItem("user");
      setUser(null);
      navigate("/login");
        }, 2000);
      } else {
        toast.error("Payment verification failed. Please try again.");
        deleteDueTransactions()
        
        
      }
    } catch (error) {
      console.error("Payment verification error:", error);
      toast.error("Payment verification failed. Please try again.");
      deleteDueTransactions()
    }
  };

  if(loading) {
    <>
    <Loader/>
    </>
  }

  return (
    <div className="min-h-screen max-w-[1370px] w-full md:w-[80%] mx-auto bg-white my-10 px-4">
      <Helmet>
        <title>BME Bharat | Subscriptions</title>
        <meta property="og:title" content="BME Bharat | Subscriptions"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/mysubscriptions" />
      </Helmet>
      <div className="text-center mb-8">
    
      </div>

      <div className=" rounded-lg border border-gray-300">
        {/* Features Row */}
        <div className="grid grid-cols-4 gap-4 p-4 bg-gray-200">
          <span className="font-bold text-lg">Features included</span>
          {packages.map((pkg, pkgIndex) => (
            <span key={pkgIndex} className="font-bold text-lg text-center">{pkg.name}</span>
          ))}
        </div>

        {/* Feature List */}
        {featuresList.map((feature, index) => (
          <div key={index} className="grid grid-cols-4 gap-4 p-4 border-t border-gray-300">
            <span>{feature}</span>
            {packages.map((pkg, pkgIndex) => (
              <span key={pkgIndex} className="text-center">
                {pkg.features[index] ? (
                  <span className="text-green-500">✔️</span>
                ) : (
                  <span className="text-red-500">❌</span>
                )}
              </span>
            ))}
          </div>
        ))}

        {/* Buy Now Row */}
        <div className="grid grid-cols-4 gap-4 p-4 border-t border-gray-300">
          <span className="font-bold text-lg">Buy</span>
          {packages.map((pkg, pkgIndex) => (
            <button
              key={pkgIndex}
              onClick={() => initiatePayment(pkg)}
              className="custom-btn w-fit text-center mx-auto"
            >
              {pkg.price}
            </button>
          ))}
        </div>
      </div>
      <CustomToastContainer/>
    </div>
  );
};

export default UserSubscription;