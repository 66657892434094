import React from "react";
import { NavLink } from "react-router-dom";
import { FaInstagram, FaSquareFacebook, FaXTwitter } from "react-icons/fa6";
import { CiLinkedin, CiYoutube } from "react-icons/ci";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';


const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-gray-800 text-white py-8 pb-0 text-center mt-8 text-xs sm:text-sm">
      <div className="mx-auto flex flex-col md:flex-row justify-between">
        {/* Left Section */}
        <div className="flex-1 mb-4 md:mb-0">
        <div className="mb-4">
          <p className="text-white">
            BME BHARAT &copy; {new Date().getFullYear()} All Rights Reserved.
          </p>
        </div>

        {/* Social Media Icons */}
        <div className="flex flex-wrap justify-center space-x-4 mb-4">
          <a href="https://www.facebook.com/bme.bharat/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <FaSquareFacebook  style={{ fill: 'white' }} className="text-2xl text-white transition-colors duration-200" />
          </a>
          <a href="https://www.instagram.com/b_m_e_bharat/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <FaInstagram  style={{ fill: 'white' }} className="text-2xl transition-colors duration-200" />
          </a>
          <a href="https://in.linkedin.com/in/bme-bharat-6859b6201" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <CiLinkedin  style={{ fill: 'white' }} className="text-2xl transition-colors duration-200" />
          </a>
          <a href="https://x.com/BME_BHARAT2020" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <FaXTwitter  style={{ fill: 'white' }} className="text-2xl transition-colors duration-200" />
          </a>
          <a href="https://www.youtube.com/channel/UCxEPxTe3RhRXlBd3Er4653Q" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <CiYoutube  style={{ fill: 'white' }} className="text-2xl transition-colors duration-200" />
          </a>
        </div>

        {/* Links Section */}
        <div className="flex flex-wrap justify-center items-center space-x-2 mb-2 w-96 sm:w-auto mx-auto">
          <NavLink to="/company-dashboard/about" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>About</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/jobs" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '1' }}>Jobs</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/company" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Companies</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/forums" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Forums</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/profile/subscription" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Subscriptions</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/contact-us" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Help center</NavLink>
        </div>

        {/* Second line with other links */}
        <div className="flex flex-wrap justify-center items-center space-x-2">
          <NavLink to="/company-dashboard/guidelines" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Guidelines</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/guidelines/privacypolicy" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Privacy Policy</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/guidelines/cancellation" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Cancellation Policy</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/guidelines/termsandconditions" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Terms & Conditions</NavLink>
          <span className="text-white" style={{ lineHeight: '2' }}>|</span>
          <NavLink to="/company-dashboard/guidelines/legalpolicy" className="text-white no-underline hover:underline" onClick={scrollToTop} style={{ lineHeight: '2' }}>Legal Policy</NavLink>
          
        </div>
      </div>



      <div className="w-full md:w-1/4  flex flex-col items-center justify-center mx-auto ">
          {/* <h4 className="text-xl font-bold mb-6 text-center text-white">Contact Us</h4> */}
          <ul className="space-y-2 md:space-y-4  text-white">
            <li className="flex items-center ">
              <FaEnvelope style={{ fill: 'white' }} className="mr-2 text-xl" />
              <a href="mailto:admin@bmebharat.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 text-white my-0">
                admin@bmebharat.com
              </a>
            </li>
            <li className="flex items-center">
              <FaEnvelope style={{ fill: 'white' }} className="mr-2 text-xl" />
              <a href="mailto:bmebharat@gmail.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 text-white">
                bmebharat@gmail.com
              </a>
            </li>
            <li className="flex items-center">
              <FaPhone style={{ fill: 'white' }} className="mr-2 text-xl" />
              <a href="tel:+918310491223" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 text-white">
                +91 83104 91223
              </a>
            </li>
            <li className="flex items-start">
              <FaMapMarkerAlt style={{ fill: 'white' }} className="text-xl mr-2" />
              <div className="flex flex-col">
                <p className="m-0 text-white text-left">Operational Address:</p>
                <p className="m-0 mt-1 text-white text-left">
                  #260, 1st floor, 18th main
                  <br />
                  11th block Anjanpura,
                  <br />
                  Lal Bahadur Shastri Nagar,
                  <br />
                  Bengaluru-560083, Karnataka.
                </p>
              </div>
            </li>
          </ul>
          </div>
    </div>
    </div>
  );
};

export default Footer;
