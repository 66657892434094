import React, { useState } from 'react'
import apiClient from '../../../ApiClient';
import apiClientAdmin from '../../../ApiClientAdmin';
import LoaderButton from '../../../Common/LoaderButton';
import { CustomToastContainer } from '../../../CustomToastContainer';

const AdminCustomNotifications = () => {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setMessage('');
      
      const requestBody = {
        command: 'adminPushNotificationsToAllUsers',
        title: title,
        body: body,
      };
  
      try {
        const response = await apiClientAdmin.post(
          '/adminPushNotificationsToAllUsers',
          requestBody
        );
        console.log(response)
  
        if (response.status === 200) {
          setMessageType('success');
          setMessage('Notification sent to all users.');
        }
      } catch (error) {
        setMessageType('error');
        setMessage('Failed to send notification. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div className="max-w-md mx-auto mt-8 p-6 border border-gray-200 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Send Push Notification</h2>
        
        {message && (
          <div className={`mb-4 p-2 text-center ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {message}
          </div>
        )}
        
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter notification title"
            />
          </div>
          
          <div className="mb-4">
            <label htmlFor="body" className="block text-sm font-medium text-gray-700">Body</label>
            <textarea
              id="body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              required
              rows="4"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter notification body"
            />
          </div>
  
          <div className="flex justify-center">
          <button className="custom-btn" type='submit' disabled={loading}>

{loading ? (
      <div className='flex justify-center items-center'>
        <LoaderButton />
        Sending ..
      </div>
    ) : (
      <>Send Notification</>
    )}
  </button>
          </div>
        </form>
      <CustomToastContainer/>
      </div>
    );
}

export default AdminCustomNotifications