import React, { useContext, useRef, useState } from 'react';
import apiClient from '../ApiClient';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import LoaderButton from './LoaderButton';
import { CustomToastContainer } from '../CustomToastContainer';
import { CountryCodes } from '../Data';
import logo from "../../assets/images/logo-crop.png";

const DeleteAccountGlobal = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const otpInputs = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91'); // Default country code (India)
  const [showMobileInput, setShowMobileInput] = useState(true);

  const handleLogoutUserSession = async () => {
    const session_id = localStorage.getItem('session_id');
    try {
      const res = await apiClient.post('/logoutUserSession', {
        command: 'logoutUserSession',
        session_id: session_id,
      });
      if (res.data.status === 'success') {
        localStorage.removeItem('session_id');
      }
    } catch (error) {
      console.log('Error while handleLogoutUserSession');
    }
  };

  const handleDelete = async () => {
    const fullPhoneNumber = countryCode + mobileNumber;
    try {
      const res = await apiClient.post('/deleteAccount', {
        command: 'deleteAccount',
        user_phone_number: fullPhoneNumber,
      });

      if (res.data.status === 'success') {
        handleLogoutUserSession();
        toast.success(res.data.status_message);
        localStorage.removeItem('user');
        setUser(null);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        toast.error(res.data.errorMessage);
      }
    } catch (error) {
      console.log('Error in handleDelete:', error);
      toast.error('An error occurred while deleting your account.');
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  const handleOTPChange = (index, value) => {
    if (/^[0-9a-zA-Z]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleVerifyOtp();
    }
  };

  const handleSendOtp = async () => {
    const fullPhoneNumber = countryCode + mobileNumber;
    try {
      setLoading(true);
      const otpRes = await apiClient.post('/sendVerifyOtpMsg91', {
        command: 'sendVerifyOtpMsg91',
        user_phone_number: fullPhoneNumber,
      }, {
        headers: {
          'x-api-key': 'YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg',
        },
      });
      if (otpRes.data?.type === 'success') {
        setOtpSent(true);
        setShowMobileInput(false);
        toast.success('OTP sent successfully.');
      } else {
        toast.error(otpRes.data.errorMessage);
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Error sending OTP.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    const fullPhoneNumber = countryCode + mobileNumber;
    const enteredOTP = otp.join('');
    try {
      setLoading(true);
      const response = await apiClient.post('/verifyOtpMsg91', {
        command: 'verifyOtpMsg91',
        otp: enteredOTP,
        user_phone_number: fullPhoneNumber,
      });

      if (response.data.type === 'success') {
        setIsOtpVerified(true);
        toast.success('OTP Verified successfully!');
        handleDelete();
      } else {
        toast.error(response.data.message || 'Invalid OTP.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('Error verifying OTP.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-10 flex items-center justify-center p-6">
      <Helmet>
        <title>BME Bharat | Delete account</title>
        <meta property="og:title" content="BME Bharat | Delete account" />
        <meta
          property="og:description"
          content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering."
        />
        <meta
          property="og:url"
          content="https://bmebharat.com/user-dashboard/profile/deleteaccount"
        />
      </Helmet>

      <div className="bg-white rounded-lg shadow-lg p-6 w-auto">
        <div className="w-full flex justify-center items-center">
          <img src={logo} alt='' className="h-32 w-auto" />
        </div>

        <h2 className="text-lg font-semibold mt-4 text-center">
          We’re Sorry to See You Go!
        </h2>
        <p className="mb-2 text-left ml-0 md:ml-14">
          If you’d like to delete your account, please follow the steps below:
        </p>

        <div className="space-y-2 text-left ml-6">
          <ul>
            <li><strong>1. Enter Your Phone Number:</strong> Provide the phone number associated with your account.</li>
            <li><strong>2. Get OTP (One-Time Password):</strong> Click the "Send OTP" button to receive a verification code via SMS.</li>
            <li><strong>3. Verify OTP:</strong> Enter the OTP you received to confirm your identity.</li>
            <li><strong>4. Submit Account Deletion Request:</strong> Click "Delete My Account" to complete the process.</li>
          </ul>
        </div>

        <h3 className="text-lg font-semibold mt-4 text-center">What Happens Next?</h3>
        <p className="text-center mb-4">
          Your account will be permanently deleted, along with all associated data, within 5 business days.
        </p>
        <p className=" text-center">  
            
            You will permanently lose all data associated with this account,<br></br> including your posts in the article and forum sections,<br></br> comments, uploaded files (images, videos, documents), and transaction details.
          </p>
        <p className="text-center mb-4 text-bme_blue">
          Once deleted, you will no longer have access to your account.
        </p>

        <p className="  text-center mt-4 ">
          <strong className='text-lg'>Changed Your Mind?</strong><br></br> If you wish to return, you are always welcome! Simply sign up again to create a new account.
        </p>

        {showMobileInput && (
          <div className="flex flex-col space-y-2 mt-10 w-96 mx-auto">
         <p className='text-center'><strong className='text-lg'>Request Account Deletion</strong></p> 
            <label htmlFor="mobile">Enter your Mobile Number</label>
            <div className="flex space-x-2">
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="p-2 w-[90px] border rounded"
              >
                {CountryCodes.map((code, index) => (
                  <option key={index} value={code.value}>
                    {code.label} ({code.value})
                  </option>
                ))}
              </select>
              <input
                type="text"
                className="w-full p-2 border rounded"
                placeholder="Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                maxLength={10}
              />
            </div>
            <button
              onClick={handleSendOtp}
              className=" text-bme_blue border border-bme_blue w-fit p-4 rounded py-2 mt-4 mx-auto"
              disabled={loading || !mobileNumber}
            >
              {loading ? 'Sending OTP...' : 'Send OTP'}
            </button>
          </div>
        )}

        {otpSent && !isOtpVerified && (
          <div className="flex flex-col justify-center items-center space-y-2 mt-4">
          <p className='text-center'><strong className='text-lg'>Request Account Deletion</strong></p> 
            <label htmlFor="otp">Enter OTP<span className="text-red-500"> *</span></label>
            <div className="flex justify-center gap-3">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  className="w-11 h-11 border rounded text-center text-xl focus:outline-none focus:border-blue-500"
                  placeholder="•"
                  type="tel"
                  maxLength={1}
                  onChange={(e) => handleOTPChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  value={digit}
                  ref={(ref) => (otpInputs.current[index] = ref)}
                />
              ))}
            </div>
            <button
              onClick={handleCancel}
              className="custom-btn w-fit mx-auto mt-4"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="custom-btn w-fit mx-auto "
              onClick={handleVerifyOtp}
              disabled={loading}
            >
              {loading ? (
                <div className="flex justify-center items-center text-white">
                  <LoaderButton />
                  Verifying OTP
                </div>
              ) : (
                'Verify OTP and Delete Account'
              )}
            </button>
          </div>
        )}
        <CustomToastContainer />
      </div>
    </div>
  );
};

export default DeleteAccountGlobal;
