import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom"; // Import Navigate for redirects
import JobList from "./Jobs/JobList";

import CompanyList from "./Company/CompanyList";
import CompanyDetails from "./Company/CompanyDetails";
import UserForumList from "./Forum/UserForumList";
import UserForumDetails from "./Forum/UserForumDetails";
import UserForumPost from "./Forum/UserForumPost";
import UserUpdateProfile from "./Profile/UserUpdateProfile";

import MyForumList from "./Profile/MyForumList";
import UserSubscription from "./Profile/UserSubscription";
import MyJobProfile from "./Profile/MyJobProfile";
import MyForumEdit from "./Profile/MyForumEdit";
import MyJobsApplied from "./Profile/MyJobsApplied";
import MyjobProfileEdit from "./Profile/MyjobProfileEdit";
import MyjobsAppliedDetails from "./Profile/MyjobsAppliedDetails";
import UserProfileScreen from "./Profile/UserProfile"
import Navbar from "../../Common/Navbar"; // Import Navbar
import JobDetails from "./Jobs/JobDetails";
import UserHome from "./UserHome";
import About from "../Main/About-Page/About";
import Guidelines from "../Main/Guidelines-Page/Guidelines";
import Cancellation from "../Main/Guidelines-Page/Cancellation";
import Privacypolicy from "../Main/Guidelines-Page/PrivacyPolicy";
import Refund from "../Main/Guidelines-Page/Refunds";
import Subscription from "../Main/Guidelines-Page/Subscription";
import Footer from "../../Common/Footer";
import UserComments from "./Forum/UserComments";
import MySubscriptionList from "./Profile/MySubscriptionList";
import PdfViewer from "../../Common/PdfViewer";
import { TrialContext } from "../../TrialContext";
import MyJobProfileCreate from "./Profile/MyJobProfileCreate";
import { AuthContext } from "../../AuthContext";
import apiClient from "../../ApiClient";
import { IoIosLogOut } from "react-icons/io";
import DeleteAccount from "./Profile/DeleteAccount";
import AccountChecker from "../../AccountStatusMonitor";
import ContactUs from "../../Common/ContactUs";
import { Helmet } from "react-helmet";
import UserList from "./Users/UsersList";
import UserDetails from "./Users/UserDetails";
import Loader from "../../Common/Loader";
import LoaderButton from "../../Common/LoaderButton";
import LogoutLoader from "../../Common/LogoutLoader";


function UsersDashboard() {
  const { trialVisible, setTrialVisible } = useContext(TrialContext);
  const { user, setUser } = useContext(AuthContext)
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await apiClient.post("/getUserDetails", {
          command: "getUserDetails",
          user_id: user.user_id,
        });
        // console.log(response)
        const subscriptionExpiresOn = response.data.status_message.subscription_expires_on;
        // console.log("subscriptionExpiresOn",subscriptionExpiresOn)
        setFormattedDate(new Date(subscriptionExpiresOn * 1000).toLocaleString());
        const currentTime = Math.floor(Date.now() / 1000);
        // console.log("currentTime",currentTime)

        if (subscriptionExpiresOn < currentTime) {
          setSubscriptionExpired(true);
        }
      } catch (error) {
        console.error('Error fetching user details from API:', error);

      }
    };

    const checkUserSession = async () => {
      const session_id = localStorage.getItem("session_id")
      try {
        const response = await apiClient.post("/checkUserSession", {
          command: "checkUserSession",
          session_id: session_id
        })

        if (response?.data?.data?.isActive === false) {
          handleLogout()
        }

      } catch (error) {
        console.log("Error while check user Session", error)
      }
    }
    checkUserSession()
    getUserDetails();
    const intervalId = setInterval(() => {
      checkUserSession();
      getUserDetails();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [user])

  const handleSubscribe = () => {
    navigate('/usersubscribe')
    setSubscriptionExpired(false)
  };

  const handleLogout = () => {
    setIsLoggingOut(true)
    setTimeout(() => {
      setIsLoggingOut(false)
      localStorage.removeItem("user");
      localStorage.removeItem("session_id");
      setUser(null);
      navigate("/homepage");
    }, 5000);
  };

  if (isLoggingOut) {
    return <LogoutLoader/>
  }

  return (
    <div>
      <Helmet>
        <title>BME Bharat | User dashboard</title>
        <meta property="og:title" content="BME Bharat | User dashboard"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard" />
      </Helmet>

      <AccountChecker />
      {/* bg-[#f6f6f5] bg-[#E3E6E6]*/}
      <div className="main-content ">
        <Navbar />

        <Routes>
          {/* Set the default route to redirect to jobs or any other page */}
          <Route path="/" element={<UserHome />} />
          <Route path="jobs" element={<JobList />} />
          <Route path="jobs/user-job-detail/:post_id" element={<JobDetails />} />

          <Route path="company/list" element={<CompanyList />} />
          <Route path="company/details/:company_id" element={<CompanyDetails />} />
          <Route path="forums" element={<UserForumList />} />
          <Route path="forums/details/:forum_id" element={<UserForumDetails />} />
          <Route path="forums/post" element={<UserForumPost />} />
          <Route path="about" element={<About />} />
          <Route path="guidelines" element={<Guidelines />} />
          <Route path="guidelines/privacypolicy" element={<Privacypolicy />} />
          <Route path="guidelines/cancellation" element={<Cancellation />} />
          <Route path="guidelines/termsandconditions" element={<Refund />} />
          <Route
            path="guidelines/legalpolicy"
            element={<Subscription />}
          />
          <Route path="forums/comments/:forum_id" element={<UserComments />} />

          {/* Profile ✅ */}
          <Route path="profile" element={<UserProfileScreen />} />
          <Route path="profile/user-update" element={<UserUpdateProfile />} />

          <Route path="profile/my-forum-list" element={<MyForumList />} />
          <Route path="profile/subscription" element={<UserSubscription />} />
          <Route path="profile/mysubscriptions" element={<MySubscriptionList />} />
  


          {/* Users List and details */}
          <Route
            path="users/list"
            element={<UserList />}
          ></Route>
          <Route
            path="users/user_details/:user_id"
            element={<UserDetails />}
          ></Route>
          {/* Users List and details */}

          {/* <Route
            path="profile/subscription-list"
            element={<MySubscriptionList />}
          /> */}
          <Route path="profile/my-forum-edit" element={<MyForumEdit />} />
          <Route path="profile/my-job-profile" element={<MyJobProfile />} />
          <Route path="profile/my-job-apply" element={<MyJobsApplied />} />

          <Route
            path="profile/my-job-profile-edit"
            element={<MyjobProfileEdit />}
          />
          <Route
            path="/profile/create-job-profile"
            element={<MyJobProfileCreate />}
          />
          <Route path="profile/details" element={<MyjobsAppliedDetails />} />
          {/* pdf viewer */}
          <Route path='/doc-reader' element={<PdfViewer />} />

          {/* delete profile */}
          <Route path='profile/deleteaccount' element={<DeleteAccount />} />

          {/* contact-us */}
          <Route path='contact-us' element={<ContactUs />} />


        </Routes>
        <Footer />
        {trialVisible && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg max-w-sm w-full text-center">
              <div className="font-bold text-lg">🎉 Enjoy Your 15-Day Free Trial! 🎉</div>
              <div className="mt-2">Experience all the premium features of our app at no cost for 15 days. Dive in and explore everything we have to offer.</div>

              <button
                onClick={() => setTrialVisible(false)}
                className="mt-4 w-full custom-btn">
                OK
              </button>
            </div>
          </div>
        )}
      </div>
      {subscriptionExpired && (
        <div className="fixed inset-0 flex items-center justify-center bg-red-600 bg-opacity-50 z-50">
          <div className="card bg-white p-4 rounded shadow-lg mx-3 md:mx-0">
            <h2 className="text-lg font-bold "><span className="text-red-600 text-lg font-bold">Attention:</span> Subscription Expired</h2>
            <p>Your subscription expired on: {formattedDate}.<br></br> Don’t miss out! Renew now to keep enjoying uninterrupted service.</p>

            <div className="mt-2 flex ">
              <button
                onClick={handleSubscribe}
                className="custom-btn mr-2"
              >
                Subscribe
              </button>
              <button
                onClick={handleLogout}
                className="flex items-center custom-btn text-red-600 justify-center"
              >
                <IoIosLogOut className="mr-2" /> Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersDashboard;
