import React, { useContext, useEffect, useRef, useState } from "react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import logo from "../../assets/images/logo-crop.png"
import logoTop from "../../assets/images/logoTop.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import uparrow from "../../assets/images/up-arrow.png"
import { IoIosArrowDown, IoIosLogOut } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";
import Loader from "./Loader";
import apiClient from "../ApiClient"
import NotificationsUser from "./NotificationsUser";


const NavbarCompany = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("");
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const { user, setUser, loading } = useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    // console.log(dropdownRef.current); 
  }, [notificationModalOpen]);


  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response =await apiClient.post('/getUnreadNotificationCount', {
       
            command: 'getUnreadNotificationCount',
            user_id: user.user_id
        });

        const data = response.data;

        if (data.statusCode === 200) {
          setUnreadCount(data.count);
        } else {
          console.error('Failed to fetch unread count');
        }
      } catch (error) {
        console.error('Error fetching unread notifications count:', error);
      }
    };

    fetchUnreadCount();
  }, [notificationModalOpen,isOpen,activeDropdown,user.user_id]);

  // scroll to top btn starts ✅
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    if (window.scrollY > 50) {
      setScrolled(true); 
    } else {
      setScrolled(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    setActiveDropdown("");
    setNotificationModalOpen(false)
    setIsOpen(false)
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown("");
        setIsOpen(false)
        // setNotificationModalOpen(false)
        // console.log("click outside")
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);

    };
  }, []);


  const handleDropdownClick = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? "" : dropdown);
  };

  const handleItemClick = () => {

    setIsOpen(false);
    setActiveDropdown("");
    setNotificationModalOpen(false)
  };


  const isActive = (path) => location.pathname.startsWith(path);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (!user && storedUser) {
      setUser(JSON.parse(storedUser));
    }

    if (!loading && !user && !storedUser) {
      navigate("/");
    }
  }, [user, loading, navigate, setUser]);


  const handleLogoutUserSession = async () => {
    const session_id = localStorage.getItem('session_id')

    try {
      const res = await apiClient.post("/logoutUserSession", {
        command: "logoutUserSession",
        session_id: session_id
      })

      if (res.data.status === "success") {
        localStorage.removeItem("session_id")
      }
    } catch (error) {
      console.log("Error while handleLogoutUserSession")
    }
  }

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');

    if (confirmLogout) {
      handleLogoutUserSession()
      localStorage.removeItem('user');
      localStorage.removeItem("session_id");
      setUser(null);
      navigate('/');
    }
  };

  const handleNotifications = () => {
    setNotificationModalOpen(!notificationModalOpen)
  }

  if (loading) {
    return <div><Loader /></div>;
  }


  return (
    <>
      <div className="bg-white shadow-md fixed mt-0  w-[100%] z-50">

        {/* sticky Scroll to top */}
        <button
          className={`fixed z-50 bottom-24 right-10 rounded-full p-3 transition-opacity duration-300 ease-in-out transform ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-75'}`}
          onClick={scrollToTop}
          aria-label="Scroll to top"
          title="Scroll to Top!"
        >
          <img src={uparrow} alt="scroll to top icon "
            onContextMenu={(e) => e.preventDefault()}
            className="w-12 h-12" />
        </button>

        {/* scroll to top ends ❎ */}

        {/* First Row: Logo item Logout Button ✅ */}

        <div className="flex items-center justify-between px-4 py-2 text-[#075CAB] md:px-8 md:py-4 border-b mx-3 md:mx-10">
        <Link to="/" >
        {scrolled ? (
        <img
          src={logoTop}
          alt="Logo"
          onContextMenu={(e) => e.preventDefault()}
          className="transition-all duration-300 ease-in-out h-[50px] -ml-5 md:h-[60px]"  // Transition when scrolled
        />
      ) : (
        <img
          src={logo}
          alt="Logo"
          onContextMenu={(e) => e.preventDefault()}
          className="transition-all duration-300 ease-in-out h-[70px] md:h-[90px]"  // Default larger logo size
        />
      )}

          </Link>
          <div className=" justify-center items-center gap-2 hidden lg:flex">
            <div
              ref={dropdownRef}
              className={`lg:flex `}

            >

              <div className="relative" >
                <button
                  className={`flex items-center px-4 py-2 font-bold  text-[#075CAB] md:px-8 md:py-4   hover:bg-gray-100 rounded ${isActive("/user-dashboard/about") ? "bg-gray-100" : ""}`}
                  onClick={() => {
                    navigate('/user-dashboard/about')
                    window.scrollTo(0,0)
                    handleItemClick()
                  }}
                >
                  About
                </button>
              </div>

              <div className="relative" onMouseEnter={() => setActiveDropdown("jobs")} onMouseLeave={() => setActiveDropdown("")} >
                <button
                  className={`flex items-center px-4 py-2 font-bold  text-[#075CAB] md:px-8 md:py-4   hover:bg-gray-100 rounded ${isActive("/user-dashboard/jobs") ? "bg-gray-100" : ""}`}
                >
                  Jobs <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </button>
                <div
                  className={`absolute z-20 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "jobs" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-50 no-underline border-b border-blue-200"
              `}
                    to="/user-dashboard/jobs"
                    onClick={handleItemClick}
                  >
                    Latest jobs
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-50 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-job-apply"
                    onClick={handleItemClick}
                  >
                    Applied jobs
                  </Link>

                </div>
              </div>

           

              {/* Companies Dropdown */}
              <div className="relative" onMouseEnter={() => setActiveDropdown("companies")} onMouseLeave={() => setActiveDropdown("")}>
                <button
                  className={`flex items-center px-4 py-2 font-bold  text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/company") ? "bg-gray-100" : ""}`}
                >
                  Companies <IoIosArrowDown style={{ fill: "#075cab" }} className="h-5 w-5 ml-2" />
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "companies" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/company/list"
                    onClick={handleItemClick}
                  >
                    Companies
                  </Link>
                </div>
              </div>



              {/* Forum Dropdown */}
              <div className="relative" onMouseEnter={() => setActiveDropdown("forums")} onMouseLeave={() => setActiveDropdown("")}>
                <button
                  className={`flex items-center font-bold  px-4 py-2 text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/forums") ? "bg-gray-100" : ""}`}
                >
                  Forum <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "forums" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/forums"
                    onClick={handleItemClick}
                  >
                    Latest posts
                  </Link>
                  {/* <Link
      className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
      to="/user-dashboard/forums/details"
      onClick={handleItemClick}
    >
      Forum Details
    </Link> */}
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-forum-list"
                    onClick={handleItemClick}
                  >
                    My posts
                  </Link>

                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/forums/post"
                    onClick={handleItemClick}
                  >
                    Post a query
                  </Link>
                </div>
              </div>

              {/* Guidelines Dropdown */}
              <div className="relative" onMouseEnter={() => setActiveDropdown("guidelines")} onMouseLeave={() => setActiveDropdown("")}>
                <button
                  className={`flex items-center px-4 py-2 text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/guidelines") ? "bg-gray-100" : ""}`}
                >
                  <Link to="/user-dashboard/guidelines" className="flex no-underline font-bold text-[#075cab]">
                    Guidelines <IoIosArrowDown
                      style={{ fill: "#075CAB" }}
                      className="h-5 w-5 ml-2"
                    />
                  </Link>
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "guidelines" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/guidelines/privacypolicy"
                    onClick={handleItemClick}
                  >
                    Privacy policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/guidelines/cancellation"
                    onClick={handleItemClick}
                  >
                    Cancellation policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/guidelines/legalpolicy"
                    onClick={handleItemClick}
                  >
                    Legal policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/guidelines/termsandconditions"
                    onClick={handleItemClick}
                  >
                    Terms and conditions
                  </Link>
                </div>
              </div>

              {/* Profile Dropdown */}
              <div className="relative" onMouseEnter={() => setActiveDropdown("profile")} onMouseLeave={() => setActiveDropdown("")}>
                <button
                  className={`flex items-center font-bold px-4 py-2 text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/profile") ? "bg-gray-100" : ""}`}
                >
                  Profile <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "profile" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile"
                    onClick={handleItemClick}
                  >
                    My profile
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-job-apply"
                    onClick={handleItemClick}
                  >
                    Applied jobs
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-job-profile"
                    onClick={handleItemClick}
                  >
                    My job profile
                  </Link>
        
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-forum-list"
                    onClick={handleItemClick}
                  >
                    My posts
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/subscription"
                    onClick={handleItemClick}
                  >
                    Subscription
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/mysubscriptions"
                    onClick={handleItemClick}
                  >
                    My subscriptions
                  </Link>

                  <Link
                    className={` flex items-center px-4 py-2 text-red-700 hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/mysubscriptions"
                    onClick={handleLogout}
                  >
                    <IoIosLogOut style={{ fill: "red" }} className='mr-2 ' /> <strong className="text-red-500">Logout</strong>
                  </Link>


                </div>
              </div>

              <button onClick={() => {
                handleNotifications()
              }} className="relative ml-3 hover:bg-blue-50 p-2 rounded-full ">
                <IoNotifications style={{ fill: "#075cab" }} className="w-7 h-7" />
                {unreadCount > 0 && (
                  <div className="absolute top-0 right-0 text-xs text-white bg-green-500 rounded-full w-5 h-5 flex items-center justify-center">
                    {unreadCount}
                  </div>
                )}
              </button>
              <div className="justify-center flex-col items-center gap-8 md:hidden flex mr-5">
                <button onClick={handleLogout} className="md:hidden text-red-500 flex items-center btn-custom mb-5 ">
                  <IoIosLogOut className='mr-2' /> Sign Out
                </button>
              </div>
            </div>

          </div>

          <div className="lg:hidden flex items-center space-x-4">
          <button onClick={() => {
                handleNotifications()
              }} className="relative ml-3 hover:bg-blue-50 p-2 rounded-full ">
                <IoNotifications style={{ fill: "#075cab" }} className="w-8 h-8" />
                {unreadCount > 0 && (
                  <div className="absolute top-0 right-0 text-xs text-white bg-green-500 rounded-full w-5 h-5 flex items-center justify-center">
                    {unreadCount}
                  </div>
                )}
              </button>

            <button onClick={() => {setIsOpen(!isOpen)
            setNotificationModalOpen(false)}} aria-label="Menu">
              {isOpen ? (
                <XIcon className="h-10 w-10 text-blue-700" />
              ) : (
                <MenuIcon className="h-9 w-9 text-blue-700" />
              )}
            </button>
          </div>



        </div>

        {/* small screen starts here */}

        {isOpen && (
          <>
            <div
              ref={dropdownRef}
              className={`flex justify-center items-center flex-col gap-6 my-2 ml-5`}
            >
              <div className="relative" >
                <button
                  className={`flex items-center px-4 py-2 font-bold  text-[#075CAB] md:px-8 md:py-4   hover:bg-gray-100 rounded ${isActive("/user-dashboard/about") ? "bg-gray-100" : ""}`}
                  onClick={() => {
                    navigate('/user-dashboard/about')
                    window.scrollTo(0,0)
                    handleItemClick()
                  }}
                >
                  About
                </button>
              </div>

              <div className="relative" >
                <button
                  onClick={() => setActiveDropdown("jobs")}
                  className={`flex items-center font-bold px-4 py-2 text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/jobs") ? "bg-gray-100" : ""}`}
                >
                  Jobs <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </button>
                <div
                  className={`absolute z-20 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "jobs" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2  text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200"
              `}
                    to="/user-dashboard/jobs"
                    onClick={handleItemClick}
                  >
                    All jobs
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-job-apply"
                    onClick={handleItemClick}
                  >
                    Applied jobs
                  </Link>

                </div>
              </div>

        

              {/* Companies Dropdown */}
              <div className="relative" >
                <button
                  onClick={() => setActiveDropdown("companies")}

                  className={`flex items-center px-4 py-2 font-bold text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/company") ? "bg-gray-100" : ""}`}
                >
                  Companies <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "companies" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/company/list"
                    onClick={handleItemClick}
                  >
                    Companies
                  </Link>
                </div>
              </div>
              {/* Forum Dropdown */}
              <div className="relative" >
                <button
                  onClick={() => setActiveDropdown("forums")}

                  className={`flex items-center px-4 py-2 font-bold text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/forums") ? "bg-gray-100" : ""}`}
                >
                  Forum <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "forums" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/forums"
                    onClick={handleItemClick}
                  >
                    Posts
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-forum-list"
                    onClick={handleItemClick}
                  >
                    My posts
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/forums/post"
                    onClick={handleItemClick}
                  >
                    Post a query
                  </Link>
                </div>
              </div>

              {/* Guidelines Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setActiveDropdown("guidelines")}

                  className={`flex items-center px-4 py-2 text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/guidelines") ? "bg-gray-100" : ""}`}
                >
                  <Link to="/user-dashboard/guidelines" className="flex no-underline font-bold text-[#075CAB]">
                    Guidelines <IoIosArrowDown
                      style={{ fill: "#075CAB" }}
                      className="h-5 w-5 ml-2"
                    />
                  </Link>
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "guidelines" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/guidelines/privacypolicy"
                    onClick={handleItemClick}
                  >
                    Privacy policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/guidelines/cancellation"
                    onClick={handleItemClick}
                  >
                    Cancellation policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/guidelines/legalpolicy"
                    onClick={handleItemClick}
                  >
                    Legal policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/guidelines/termsandconditions"
                    onClick={handleItemClick}
                  >
                    Terms and conditions
                  </Link>
                </div>
              </div>

              {/* Profile Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setActiveDropdown("profile")}

                  className={`flex items-center px-4 py-2 font-bold text-[#075CAB] md:px-8 md:py-4  hover:bg-gray-100 rounded ${isActive("/user-dashboard/profile") ? "bg-gray-100" : ""}`}
                >
                  Profile <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "profile" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile"
                    onClick={handleItemClick}
                  >
                    Profile
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-job-apply"
                    onClick={handleItemClick}
                  >
                    Applied jobs
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-job-profile"
                    onClick={handleItemClick}
                  >
                    My job profile
                  </Link>
   
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/my-forum-list"
                    onClick={handleItemClick}
                  >
                    My posts
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/subscription"
                    onClick={handleItemClick}
                  >
                    Subscription
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-gray-100 no-underline border-b border-blue-200`}
                    to="/user-dashboard/profile/mysubscriptions"
                    onClick={handleItemClick}
                  >
                    My subscriptions
                  </Link>
                </div>
              </div>
              <div className=" justify-center flex-col items-center gap-8 flex mr-5">
                <button onClick={handleLogout} className=" flex  font-bold items-center btn-custom mb-4 text-red-500">
                  <IoIosLogOut style={{ fill: "red" }} className='mr-2' /> Sign Out
                </button>
              </div>
            </div>
          </>
        )}
        {/* ends here */}

        {notificationModalOpen && (
          <div ref={dropdownRef}>
          <NotificationsUser setNotificationModalOpen={setNotificationModalOpen} />
          </div>
        )}
      </div><div className="pt-[84px] md:pt-[105px] "></div></>
  );
};

export default NavbarCompany;
