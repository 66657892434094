import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import apiClient from '../../../ApiClient';
import { Helmet } from 'react-helmet';
import Loader from '../../../Common/Loader';
import { CustomToastContainer } from '../../../CustomToastContainer';

const MyJobsApplied = () => {
  const { user } = useContext(AuthContext);
  const userid = user.user_id;
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [companyid, setCompanyid] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      setLoading(true)
      try {
        const response = await apiClient.post(
          '/getUsersAppliedJobs',
          {
            command: 'getUsersAppliedJobs',
            user_id: userid,
          }
        );
        if (response.data.status === 'success' && response.data.response.length > 0) {
          setCompanyid(response.data.response[0].company_id);
          setAppliedJobs(response.data.response);
        } else {
          toast.info(
            response.data.errorMessage
          );
          setAppliedJobs([]);
        }
      } catch (error) {
        console.error('Error fetching applied jobs', error);
        toast.error('Network error', { position: 'top-center' });
      } finally {
        setLoading(false)
      }
    };
    
    fetchAppliedJobs();
  }, []);

 

  const handleRevoke = async (jobTitle) => {
    setLoading(true)
    try {
      const response = await apiClient.post(
        '/revokeJobs',
        {
          command: 'revokeJobs',
          company_id: companyid,
          user_id: userid,
          job_title: jobTitle,
        }
      );

      if (response.data.status === 'success') {
        setAppliedJobs((prevJobs) =>
          prevJobs.filter((job) => job.job_title !== jobTitle)
        );
        toast.success('Job Revoked. The application has been successfully revoked!', {
          
        });
      } else {
        toast.error(`Failed to revoke job: ${response.data.status_message}`, {
          
        });
      }
    } catch (error) {
      console.error('Error revoking job', error);
      toast.error(`Error revoking job: ${error.message}`, { position: 'top-center' });
    } finally {
      setLoading(false)
    }
  };
  const navigateToCreateProfile = () => {
    navigate('/user-dashboard/jobs')
  }
  const confirmRevoke = (jobTitle) => {
    if (window.confirm(`Are you sure you want to revoke the job "${jobTitle}"?`)) {
      handleRevoke(jobTitle);
    }
  };

  const handleDetails = (item) => {
    navigate('/user-dashboard/profile/details', { state: { jobDetails: item } });
  };

  const navigateToCompanyDetails = (company_id) => {
    navigate(`/user-dashboard/company/details/${company_id}`);
  };

  if (loading) {
    return <Loader/>
  }

  return (
    <div className="m-5 min-h-[60vh] bg-white">
     <Helmet>
        <title>BME Bharat | Applied jobs</title>
        <meta property="og:title" content="BME Bharat | Applied jobs"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/my-job-apply" />
      </Helmet>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {appliedJobs.length > 0 ? (
          appliedJobs.map((item) => (
            <div key={item.job_title} className="p-4  rounded-lg shadow-md" onClick={() => handleDetails(item)}>
              <div className="flex items-center justify-between mb-2">
                <h2 className="text-[16px] font-semibold ">{item.job_title}</h2>
                <button
                  onClick={(e) =>{ 
                    e.stopPropagation()
                    confirmRevoke(item.job_title)}}

                  className="custom-btn"
                >
                  Revoke
                </button>
              </div>
              <div className="inline-flex flex-col justify-start mb-2 cursor-pointer w-fit" onClick={(e)=> {
                      e.stopPropagation()
                      navigateToCompanyDetails(item.company_id)
                    }}>
                      <span className="font-bold text-lg">{item.company_name || "N/A"}</span>
                      <span className="text-sm text-gray-500 -mt-1">{item?.company_category}</span>
                    </div>
              <p className=" text-gray-600 mb-1">
                <strong>City:</strong> {item.company_located_city}
              </p>
              <p className=" text-gray-600 mb-2">
                <strong>Package:</strong> {item.Package}
              </p>
              <button
                onClick={() => handleDetails(item)}
                className="custom-btn w-fit mx-auto mt-4"
              >
                View More
              </button>
            </div>
          ))
        ) : (

 
          <div className="flex flex-col items-center ">
            <p className="text-center text-2xl">No jobs applied yet.</p>
            <button
              onClick={navigateToCreateProfile}
              className="custom-btn"
            >
              All jobs
            </button>
          </div>
          
        )}
      </div>
      <CustomToastContainer/>
    </div>
  );
};

export default MyJobsApplied;
