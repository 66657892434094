import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import apiClient from '../../../ApiClient';
import defaultImage from "../../../../assets/images/defaultImage1.jpg"
import LoaderButton from '../../../Common/LoaderButton';
import { Helmet } from 'react-helmet';
import { CustomToastContainer } from '../../../CustomToastContainer';

const MyForumEdit = () => {
  const [loading, setLoading] = useState(false)
  const { user } = useContext(AuthContext);
  const user_id = user.user_id 
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState()


  const location = useLocation();
  const { post, imageUrls } = location.state || {}
  // console.log(post)
  // console.log(imageUrls)
  const [file, setFile] = useState("");
  const [userid, setUserid] = useState("");
  const [fileType, setFileType] = useState('');
  const [fileKey, setFileKey] = useState(post?.fileKey || "");

  const [postData, setPostData] = useState({
    // title: post?.title || '',
    body: post?.forum_body || '',
    conclusion: post?.conclusion || '',
  });

  useEffect(() => {
    if (typeof imageUrls === "string") {
      setImageUrl(imageUrls);
    } else {
      setImageUrl(defaultImage);
    }
  }, [imageUrls]);

  useEffect(() => {
    if (!user) {
      toast.error("Please log in to update an article.");
      return;
    }

    setUserid(user.user_id);

    if (post) {
      const { fileKey } = post;
      setFileKey(fileKey || "");
      // setImageUrl(imageUrls || defaultImage); 

    }
  }, [user, post]);

  const handleFileChange = (event) => {

    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const sizeLimit = selectedFile.type.startsWith('image') ? 5 * 1024 * 1024 : 10 * 1024 * 1024;
      if (selectedFile.size > sizeLimit) {
        toast.error(selectedFile.type.startsWith('image') ? 'Image size limit exceeds 5 MB.' : 'Video size limit exceeds 10 MB.');
        setFile(null);
        return;
      }
      setFile(selectedFile);
      setFileType(selectedFile.type);
    }
  };


  const handleUploadFile = async () => {
    setLoading(true);
    if (!file) {
      return setFile(null);
    }
    const fileSize = file.size
    try {
      const res = await apiClient.post('/uploadFileToS3', {
        command: 'uploadFileToS3',
        headers: {
          'Content-Type': fileType,
          'Content-Length': fileSize
        },
      });
      if (res.data.status === "success") {
        let url = res.data.url
        const uploadFile = await axios.put(url, file, {
          headers: {
            "Content-Type": fileType
          }
        })
        console.log(uploadFile)
        if (uploadFile.status === 200) {
          setFile(file)
          setFileKey(res.data.fileKey)
          // toast.success('File uploaded successfuly')
          return res.data.fileKey
        }
      } else {
        console.log(res.data.errorMessage)
        toast.error(res.data.errorMessage)
      }
    } catch (error) {
      toast.error('An error occurred during file upload.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (item) => {
    const forum_id = item.forum_id
    const confirmDelete = window.confirm("Are you sure you want to delete this post?");
    if (confirmDelete) {
      try {
        const response = await apiClient.post('/deleteForumPost', {
          command: "deleteForumPost",
          user_id: user_id,
          forum_id: forum_id,
        });
        console.log(item)
        if (item.fileKey) {
          const res = await apiClient.post(
            "/deleteFileFromS3",
            {
              command: "deleteFileFromS3",
              key: item.fileKey,
            }
          );
        }

        if (response.data.status === 'success') {
          toast.success("The forum post has been successfully deleted.");
          navigate('/user-dashboard/profile/my-forum-list')
        } else {
          toast.error(response.data.status_message);
        }
      } catch (error) {
        toast.error("Error deleting post");
      }
    }
  };

  const handlePostUpdate = async (event) => {
    setLoading(true)
    event.preventDefault();
    try {

      if (fileKey && file) {
        try {
          // console.log("hi")
          const res = await apiClient.post("/deleteFileFromS3", {
            command: "deleteFileFromS3",
            key: post.fileKey,
          });

          // console.log("deleteFileFromS3",res)
        } catch (error) {
          toast.error("Failed to delete old image");
          return;
        }
      }

      let uploadedFileKey = fileKey;
      if (file) {
        try {
          uploadedFileKey = await handleUploadFile();

        } catch (error) {
          console.log("image upload failed", error)
        }
        // console.log(uploadedFileKey)
      }



      const postPayload = {
        command: 'updateForumPost',
        user_id: user.user_id,
        forum_id: post.forum_id,
        // title: postData.title,
        forum_body: postData.body,
        conclusion: postData.conclusion,
        fileKey: uploadedFileKey,
      };
      // console.log("postPayload",postPayload)
      // console.log("post",post)
      // console.log("forum_id",post.forum_id)


      const res = await apiClient.post('/updateForumPost', postPayload);

      if (res.data.status === 'SUCCESS') {
        toast.success(res.data.status_message);
        setTimeout(() => {
          navigate("/user-dashboard/forums");
        }, 1000);
      } else {
        toast.error(res.data.message || res.data.errorMessage);
        // console.log(res)
      }
    } catch (error) {
      console.error('Error in handlePostUpdate:', error.message);
      toast.error('An error occurred while updating the post.');
    } finally {
      setLoading(false)
    }
  };

  // if (loading) {
  //   return <Loader />;
  // }

  const deleteFileFromS3 = async (key) => {
    try {
      const response = await apiClient.post("/deleteFileFromS3", {
        command: "deleteFileFromS3",
        key: key,
      });
      console.log("deleteFileFromS3", response.data.message);
    } catch (error) {
      console.error("Error deleting file:", error);
      return false;
    }
  };

  const clearImage = async () => {
    setFile("");
    if (fileKey) {
      await deleteFileFromS3(fileKey)
    }
    setFileKey("");
    setImageUrl(defaultImage)
    const fileInput1 = document.getElementById('file-upload');
    if (fileInput1) fileInput1.value = '';
  };

  const handleCancel = () => {
    let confirm = window.confirm("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className='max-w-[1370px] w-full mt-10'>
      <Helmet>
        <title>BME Bharat | Post</title>
        <meta property="og:title" content="BME Bharat | Post"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/my-forum-edit" />
      </Helmet>
      <form className="max-w-3xl w-[90%] md:w-full mx-auto" onSubmit={handlePostUpdate}>
        {/* <div className="mb-4 w-full ">
          <label htmlFor="title" className="block mb-2 text-gray-900">
            Title
          </label>
          <input
            id="title"
            type="text"
            value={postData.title}
            onChange={(e) => setPostData({ ...postData, title: e.target.value })}
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
        </div> */}
        <div className="mb-4">
          <label htmlFor="body" className="block mb-2 mt-4 text-gray-900">
            Description
          </label>
          <textarea
            id="body"
            value={postData.body}
            onChange={(e) => setPostData({ ...postData, body: e.target.value })}
            className="bg-gray-50 h-52 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
        </div>

        <div className="mb-2">
          <label htmlFor="file-upload" className="block text-gray-900">
            Upload a file
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*,video/*"
            onChange={handleFileChange}
            className="text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 hidden w-full p-2.5"
          />
        </div>
        <div className=" flex justify-center items-center mx-auto">
          {/* starts */}
          {imageUrl && !file && (
            <div className="relative inline-block">

              {/* Check if the file is a video */}
              {imageUrl.match(/(.*)(\.(mp4|webm|ogg|avi|mov))(\?.*)?$/i) ? (
                <><video controls className=" rounded">
                  <source src={imageUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                  {fileKey && (

                    <button
                      type="button"
                      onClick={clearImage}
                      className="text-red-500 hover:underline mt-2 block"
                    >
                      Remove file <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
                    </button>

                  )}
                </>
              ) : (
                // Default case if it's an image or other supported type
                <>

                  <img
                    src={imageUrl}
                    alt="Uploaded Media"
                    onContextMenu={(e) => e.preventDefault()}
                    className=" rounded cursor-pointer"
                    onClick={() => document.getElementById("file-upload").click()} // Clicking on the image opens file picker
                  />
                  {fileKey && (
                    <button
                      type="button"
                      onClick={clearImage}
                      className="text-red-500 hover:underline mt-2 block"
                    >
                      Remove file <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
                    </button>
                  )}

                </>
              )}

              {/* Pencil Icon to change the video or image */}
              <button
                type="button"
                className="absolute top-0 right-0 bg-white p-1 rounded-full shadow"
                onClick={() => document.getElementById("file-upload").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>
            </div>
          )}

          {file && (
            <div className="relative mt-4 inline-block">
              {/* Show image if it's an image file */}
              {file.type.startsWith("image/") ? (
                <><img
                  src={URL.createObjectURL(file)}
                  alt="Selected File"
                  onContextMenu={(e) => e.preventDefault()}
                  className=" rounded cursor-pointer"
                  onClick={() => document.getElementById("file-upload").click()} /></>
              ) : (
                <>

                  <video controls className=" rounded">
                    <source src={URL.createObjectURL(file)} type={file.type} />
                    Your browser does not support the video tag.
                  </video></>

              )}

              {/* Pencil Icon to change the file */}
              <button
                type="button"
                className="absolute top-0 right-0 bg-white p-1 rounded-full shadow"
                onClick={() => document.getElementById("file-upload").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>

              {/* Remove Image Button */}


              <button
                type="button"
                onClick={clearImage}
                className="text-red-500 hover:underline mt-2 block"
              >
                Remove file <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>


            </div>
          )}
          {/* ends */}
        </div>

        


        <div className="flex gap-2 mt-3">
          <button type="submit" className="custom-btn" disabled={loading}>
            {loading ? (
              <div className='flex justify-center items-center'>
                <LoaderButton />
                Updating
              </div>
            ) : (
              <>Update</>
            )}
          </button>

          <button type="submit" className="custom-btn" disabled={loading} onClick={()=>{
            handleDelete(post)
          }}>
              Delete
          </button>

          <button className="custom-btn ml-2" onClick={(e) => {
            e.stopPropagation()
            handleCancel()
          }}>
            Cancel
          </button>
        </div>
      </form>
      <CustomToastContainer />
    </div>
  );
};

export default MyForumEdit;
