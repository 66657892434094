import React, { useState, useContext } from 'react'; 
import axios from 'axios';
import { AuthContext } from '../../../AuthContext';
import { HireType, SalaryType, ExperienceType, Speiciaalization,industryType } from '../../../Data';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CustomDropdown from '../../../Common/CustomDropdown';
import CustomDropDownMenu from "../../../Common/CustomDropdown";
import apiClient from '../../../ApiClient';
import LoaderButton from '../../../Common/LoaderButton';
import {CustomToastContainer} from '../../../CustomToastContainer';
import { Helmet } from 'react-helmet';

const CompanyJobPostScreen = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const userid = user.company_id;
    const [loading, setLoading] = useState(false)
    // console.log(userid);
    // console.log("hi")

    const [jobFormData, setJobFormData] = useState({
        job_title: "",
        preferred_languages: "",
        job_description: "",
        experience_required: "",
        speicializations_required: "",
        Package: "",
        industry_type:"",
        required_expertise:""
    });

    const handleInputChange = (key, value) => {
        setJobFormData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const isFormValid = () => {
        const { job_title, preferred_languages, job_description, experience_required, speicializations_required, Package,industry_type,required_expertise } = jobFormData;
        return (
            job_title.trim() !== "" &&
            preferred_languages.trim() !== "" &&
            job_description.trim() !== "" &&
            experience_required.trim() !== "" &&
            speicializations_required.trim() !== "" &&
            Package.trim() !== "" &&
            industry_type.trim() !== ""&&
            required_expertise.trim() !== ""
        );
    };

    const JobPostHandle = async () => {
        setLoading(true)
        if (!isFormValid()) {
            toast.error('All fields are required.');
            return setLoading(false)
        }

        const payload = {
            command: "createAJobPost",
            company_id: userid,
            job_title: jobFormData.job_title,
            preferred_languages: jobFormData.preferred_languages,
            job_description: jobFormData.job_description,
            experience_required: jobFormData.experience_required,
            speicializations_required: jobFormData.speicializations_required,
            Package: jobFormData.Package,
            industry_type: jobFormData.industry_type,
            required_expertise: jobFormData.required_expertise,
        };
        // console.log(payload)
        try {
          const res = await apiClient.post('/createAJobPost', payload);
            // console.log(res);
            if (res.data.status === "success") {
            // console.log(res.data);

                toast.success(res.data.status_message);
                setTimeout(() => {
                navigate('/company-dashboard/jobs');
                }, 1000);
            } else {
                toast.error("Failed to create job post.");
                toast.info(res.data.errorMessage);
            }
        } catch (error) {
            toast.error("Network error: " + error);
        } finally {
            setLoading(false)
        }
    };

    const handleCancel = () => {
        let confirm = window.confirm ("All unsaved changes will be discarded. Do you want to continue? ")
        if (confirm) {
          navigate(-1)
        }
      }

    return (
        <div className="flex flex-col items-center min-h-screen py-8 px-4 sm:px-6 lg:px-8">
            <Helmet>
        <title>BME Bharat | Post a job</title>
        <meta property="og:title" content="BME Bharat | Post a job"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/jobs/user-job-post" />
      </Helmet>
            <h1 className="custom-h1 mb-6 text-center">Post a job</h1>
            <div className="bg-white shadow-md rounded-lg w-full max-w-md sm:max-w-lg p-6">
                {/* Job Title */}
                <div className="mb-4">
                    <label htmlFor="job_title" className="block mb-2 font-medium text-gray-900">Job Title</label>
                    <input
                        id="job_title"
                        type="text"
                        placeholder="Job Title"
                        value={jobFormData.job_title}
                        onChange={(e) => handleInputChange('job_title', e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                    />
                </div>

                {/* Hire Type Dropdown */}
                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-900">Langauges required</label>
                    <input
                        id="lang"
                        type="text"
                        placeholder="Languages required (comma separated)"
                        value={jobFormData.preferred_languages}
                        onChange={(e) => handleInputChange('preferred_languages', e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                    />
                </div>

                <div className="mb-4">
            <label htmlFor="industryType" className="block mb-2 font-medium text-gray-900">
              Industry Type
            </label>
            <CustomDropDownMenu
              items={industryType}
              onSelect={(item) => handleInputChange("industry_type", item.label)}
              placeholder="Select industry type"
              id="industryType"
            />
          </div>

                {/* Salary Type Dropdown */}
                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-900">Salary Package</label>
                    <CustomDropdown
                        items={SalaryType}
                        onSelect={(item) => handleInputChange('Package', item.label)}
                        placeholder="Select Package"
                    />
                </div>

                {/* Experience Dropdown */}
                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-900">Experience Required</label>
                    <CustomDropdown
                        items={ExperienceType}
                        onSelect={(item) => handleInputChange('experience_required', item.label)}
                        placeholder="Select Experience"
                    />
                </div>

                {/* Specialization Dropdown */}
                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-900">Specializations Required</label>
                    {/* <CustomDropdown
                        items={Speiciaalization}
                        onSelect={(item) => handleInputChange('speicializations_required', item.label)}
                        placeholder="Select Specializations"
                    /> */}
                    <input
                        id="lang"
                        type="text"
                        placeholder="Specializations Required (comma separated)"
                        value={jobFormData.speicializations_required}
                        onChange={(e) => handleInputChange('speicializations_required', e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-900">Required expertise</label>
                    {/* <CustomDropdown
                        items={Speiciaalization}
                        onSelect={(item) => handleInputChange('speicializations_required', item.label)}
                        placeholder="Select Specializations"
                    /> */}
                    <input
                        id="lang"
                        type="text"
                        placeholder="Required expertise (comma separated)"
                        value={jobFormData.required_expertise}
                        onChange={(e) => handleInputChange('required_expertise', e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    
                    />
                </div>

                {/* Job Description */}
                <div className="my-4">
                    <label htmlFor="job_description" className="block mb-2 font-medium text-gray-900">Job Description</label>
                    <textarea
                        id="job_description"
                        placeholder="Write job description here..."
                        value={jobFormData.job_description}
                        onChange={(e) => handleInputChange('job_description', e.target.value)}
                        rows={4}
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                    />
                </div>

                {/* Post Button */}
                <div className="flex gap-2 mt-3">
                <button className="custom-btn" onClick={JobPostHandle} disabled={loading}>

{loading ? (
      <div className='flex justify-center items-center'>
        <LoaderButton />
        Submitting
      </div>
    ) : (
      <>Submit</>
    )}
  </button>
  <button className="custom-btn ml-2" onClick={(e)=>{
            e.stopPropagation()
            handleCancel()
          }}>
            Cancel
          </button>
                </div>
            </div>
            <CustomToastContainer/>
        </div>
    );
};

export default CompanyJobPostScreen;
