import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaComment, FaPlus, FaTrash } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { AuthContext } from "../../../AuthContext";
import apiClient from '../../../ApiClient';
import { toast } from "react-toastify";
import defaultImage from "../../../../assets/images/defaultImage.jpg"
import { Helmet } from "react-helmet";
import Loader from "../../../Common/Loader";
import { CustomToastContainer } from "../../../CustomToastContainer";

const MyCompanyForumList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [forum, setForum] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [noPostsFound, setNoPostsFound] = useState(false);
  const [loading, setLoading] = useState(false)

  const fetchPosts = async () => {
    setLoading(true)

    try {
      const response = await apiClient.post(
        "/getUsersAllForumPosts",
        { command: "getUsersAllForumPosts", user_id: user.user_id }
      );
      // console.log(response)
      // console.log(user.user_id)
      let posts = response.data.response;
      posts.sort((a, b) => b.posted_on - a.posted_on);
      setForum(posts);
      setFilteredPosts(posts);
      setNoPostsFound(posts?.length === 0);

      const urlsObject = {};
      await Promise.all(
        posts.map(async (post) => {
          if (post.fileKey) {
            try {
              const res = await apiClient.post("/getObjectSignedUrl", {
                command: "getObjectSignedUrl",
                key: post.fileKey,
              });

              const img_url = res.data;
              if (img_url) {
                urlsObject[post.forum_id] = img_url;
              }
            } catch (error) {
              console.error("Error getting signed URL for post:", error);
            }
          }
        })
      );
      setImageUrls(urlsObject);
    } catch (error) {
      console.log("Error fetching posts", error);
    } finally {
      setLoading(false)
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum?.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      // const title = post.title ? post.title.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();
      // title.includes(query) || 
      return body.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results?.length === 0);
  };


  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum?.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      // const title = post.title ? post.title.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();
      // title.includes(query) ||
      return  body.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results?.length === 0);
  }, [searchQuery, forum])

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    }).replace(/ /g, "/");
  };

  const handleDelete = async (item) => {
    const forum_id = item.forum_id
    const confirmDelete = window.confirm("Are you sure you want to delete this post?");
    if (confirmDelete) {
      try {
        const response = await apiClient.post('/deleteForumPost', {
          command: "deleteForumPost",
          user_id: user.user_id,
          forum_id: forum_id,
        });
        // console.log(fileKey)
        if (item.fileKey) {
          const res = await apiClient.post(
            "/deleteFileFromS3",
            {
              command: "deleteFileFromS3",
              key: item.fileKey,
            }
          );

          // console.log("deleteFileFromS3",res)
        }

        if (response.data.status === 'success') {
          setFilteredPosts(filteredPosts.filter(post => post.forum_id !== forum_id));
          toast.success("The forum post has been successfully deleted.");
        } else {
          toast.error(response.data.status_message);
        }
      } catch (error) {
        toast.error("Error deleting post");
      }
    }
  };

  
  const navigateToForumDetails = (forum_id) => {
    navigate(`/admin-dashboard/forums/details/${forum_id}`)
  }

  const handleEditForum = (item, imageUrls) => {
    navigate("/admin-dashboard/profile/my-forum-edit", {
      state: { post: item, imageUrls },

    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    // console.log("Image URL: ", imageUrl);
  }

  const timeAgo = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp * 1000;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) return 'Just now';
    if (minutes < 60) return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    if (hours < 24) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (days < 30) return `${days} day${days > 1 ? 's' : ''} ago`;

    return new Date(timestamp * 1000).toLocaleDateString();
  };
  

  const RenderPostItem = ({ item }) => {
    const imageUrl = imageUrls[item.forum_id] || defaultImage;
    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm"];
    const isVideo = item.fileKey && videoExtensions.some(ext => item.fileKey.endsWith(ext));

    return (
      <div className="w-full ">
        <div className="bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <div
            onClick={() =>navigateToForumDetails(item.forum_id)
            }
          >
            {isVideo ? (
              <video
                src={imageUrl}
                className="w-full h-64 object-contain rounded-t-lg"
                controls
                muted
                onError={(e) => { e.target.src = defaultImage; }}
              />
            ) : (
              <img
                src={imageUrl}
                alt={item.body?.slice(0,15)}

                onContextMenu={(e) => e.preventDefault()}
                className="w-full h-64 object-contain rounded-t-lg"
                onError={(e) => { e.target.src = defaultImage; }}
              />
            )}
            <div className="p-4">
            <div className="inline-flex flex-col justify-start mb-2 w-fit">
            <span className="font-bold text-lg">{item.author?.slice(0, 15) || "N/A"}{item.author?.length > 15 && ".."}</span>
                <span className="text-sm text-gray-500 -mt-1">{item?.author_category}</span>
              </div>
              <p className="mb-1">{item.forum_body?.slice(0, 50) || "Description not specified"} {item.forum_body?.length > 16 && ".."}</p>
         

              <div className="flex justify-start mb-2">
                <span className=" text-sm text-gray-500">{timeAgo(item.posted_on) || "N/A"}</span>
              </div>
             

              <div className="flex justify-between items-center text-sm text-gray-500">
                <Link to={`/admin-dashboard/forums/comments/${item.forum_id}`} onClick={(e) => {
                  e.stopPropagation()
                }} className="flex items-center text-blue-600 hover:text-blue-800 no-underline">
                  <FaComment className="mr-1" /> {item.comments_count || 0} Comments
                </Link>
                <span>{item.viewsCount || "0"} Views</span>
              </div>
              <div className="flex justify-start gap-2 mt-3 items-center text-sm text-gray-500">
                {item.user_id === user.user_id && (
                  <><button
                    className="hover:underline custom-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditForum(item, imageUrl)
                    }}
                  >
                    Edit Post
                  </button><button onClick={(e) => {
                    e.stopPropagation()
                    handleDelete(item)
                  }} className="flex items-center custom-btn">
                   Delete
                    </button></>
                )}
              </div>


            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="p-6 py-2 min-h-screen mt-6">
      <Helmet>
        <title>BME Bharat | My posts</title>
        <meta property="og:title" content="BME Bharat | My posts"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/admin-dashboard/profile/my-forum-list" />
      </Helmet>

      <div className="flex justify-center mb-6">
        <div className="relative w-full max-w-sm">
          {!searchQuery && (
            <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
          )}
          <input
            type="text"
            className="w-full pl-10 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="      Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
        </div>
      </div>
      <div className=" mx-auto">


        {noPostsFound ? (
          <div className="text-left text-gray-600">No posts found</div>
        ) : (
          <>
            <p className="mb-4 text-gray-600">{filteredPosts?.length} posts found</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredPosts.map((item) => (
                <RenderPostItem key={item.forum_id} item={item} />
              ))}
            </div>
          </>
        )}

        <Link
          to="/admin-dashboard/forums/post"
          className="fixed z-50 bottom-6 right-11 bg-bme_blue text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
        >
          <FaPlus style={{fill:"white"}} size={24} />

          </Link>
      </div>
      <CustomToastContainer/>
    </div>
  );
};

export default MyCompanyForumList;
