import React, { useState, useEffect, useContext } from 'react';
import apiClient from '../../../ApiClient';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import defaultImage from '../../../../assets/images/default_user.jpg';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Loader from '../../../Common/Loader';
import { CiSearch } from 'react-icons/ci'; // Make sure you have this icon library or adjust accordingly

const AdminUserList = () => {
  const [posts, setPosts] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const userId = user?.user_id;

  useEffect(() => {
    if (userId) {
      fetchAllUsersDetails();
    }
  }, [userId]);

  const fetchAllUsersDetails = async () => {
    setLoading(true);
    try {
      const response = await apiClient.post('/getCurrentUsers', { command: 'getCurrentUsers' });
      if (response.data.status === 'success') {
        const users = response.data.response;

        const sort_user = users
          .filter(user => user.user_type === "users")
          .sort((a, b) => b.user_created_on - a.user_created_on);
        
        setPosts(sort_user);

        const urlsObject = {};
        await Promise.all(
          users.map(async (post) => {
            if (post.fileKey) {
              try {
                const res = await apiClient.post('/getObjectSignedUrl', {
                  command: 'getObjectSignedUrl',
                  key: post.fileKey,
                });
                const img_url = res.data;
                if (img_url) {
                  urlsObject[post.user_id] = img_url;
                }
              } catch (error) {
                console.error('Error getting signed URL for post:', error);
              }
            }
          })
        );
        setImageUrls(urlsObject);
      } else {
        console.log('API Error:', response.data.status_message);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    // Filter users based on search query (e.g., by name or other attributes)
    return posts.filter((candidate) =>
      `${candidate.first_name} ${candidate.last_name}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };

  const navigateToDetails = (user_id) => {
    navigate(`/admin-dashboard/users/user_details/${user_id}`)
  };

  const filteredPosts = handleSearch();

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mx-4 md:mx-auto px-4">
      
      <Helmet>
        <title>BME Bharat | All users</title>
        <meta property="og:title" content="BME Bharat | All users" />
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/admin-dashboard/users/user_details" />
      </Helmet>
   
      {/* Search bar */}
      <div className="relative w-full max-w-sm mx-auto my-4">
        {!searchQuery && (
          <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
        )}
        <input
          type="text"
          className="w-full pl-10 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="      Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {filteredPosts.length > 0 ? (
        <>
          <p className="mb-4 text-gray-600">
            {filteredPosts.length} users found
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {filteredPosts.map((candidate) => (
              <div
                key={candidate.user_id}
                className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-shadow cursor-pointer"
                onClick={() => navigateToDetails(candidate.user_id)}
              >
                <div className="flex justify-center items-center mx-auto">
                  <img
                    src={imageUrls[candidate.user_id] || defaultImage}
                    onContextMenu={(e) => e.preventDefault()}
                    alt="Candidate"
                    className="w-32 h-32 shadow-md mb-4 rounded-full object-contain"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col justify-start mb-2">
                    <span className="font-bold text-lg text-[18px]">{candidate.first_name} {candidate.last_name}</span>
                    <span className="text-sm text-gray-500 -mt-1">{candidate?.category}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Profile</span>
                    <span>: {candidate.select_your_profile.slice(0,18) || 'NULL'} {candidate.select_your_profile.length > 18 && ".."} </span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">City</span>
                    <span>: {candidate.city || 'Not specified'}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">State</span>
                    <span>: {candidate.state || 'Not specified'}</span>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      className="custom-btn mt-4"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToDetails(candidate.user_id );
                      }}
                    >
                      View More
                    </button>
                    <button
                      className="custom-btn mt-4 mr-2"
                      onClick={() => window.open(
                        "https://wa.me/+918310491223",
                        "_blank",
                        "noopener noreferrer"
                      )}
                    >
                      Contact
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="text-gray-500">No candidates found</p>
      )}
    </div>
  );
};

export default AdminUserList;
