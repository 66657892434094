import React, { useEffect, useState } from 'react';
import apiClient from '../../../ApiClient'; // Adjust the import path if necessary
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../Common/Loader';
import { Helmet } from 'react-helmet';
import { CustomToastContainer } from '../../../CustomToastContainer';

const CompanyJobsAppliedList = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { post } = location.state || {};
  const companyId = post?.company_id;
  // console.log(post)

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      if (companyId && post) {
        try {
          const response = await apiClient.post(
            '/getJobAppliedCandidates', // Adjust the endpoint as needed
            {
              command: 'getJobAppliedCandidates',
              company_id: companyId,
              job_title: post.job_title,
            }
          );
          if (response.data && response.data.response) {
            setAppliedJobs(response.data.response);
          } else {
            const errorMsg = response.data.errorMessage || 'No jobs found';
            setError(errorMsg);
            toast.error(errorMsg);
          }
        } catch (error) {
          console.error('Error fetching applied jobs:', error);
          toast.error('An error occurred while fetching applied jobs');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAppliedJobs();
  }, [companyId, post]);

  const handleCardClick = (item) => {
    navigate('/company-dashboard/profile/my-post-jobs-Apply-details', {
      state: {
        user_id: item.user_id,
        applied_on: item.applied_on,
        post,
      },
    });
  };
  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      })
      .replace(/ /g, "/");
  };

  const renderJobItem = (item) => (
    <div
      className="p-4 max-w-sm w-full mx-auto border border-gray-300 rounded-lg mb-4 cursor-pointer"
      key={item.user_id}
      onClick={() => handleCardClick(item)}
    >
      <div>
      <div className="flex justify-start mb-2">
  <span className='font-bold'>{item.first_name}{item.last_name}</span>
</div>
<div className="flex justify-start mb-2">
  <span className="font-bold w-36">Email</span>
  <span>: {item.user_email_id || "N/A"}</span>
</div>
<div className="flex justify-start mb-2">
  <span className="font-bold w-36">Phone</span>
  <span>: {item.user_phone_number || "N/A"}</span>
</div>
<div className="flex justify-start mb-2">
  <span className="font-bold w-36">Applied on</span>
  <span>: {formatDate(item.applied_on) || "N/A"}</span>
</div>

        <div className="w-full flex items-center justify-center mx-auto mt-3">
        <button className="custom-btn">
          View more
        </button>
      </div>
      </div>
    </div>
  );

  if (loading) {
    return <div className="text-center mt-10"><Loader/></div>;
  }

  return (
    <div className=" flex flex-col  max-w-sm w-full mx-auto p-4">
      <Helmet>
        <title>BME Bharat | All applications</title>
        <meta property="og:title" content="BME Bharat | All applications"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/profile/my-post-jobs-apply" />
      </Helmet>

      <h1 className="text-center custom-h1 mb-6">Applications</h1>
      {appliedJobs.length === 0 && !error ? (
        <p className='text-center'>No Applied Jobs Found</p>
      ) : (
        <div>
          {appliedJobs.map((job) => renderJobItem(job))}
        </div>
      )}
      {error && <p className="text-red-500">{error}</p>}
      <div className="w-full flex items-center justify-center mx-auto mt-3">
        <button className="custom-btn" onClick={() => {
          navigate(-1);
        }}>
          Back
        </button>
      </div>
      <CustomToastContainer/>
    </div>
  );
};

export default CompanyJobsAppliedList;
