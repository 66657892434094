import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import dummy_pic from '../../../../assets/images/default_user.jpg';
import { stateCityData, CountryCodes } from '../../../Data';
import apiClient from '../../../ApiClient';
import { AuthContext } from '../../../AuthContext';
import { TrialContext } from '../../../TrialContext';
import LoaderButton from '../../../Common/LoaderButton';
import { CustomToastContainer } from '../../../CustomToastContainer';

const ProfileSelect = {
    "Biomedical Engineering Company Manufacturer": [
        "Diagnostic Equipment",
        "Wearable Health Tech",
        "Prosthetics and Implants",
        "Medical Devices",
        "Biotechnology Products",
        "Pharmaceuticals",
        "Laboratory Equipment",
        "Imaging Technology"
    ],
    "Dealer/Distributor": [
        "Medical Devices",
        "Laboratory Supplies",
        "Pharmaceuticals",
        "Healthcare IT Solutions",
        "Surgical Instruments",
        "Medical Imaging Devices",
        "Diagnostic Equipment",
        "Implantable Devices",
        "Wearable Health Monitors"
    ],
    "Biomedical Engineering Company - Service Provider": [
        "Equipment Maintenance",
        "Calibration Services",
        "Medical Imaging Services",
        "Biomedical Waste Management",
        "Installation Services",
        "Clinical Engineering Support",
        "Training and Education Services",
        "Telemedicine Services"
    ],
    "Healthcare Provider - Biomedical": [
        "Hospital Biomedical Department",
        "Clinical Lab",
        "Diagnostic Center",
        "Rehabilitation Center",
        "Home Healthcare"
    ],
    "Academic Institution - Biomedical": [
        "Biomedical Engineering Programs",
        "Research Institutions",
        "Training Centers",
        "Internship and Training Provider",
        "Healthcare Education",
        "Continuing Medical Education"
    ],
    "Regulatory Body": [
        "Medical Device Regulations",
        "Biomedical Ethics and Compliance",
        "Biotechnology Regulations",
        "Pharmaceutical Regulations",
        "Clinical Trial Oversight",
        "Quality Assurance"
    ],
    "Investor/Venture Capitalist": [
        "Medical Devices",
        "Biotechnology",
        "Pharmaceuticals",
        "Healthcare Startups",
        "Research and Development Funding"
    ],
    "Patient Advocate": [
        "Patient Education",
        "Patient Rights",
        "Healthcare Access",
        "Chronic Disease Advocacy",
        "Disability Support"
    ],
    "Healthcare IT Developer": [
        "Electronic Health Records (EHR)",
        "Telemedicine Solutions",
        "Healthcare Apps",
        "AI in Healthcare",
        "Data Analytics in Healthcare"
    ],
    "Biomedical Engineering Student": [
        "Undergraduate Student",
        "Graduate Student",
        "PhD Candidate",
        "Research Intern",
        "Project Collaborator"
    ],
    "Biomedical Engineering Professor/Academic": [
        "Lecturer",
        "Thesis Advisor",
        "Department Head",
        "Laboratory Director"
    ],
    "Biomedical Engineer": [
        "Research & Development Engineer",
        "Clinical Engineer",
        "Product Design Engineer",
        "Quality Assurance Engineer",
        "Regulatory Affairs Specialist",
        "Biomedical Engineer Sales/Service"
    ],
    "Biomedical Researcher/Scientist": [
        "Academic Researcher",
        "Industry Researcher",
        "Clinical Trials",
        "Innovation and Prototyping",
        "Medical Device Innovation",
        "Biomedical Research",
        "Clinical Research",
        "Biotechnology Research",
        "Pharmaceutical Research"
    ],
    "Consultant": [
        "Business Development Consulting",
        "Healthcare IT Consulting",
        "Regulatory Consulting",
        "Product Development Consulting",
        "Market Research Consulting",
        "Clinical Engineering Consulting",
        "Quality Assurance Consulting",
        "Medical Device Consulting"
    ],
    "Medical Professional": [
        "Decision Maker",
        "Doctor - Anaesthetist",
        "Doctor - Cardiologist"
    ],
    "Others": [
        "Others"
    ]
};

const CreateUser = () => {
    const [defaultDate, setDefaultDate] = useState('');
    const [userType, setUserType] = useState('');
    const [selectedProfile, setSelectedProfile] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('+91');

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        collage: '',
        selectedState: '',
        selectedCity: '',
        gender: '',
        dateOfBirth: '',
        imageUri: '',
        user_phone_number: '',
    });

    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState('');
    const [loading, setLoading] = useState(false);

    const states = Object.keys(stateCityData);
    const cities = formData.selectedState ? stateCityData[formData.selectedState] : [];


    //   profile type 
    const companyProfiles = Object.keys(ProfileSelect).filter(profile =>
        profile.includes("Company") ||
        profile.includes("Dealer") ||
        profile.includes("Provider") ||
        profile.includes("Regulatory") ||
        profile.includes("Investor") ||
        profile.includes("Advocate") ||
        profile.includes("Academic Institution") ||
        profile.includes("Healthcare IT Developer")
    );

    const normalProfiles = Object.keys(ProfileSelect).filter(profile =>
        !companyProfiles.includes(profile)
    );

    const handleUserTypeSelect = (event) => {
        const type = event.target.value;
        setUserType(type);
        setSelectedProfile('');
        setSelectedCategory('');
    };

    const profiles = userType === 'company' ? [...companyProfiles, "Others"] : normalProfiles;
    const categories = selectedProfile && ProfileSelect[selectedProfile] ? ProfileSelect[selectedProfile] : [];

    const handleProfileSelect = (event) => {
        const profile = event.target.value;
        setSelectedProfile(profile);
        setSelectedCategory('');
    };

    const handleCategorySelect = (event) => {
        setSelectedCategory(event.target.value);
    };
    ;
    // profile type

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageSelection = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 200 * 1024) {
                toast.error('File size exceeds 200 KB. Please upload a smaller image.');
                return;
            }

            setFormData({ ...formData, imageUri: URL.createObjectURL(selectedFile) });
            setFile(selectedFile);
            setFileType(selectedFile.type);
        }
    };

    const handleImagePreviewClick = () => {
        document.getElementById('image-upload-input').click();
    };

    const handleRemoveImage = () => {
        setFormData({ ...formData, imageUri: null });
        setFile(null);
        setFileType('');
    };

    const handleUploadFile = async () => {
        setLoading(true)
        const fileInput = document.getElementById('fileInput');
        const file = fileInput?.files[0];

        // if (!file) {
        //   toast.error('No image file selected. Please upload a valid image file.');
        //   throw new Error('No Image file selected.');
        // }
        // console.log(file)
        if (file) {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onloadend = async () => {
                    const base64 = reader.result.split(',')[1];
                    const fileType = file.type; // Get the file type dynamically
                    try {
                        const response = await apiClient.post('/uploadImage', {

                            command: 'uploadImage',
                            headers: { 'Content-Type': fileType },
                            fileBuffer: base64,

                        });

                        const result = await response.json();
                        if (response.ok && result.statusCode === 200) {
                            const body = JSON.parse(result.body);
                            resolve(body.fileKey);
                        } else {
                            reject(new Error('File upload failed'));
                        }
                    } catch (error) {
                        reject(error);
                    } finally {
                        setLoading(false)
                    }
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        }
    };


    const formatDate = (dateString) => {
        // Assuming input date format is YYYY-MM-DD, you can format it like DD/MM/YYYY
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handlePhoneChange = (e) => {
        const newPhone = e.target.value;
        setPhoneNumber(newPhone);
    }


    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        setLoading(true);

        if (!formData?.firstName?.trim()) {
            toast.error("First Name is required.");
            setLoading(false);
            return;
        }

        if (!phoneNumber.trim()) {
            toast.error("phone number is required.");
            setLoading(false);
            return;
        }

        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

        if (cleanedPhoneNumber.length !== 10) {
            toast.error("Phone number must have exactly 10 digits.");
            setLoading(false);
            return;
        }

        if (!formData?.email?.trim()) {
            toast.error("Email id is required.");
            setLoading(false);
            return;
        }

        if (!formData?.selectedState) {
            toast.error("State is required.");
            setLoading(false);
            return;
        }

        if (!formData?.selectedCity) {
            toast.error("City is required.");
            setLoading(false);
            return;
        }

        if (!userType) {
            toast.error("user type is required.");
            setLoading(false);
            return;
        }

        if (!selectedProfile) {
            toast.error("Profile is required.");
            setLoading(false);
            return;
        }

        if (!selectedCategory) {
            toast.error("Category is required.");
            setLoading(false);
            return;
        }

        if (!formData.gender) {
            toast.error("Select your gender");
            setLoading(false);
            return;
        }

        const dob = formatDate(formData?.dateOfBirth)
        const fullPhoneNumber = countryCode + phoneNumber

        try {
            const imageFileKey = await handleUploadFile();
            const payload = {
                command: 'signUpUsers',
                fileKey: imageFileKey,
                date_of_birth: dob,
                user_phone_number: fullPhoneNumber,
                category: selectedCategory,
                select_your_profile: selectedProfile,
                user_email_id: formData.email,
                state: formData.selectedState,
                city: formData.selectedCity,
                first_name: formData.firstName,
                last_name: formData.lastName,
                college: formData.collage,
                gender: formData.gender
            };
            console.log("payload",payload)

            const res = await apiClient.post('/signUpUsers', payload);
            console.log("res", res)
            
            if (res.data.status === "success") {
                toast.success('Successfully created account!');
            } else {
                toast.error(res.data.errorMessage);
            }
        } catch (error) {
            toast.error('Error submitting the form');
            // console.log(error);
        } finally {
            setLoading(false);
        }
    };


    // date of birth config..

    const today = new Date();

    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 13);
    const minAgeDate = minDate.toISOString().split('T')[0];


    useEffect(() => {
        const defaultDate = new Date();
        defaultDate.setFullYear(defaultDate.getFullYear() - 13);
        setDefaultDate(defaultDate.toISOString().split('T')[0]);
    }, []);

    return (
        <div className="max-w-2xl mx-auto mt-10 p-8 bg-white rounded-lg shadow-md">

            <h2 className="custom-h1 text-center mb-6">Create a user</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-4 text-center">
                    <img
                        src={formData.imageUri || dummy_pic}
                        alt="Profile"
                        onContextMenu={(e) => e.preventDefault()}
                        className="h-32 w-32 rounded-full object-cover mb-4 mx-auto cursor-pointer"
                        onClick={handleImagePreviewClick}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageSelection}
                        className="hidden"
                        id="image-upload-input"
                    />
                    <p className="text-gray-500 ">Click to upload image (Max 200KB)</p>

                    {formData.imageUri && (
                        <button
                            type="button"
                            onClick={handleRemoveImage}
                            className="text-red-600 hover:underline"
                        >
                            Remove Image X
                        </button>
                    )}
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">First Name<span className="text-red-500"> *</span>
                    </label>

                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"

                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">Last Name </label>


                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                </div>

                {/* <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">Phone number </label>


                    <input
                        type="tel"
                        name="user_phone_number"
                        value={formData.user_phone_number}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                </div> */}
                <label className="block mb-2 font-medium text-gray-700">Phone number<span className="text-red-500"> *</span> </label>

                <div className="flex items-center justify-center space-x-2 mb-4">

                    <select
                        id="countryCode"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        className="block text-center w-[40%] px-1 py-2 mr-1 border border-gray-300 bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        {CountryCodes.map((code, index) => (
                            <option key={index} value={code.value}>
                                {code.label} ({code.value})
                            </option>
                        ))}
                    </select>
                    <input
                        type="tel"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        placeholder="Enter 10-digit phone number"
                        required
                        className="block w-full rounded-lg px-3 py-2 border bg-none border-gray-300 bg-transparent shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>

                <label className="block mb-2">User Type<span className="text-red-500"> *</span></label>
                <select
                    className="block w-full mb-4 p-2 border border-gray-300 rounded"
                    onChange={handleUserTypeSelect}
                    value={userType}
                >
                    <option value="">Select User Type</option>
                    <option value="normal">Individual</option>
                    <option value="company">Business</option>
                </select>

                <label className="block mb-2">Profile<span className="text-red-500"> *</span></label>
                <select
                    className="block w-full mb-4 p-2 border border-gray-300 rounded"
                    onChange={handleProfileSelect}
                    value={selectedProfile}
                    disabled={!userType}
                >
                    <option value="">Select Profile</option>
                    {profiles.map(profile => (
                        <option key={profile} value={profile}>{profile}</option>
                    ))}
                </select>

                <label className="block mb-2">Category<span className="text-red-500"> *</span></label>
                <select
                    className="block w-full mb-4 p-2 border border-gray-300 rounded"
                    onChange={handleCategorySelect}
                    value={selectedCategory}
                    disabled={categories.length === 0}
                >
                    <option value="">Select Category</option>
                    {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">Email <span className="text-red-500"> *</span></label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"


                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">Gender <span className="text-red-500"> *</span></label>
                    <div className="flex gap-4">
                        <label>
                            <input
                                type="radio"
                                name="gender"
                                value="Male"
                                onChange={handleInputChange}
                                checked={formData.gender === 'Male'}
                            />{' '}
                            Male
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="gender"
                                value="Female"
                                onChange={handleInputChange}
                                checked={formData.gender === 'Female'}
                            />{' '}
                            Female
                        </label>
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">Date of Birth</label>
                    <input
                        type="date"
                        value={formData.dateOfBirth}
                        onChange={(e) => setFormData({ ...formData, dateOfBirth: e.target.value })}
                        max={minAgeDate}
                        className="w-fit p-2 border border-gray-300 rounded-lg"
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">State<span className="text-red-500"> *</span></label>
                    <select
                        name="selectedState"
                        value={formData.selectedState}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"

                    >
                        <option value="">Select State</option>
                        {states.map((state) => (
                            <option key={state} value={state}>
                                {state}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">City<span className="text-red-500"> *</span></label>
                    <select
                        name="selectedCity"
                        value={formData.selectedCity}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"

                    >
                        <option value="">Select City</option>
                        {cities.map((city) => (
                            <option key={city} value={city}>
                                {city}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-medium text-gray-700">Organisation / Institution</label>
                    <input
                        type="text"
                        name="collage"
                        value={formData.collage}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"

                    />
                </div>

                <div className='flex justify-center items-center'>

                    <button type="submit" className="mt-6 custom-btn" onClick={handleSubmit} disabled={loading}>
                        {loading ? (
                            <div className='flex justify-center items-center'>
                                <LoaderButton />
                                Submitting
                            </div>
                        ) : (
                            <>Submit</>
                        )}
                    </button>
                </div>
            </form>
            <CustomToastContainer />
        </div>
    );
};

export default CreateUser;
