import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaComment, FaEdit, FaPlus, FaThumbsUp } from "react-icons/fa";
import { CiImport, CiSearch } from "react-icons/ci";
import { AuthContext } from "../../../AuthContext";
import apiClient from '../../../ApiClient';
import defaultImage from "../../../../assets/images/defaultImage.jpg";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Loader from "../../../Common/Loader";
import { MdDelete } from "react-icons/md";
import { ImRocket } from "react-icons/im";
import { FaShareAlt } from 'react-icons/fa';  // for share icon
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import { CustomToastContainer } from "../../../CustomToastContainer";
import { SlReload } from "react-icons/sl";


// Main component for rendering the feed
const CompanyForumList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const user_id = user.user_id
  const [forum, setForum] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [imageUrlsProfile, setImageUrlsProfile] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [noPostsFound, setNoPostsFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [latestPosts, setLatestPosts] = useState([]);
  const [postText, setPostText] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  //  read more //
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getPreviewText = (text) => {
    const words = text?.split(" ");
    return words?.slice(0, 100)?.join(" ");
  };


    // pagination 🔥
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null); 
  const [hasMorePosts, setHasMorePosts] = useState(true);

  // Refs for post scrolling
  const postRefs = useRef([]);

  // Initialize refs for each post
  useEffect(() => {
    postRefs.current = postRefs.current?.slice(0, forum?.length);
  }, [forum]);

  // Fetch the posts from the server
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await apiClient.post("/getAllForumPosts", {
        command: "getAllForumPosts",
        limit: 20,
        lastEvaluatedKey: lastEvaluatedKey || null,
      });
      let posts = response.data.response

      const newLastEvaluatedKey = response.data.lastEvaluatedKey;
      posts.sort((a, b) => b.posted_on - a.posted_on); 
      setForum((prevPosts) => [...prevPosts, ...posts]); 
      setFilteredPosts((prevPosts) => [...prevPosts, ...posts]); 
      setLastEvaluatedKey(newLastEvaluatedKey);
      setHasMorePosts(!!newLastEvaluatedKey);


      setNoPostsFound(posts?.length === 0);
      const urlsObject = {};
      const urlsObjectProfile = {};
      await Promise.all(
        posts.map(async (post) => {
          if (post.fileKey) {
            try {
              const res = await apiClient.post("/getObjectSignedUrl", {
                command: "getObjectSignedUrl",
                key: post.fileKey,
              });
              const img_url = res.data;
              // console.log(img_url)
              if (img_url) {
                urlsObject[post.forum_id] = img_url;
              }
            } catch (error) {
              console.error("Error getting signed URL for post:", error);
            }
          }
          if (post.author_fileKey) {
            try {
              const res = await apiClient.post("/getObjectSignedUrl", {
                command: "getObjectSignedUrl",
                key: post.author_fileKey,
              });
              const img_url = res.data;
              // console.log(img_url)
              if (img_url) {
                urlsObjectProfile[post.forum_id] = img_url;
              }
            } catch (error) {
              console.error("Error getting signed URL for post:", error);
            }
          }
        })
      );
      setImageUrls(urlsObject);
      setImageUrlsProfile(urlsObjectProfile);

    } catch (error) {
      console.log("Error fetching posts", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);



  useEffect(() => {
    if (searchQuery?.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum?.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      const title = post.title ? post.title.toLowerCase() : "";
      const author = post.author ? post.author.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();

      return title.includes(query) || body.includes(query) || author.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results?.length === 0);
  }, [searchQuery, forum]);

  // Scroll to a specific post
  const scrollToPost = (index) => {
    const postElement = postRefs.current[index];
    console.log(postElement)
    if (postElement) {
      postElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const incrementViewCount = async (forumId) => {
    try {
      const res = await apiClient.post('/forumViewCounts', {
        command: 'forumViewCounts',
        forum_id: forumId,
      });
    } catch (error) {
      console.error("Error incrementing view count", error);
    }
  };

  const timeAgo = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp * 1000;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) return 'Just now';
    if (minutes < 60) return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    if (hours < 24) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (days < 30) return `${days} day${days > 1 ? 's' : ''} ago`;

    return new Date(timestamp * 1000).toLocaleDateString();
  };

  const navigateToUserDetails = (user_id, user_type) => {
    const path = user_type === 'company'
      ? `/admin-dashboard/company/details/${user_id}`
      : `/admin-dashboard/users/user_details/${user_id}`;

    navigate(path);
  };

  const navigateToCommentsPage = (forum_id) => {
    sessionStorage.setItem("scrollPositionFromCommentsToForum", window.scrollY);
    navigate(`/admin-dashboard/forums/comments/${forum_id}`)
  }

  const handleEditForum = (item, imageUrls) => {
    navigate("/admin-dashboard/profile/my-forum-edit", {
      state: { post: item, imageUrls },

    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  const handleDelete = async (item) => {
    const forum_id = item.forum_id
    const confirmDelete = window.confirm("Are you sure you want to delete this post?");
    if (confirmDelete) {
      try {
        const response = await apiClient.post('/deleteForumPost', {
          command: "deleteForumPost",
          user_id: user.user_id,
          forum_id: forum_id,
        });
        if (item.fileKey) {
          const res = await apiClient.post(
            "/deleteFileFromS3",
            {
              command: "deleteFileFromS3",
              key: item.fileKey,
            }
          );
        }
        if (response.data.status === 'success') {
          setFilteredPosts((prevPosts) => {
            return prevPosts.filter(post => post.forum_id !== forum_id);
          });
          setForum((prevForum) => {
            return prevForum.filter(post => post.forum_id !== forum_id);
          });
          fetchPosts()
          toast.success("The forum post has been successfully deleted.");
        } else {
          toast.error(response.data.status_message);
        }
      } catch (error) {
        toast.error("Error deleting post");
      }
    }
  };



  // Render individual post
  const RenderPostItem = ({ item, index }) => {
    const imageUrl = imageUrls[item.forum_id];
    const imageUrlProfile = imageUrlsProfile[item.forum_id];
    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm"];
    const isVideo = item.fileKey && videoExtensions.some(ext => item.fileKey.endsWith(ext));
    const postUrl = `${window.location.origin}/admin-dashboard/forums/details/${item.forum_id}`;
    return (
      <div
        ref={(el) => (postRefs.current[index] = el)}
        onClick={() => {
          incrementViewCount(item.forum_id)
        }}
        className="w-full flex flex-col bg-white rounded-lg shadow-md mb-6"
      >
        <div className="flex p-4 border-b">

          <img
            src={imageUrlProfile || defaultImage}
            alt="Author image"
            onClick={() => {
              navigateToUserDetails(item?.user_id, item?.user_type)
            }}
            className="w-10 h-10 rounded-full object-cover "
          />
          <div className="ml-4 flex-1">
            <div className="flex justify-between">
              <div className="inline-flex flex-col justify-start mb-2 cursor-pointer" >
                <span onClick={(e) => {
                  e.stopPropagation()
                  navigateToUserDetails(item?.user_id, item?.user_type)
                }}
                  className="font-bold text-base">{item?.author || "N/A"}</span>
                <span onClick={(e) => {
                  e.stopPropagation()
                  navigateToUserDetails(item?.user_id, item?.user_type)
                }} className="text-sm text-gray-500 -mt-1">{item?.author_category}</span>
              </div>
              <div>

                {item.user_id === user_id && (
                  <div className="flex space-x-1 items-end justify-end">
                    <button
                      className=" "
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditForum(item, imageUrl);
                      }}
                    >
                      <FaEdit className="w-5 h-5" />
                    </button>
                   
                  </div>
                )}
                <button onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(item);
                    }} className="flex items-center">
                     <MdDelete className="w-6 h-6"  />
                    </button>
                <div className="text-sm text-gray-500">{timeAgo(item.posted_on)}</div>
              </div>
            </div>

          </div>
        </div>


        {isVideo ? (
          <video
            src={imageUrl}
            alt={item.title}
            className="w-full h-auto object-contain rounded-t-lg -mt-5 mb-3"
            controls
            muted
          />
        ) : imageUrl ? (
          <img
            src={imageUrl}
            alt={item.title}
            onContextMenu={(e) => e.preventDefault()}
            className="w-full h-auto object-contain rounded-t-lg"
          />
        ) : null}


        <div className="p-4">
        <p style={{ whiteSpace: "pre-wrap" }}>
        {isExpanded ? item?.forum_body : getPreviewText(item.forum_body)}
        {!isExpanded && item?.forum_body?.split(" ")?.length > 200 && (
          <span
            onClick={toggleExpand}
            className="cursor-pointer text-bme_blue"
          >
             {""} .. <span className="cursor-pointer text-bme_blue underline">Read more</span>
          </span>
        )}
      </p>
          <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
  {/* First Row - Views and Comments */}
  <div className="flex justify-between w-full sm:w-auto sm:space-x-6">
    <span>{item.viewsCount || 0} Views</span>
    <div className="flex items-center space-x-3">
      <span onClick={() => navigateToCommentsPage(item.forum_id)} className="no-underline cursor-pointer text-black flex items-center">
        <FaComment className="mr-1" /> {item.comments_count || 0} Comments
      </span>
    </div>
  </div>

  {/* Second Row - Share Buttons */}
  <div className="flex  space-y-3 sm:space-y-0 sm:space-x-4 items-center justify-center sm:ml-4 mt-3 sm:mt-0">
  
    
    <div className="flex space-x-2 items-center justify-center">
    <FaShareAlt onClick={() => {
      const postUrl = `${window.location.origin}/admin-dashboard/forums/details/${item.forum_id}`;
      navigator.clipboard.writeText(postUrl).then(() => {
        toast.info("Link copied to clipboard!"); // Or use a toast notification
      }).catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    }} className="text-gray-600 cursor-pointer" />
      <FacebookShareButton url={postUrl} quote={item.title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={postUrl} title={item.title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={postUrl} title={item.title}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </div>
  </div>
</div>

        </div>
      </div>
    );
  };

  // Fetch and set trending posts
  const fetchTrendingPosts = () => {
    const trending = forum
      .filter(post => post.viewsCount > 10) // Example: filter posts with more than 10 views
      .sort((a, b) => b.viewsCount - a.viewsCount) // Sort by view count
      ?.slice(0, 5);
    setTrendingPosts(trending);
  };

  // Fetch and set latest posts
  const fetchLatestPosts = () => {
    const latest = forum
      ?.slice(0, 5) // Get top 5 most recent posts
      .sort((a, b) => b.posted_on - a.posted_on);
    setLatestPosts(latest);
  };

  useEffect(() => {
    if (forum?.length > 0) {
      fetchTrendingPosts();
      fetchLatestPosts();
    }
  }, [forum]);


  useEffect(() => {
    if (searchQuery?.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum?.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      const title = post.title ? post.title.toLowerCase() : "";
      const author = post.author ? post.author.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();

      return title.includes(query) || body.includes(query) || author.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results?.length === 0);
  }, [searchQuery, forum])

  const handleSearch = () => {
    if (searchQuery?.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum?.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      const title = post.title ? post.title.toLowerCase() : "";
      const author = post.author ? post.author.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();

      return title.includes(query) || body.includes(query) || author.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results?.length === 0);
  };


  const handleRefresh = () => {
    fetchPosts();
    setSearchQuery("");
  };



  const handleTextChange = useCallback((e) => {
    setPostText(e.target.value);
  }, []);

  // Handle navigation and sending post text
  const navigateToForumPost = useCallback(() => {
    navigate('/admin-dashboard/forums/post', { state: { postText } }); // Navigate with the post text
  }, [navigate, postText]);


  if (loading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col md:flex-row">
      <Helmet>
        <title>BME Bharat | Latest posts</title>
        <meta property="og:title" content="BME Bharat | Latest posts"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/admin-dashboard/forums" />
      </Helmet>


      {/* Sidebar (only visible on larger screens) */}
      <div className="w-full md:w-1/4 bg-gray-100 p-6 h-auto md:h-screen sticky top-0 space-y-6 hidden md:block">
        <div>
          <h2 className="text-lg font-semibold mb-2">Trending Posts 🔥</h2>
          <div className="space-y-4 cursor-pointer">
            {trendingPosts.map((item, index) => (
              <div key={item.forum_id} onClick={() => {
                const postIndex = forum.findIndex(post => post.forum_id === item.forum_id);
                scrollToPost(postIndex);
              }} className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                <div className="font-semibold text-sm text-gray-900">{item.forum_body?.slice(0, 85)} {item.forum_body?.length > 85 && ".."}</div>
                <div className="text-xs text-gray-500 mt-2">
                  {item.comments_count || 0} Comments | {item.viewsCount || 0} Views
                </div>

              </div>
            ))}
          </div>
        </div>

        {/* Latest Posts */}
        <div>
          <h2 className="text-lg font-semibold mb-4">Latest Posts ℹ️</h2>
          <div className="space-y-4 cursor-pointer">
            {latestPosts.map((item, index) => (
              <div key={item.forum_id} onClick={() => {
                const postIndex = forum.findIndex(post => post.forum_id === item.forum_id);
                scrollToPost(postIndex);
              }} className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                <div className="font-semibold text-sm text-gray-900">{item.forum_body?.slice(0, 85)} {item.forum_body?.length > 85 && ".."}</div>
                <div className="text-xs text-gray-500 mt-2">
                  {item.comments_count || 0} Comments | {item.viewsCount || 0} Views
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>


      {/* Posts Feed */}
      <div className="w-full md:w-3/4 p-3 md:p-6 space-y-6 overflow-y-auto">
        {/* Flex container for post textbox, import media, and search */}
        <div className="flex flex-col sm:flex-row mt-3 sm:mt-0 justify-between items-center mb-6 space-x-6">

          {/* Left side: Post Textbox and Import Media Button */}
          <div onClick={()=>{
            navigate('/admin-dashboard/forums/post')
          }} className="flex relative items-center space-x-4 w-full max-w-xl bg-white p-0 mb-3">
            <textarea
              className="w-full p-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
              placeholder="Write your post here..."
              rows="1"
              value={postText} // Bind textarea value to state
              onChange={handleTextChange}
            />
      <button
                onClick={() => navigateToForumPost()}
                className="hidden md:flex items-center absolute right-20   justify-center px-2 py-2 bg-bme_blue text-white rounded-lg  focus:outline-none transition-all"
              >
                Import media <CiImport className="font-bold ml-1" style={{ fill: 'white' }} />
              </button>

              <button
                onClick={() => navigateToForumPost()}
                className="flex absolute right-2 items-center justify-center px-3 py-2 bg-bme_blue text-white rounded-3xl focus:outline-none transition-all"
              >
                Post
              </button>
    

          </div>

          {/* Right side: Search */}
          <div className=" max-w-sm w-full mt-4 mx-auto sm:mt-0 ">
            <div className="relative ">
              {!searchQuery && (
                <CiSearch
                  style={{ fill: '#D1D5DB' }}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300"
                />
              )}
              <input
                type="text"
                className="w-full pl-12 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              />
              <>

              </>
            </div>
            <p className=" mt-2 text-right text-base text-gray-500">
              {filteredPosts?.length} posts found
            </p>{" "}
          </div>
        </div>

        {/* Render posts */}
        {filteredPosts.map((item, index) => (
          <RenderPostItem key={item.forum_id} item={item} index={index} />
        ))}

          {/* load more button */}
          {hasMorePosts && !loading && (
          <div className="flex justify-center mt-6">
            <button
              onClick={()=>{
                fetchPosts()
              }}
              className=" flex justify-center items-center px-4 py-2 bg-bme_blue text-white rounded-lg"
            >
             <SlReload style={{fill:'white'}} className="w-5 h-5 mr-2" /> Load more posts 
            </button>
          </div>
        )}

        {/* No posts found message */}
        {noPostsFound && (
          <div className="text-center py-4 text-xl text-gray-500">No posts found</div>
        )}
      </div>

        <CustomToastContainer/>
    </div>
  );
};

export default CompanyForumList;
