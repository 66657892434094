import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CiSearch } from "react-icons/ci";
import defaultImage from "../../../../assets/images/defaultImage.jpg";
import { AuthContext } from "../../../AuthContext";
import apiClientAdmin from "../../../ApiClientAdmin";
import { Helmet } from "react-helmet";
import Loader from "../../../Common/Loader";
import apiClient from "../../../ApiClient";
import { CustomToastContainer } from "../../../CustomToastContainer";

const CompanyList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [jobImageUrls, setJobImageUrls] = useState({});
  const [companies, setCompanies] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [userCompanyId, setUserCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("All"); 
  const fetchCompanies = async () => {
    setLoading(true);
    try {

      const res = await apiClientAdmin.post("/listAllCompanies_adminSide", {
        command: "listAllCompanies_adminSide",
        admin_approval: "All"
      });

      // const res = await apiClientAdmin.post("/listAllCompanies", {
      //   command: "listAllCompanies",
      // });

      // console.log("listAllCompanies", res);
      const companyData = res.data.response || [];
      // console.log(companyData)
      const sortedCompanies = companyData.sort((a, b) => {
        return new Date(b.company_created_on) - new Date(a.company_created_on);
      });


      setCompanies(sortedCompanies);
      setFilteredCompanies(sortedCompanies);

      const urlsObject = {};

      await Promise.all(
        companyData.map(async (company) => {
          if (company.fileKey) {
            try {
              const res = await apiClient.post(
                "/getObjectSignedUrl",
                {
                  command: "getObjectSignedUrl",
                  key: company.fileKey,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-api-key": "YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg",
                  },
                }
              );
              const img_url = await res.data;
              if (img_url) {
                urlsObject[company.company_id] = img_url;
              }
            } catch (error) {
              console.error("Error fetching image URL:", error);
            } finally {
              setLoading(false);
            }
          }
        })
      );

      setJobImageUrls(urlsObject);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  useEffect(() => {
    if (user) {
      setUserCompanyId(user.company_id); // Assuming user object has company_id
    }
    fetchCompanies();
  }, [user]);

  useEffect(() => {
    let results = companies;

    if (searchQuery) {
      results = companies.filter(
        (company) =>
          company.company_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          company.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
          company?.products?.some((product) =>
            product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          company?.services?.some((service) =>
            service.service_name.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          company.company_located_city
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          company.company_located_state
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
    }

    if (filter !== "All") {
      results = results.filter((company) => company.admin_approval === filter);
    }

    setFilteredCompanies(results);
  }, [companies, searchQuery, filter]);

  const handleSearch = () => {
    if (companies && companies.length > 0) {
      const results = companies.filter(
        (company) =>
          company.company_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          company.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
          company?.products?.some((product) =>
            product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          company?.services?.some((service) =>
            service.service_name.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          company.company_located_city
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          company.company_located_state
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
      setFilteredCompanies(results);
    }
  };

  const navigateToDetails = (company) => {
    const company_id = company.company_id
    navigate(`/admin-dashboard/company/details/${company_id}`)
  }

  const handleEditProfile = (companyId) => {
    if (companyId === userCompanyId) {
      navigate(`/admin-dashboard/profile`);
    } else {
      toast.error("You can only edit your own company profile.");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleApprove = (company) => {
    setLoading(true)
    apiClient.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/approveOrRejectCompany', {
      command: "approveOrRejectCompany",
      bme_Admin_email_id: "bmebharat@gmail.com",
      company_id: company.company_id,
      admin_approval: "Approved",
    })
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("This company Approved", `Approved: ${company.company_name}`);
        } else {
          toast.success(res.data.errorType, res.data.errorMessage);
        }
      }).finally(() => {
        setLoading(false)
      })
  };

  const handleReject = (company) => {
    setLoading(true)
    apiClient.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/approveOrRejectCompany', {
      command: "approveOrRejectCompany",
      bme_Admin_email_id: "bmebharat@gmail.com",
      company_id: company.company_id,
      admin_approval: "Rejected",
    })
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("This company Rejected", `Rejected: ${company.company_name}`);
          return deleteAccount(company);
        } else {
          toast.success(res.data.errorType, res.data.errorMessage);
        }
      }).finally(() => {
        setLoading(false)
      })
  };

  const deleteAccount = (company) => {
    setLoading(true)
    apiClient.post('/deleteAccount', {
      command: "deleteAccount",
      user_phone_number: company.company_contact_number,
    })
      .then((deleteResponse) => {
        if (deleteResponse.data.status === "success") {
          toast.success("Account Deleted", "The company account has been deleted successfully.");
        } else {
          toast.success(deleteResponse.data.errorType, deleteResponse.data.errorMessage);
        }
      })
      .catch((error) => {
        toast.success("Error", "Failed to delete the account.");
      }).finally(() => {
        setLoading(false)
      })
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="w-[100%] mx-auto py-2 px-4 mt-6">
      <Helmet>
        <title>BME Bharat | Companies</title>
        <meta
          property="og:title"
          content="BME Bharat | Companies"
        ></meta>
        <meta
          property="og:description"
          content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology."
        />
        <meta
          property="og:url"
          content="https://bmebharat.com/admin-dashboard/company/list"
        />
      </Helmet>

      <div className="flex justify-between mb-6">
        <div className="relative w-full max-w-sm">
          {!searchQuery && (
            <CiSearch
              style={{ fill: "#D1D5DB" }}
              className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300"
            />
          )}
          <input
            type="text"
            className="w-full pl-10 py-2 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="      Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          />
        </div>
        <div className="flex space-x-4">
          <button
            className={`custom-btn ${filter === "All" ? "bg-bme_blue text-white hover:bg-bme_blue hover:text-white" : ""}`}
            onClick={() => setFilter("All")}
          >
            All
          </button>
          <button
            className={`custom-btn ${filter === "Pending" ? "bg-bme_blue text-white hover:bg-bme_blue hover:text-white" : ""}`}
            onClick={() => setFilter("Pending")}
          >
            Pending
          </button>
          <button
            className={`custom-btn ${filter === "Approved" ? "bg-bme_blue text-white hover:bg-bme_blue hover:text-white" : ""}`}
            onClick={() => setFilter("Approved")}
          >
            Approved
          </button>
        </div>
      </div>

      {filteredCompanies.length > 0 ? (
        <>
          <p className=" mb-4 text-gray-600">
            {filteredCompanies.length} companies found
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
            {filteredCompanies.map((company) => (
              <div
                key={company.company_id}
                className="p-4 bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow"
              >
                <img
                  src={jobImageUrls[company.company_id] || defaultImage}
                  onContextMenu={(e) => e.preventDefault()}
                  alt={company.company_name}
                  className="w-full h-48 object-contain mb-4"
                />

                <div
                  className="flex flex-col"
                  onClick={() => navigateToDetails(company)}
                >
         
                  <div className="flex flex-col justify-start mb-2">
                    <span className="font-bold text-lg">{company.company_name.slice(0, 25) || "N/A"} {company.company_name.length > 25 && ".."}</span>
                    <span className="text-sm text-gray-500 -mt-1">{company?.category}</span>
                  </div>
                  <div className="space-y-2">
                    <div className="flex justify-start ">
                      <span className="font-bold w-20">Website</span>
                      <span>: {company.Website.slice(0, 22) || "N/A"} {company.Website.length > 22 && ".."} </span>
                    </div>

                    <div className="flex justify-start ">
                      <span className="font-bold w-20">City</span>
                      <span>: {company.company_located_city || "N/A"}</span>
                    </div>

                    <div className="flex justify-start mb-2">
                      <span className="font-bold w-20">State</span>
                      <span>: {company.company_located_state?.value || company.company_located_state || "N/A"}</span>
                    </div>
                  </div>
                  <p className="text-gray-600 mb-2">
                    {company?.company_description?.slice(0, 25) || "Description not available .. "} {company.company_description.length > 25 && ".."}

                  </p>

                  <div className="">
                    <button
                      className="custom-btn mt-4 mr-2"
                      onClick={() => navigateToDetails(company)}
                    >
                      View more
                    </button>
              
                    {company.company_id === userCompanyId && (
                      <button
                        className="custom-btn mt-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditProfile(company.company_id);
                        }}
                      >
                        Edit Profile
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="text-gray-500">No companies found</p>
      )}
      <CustomToastContainer/>
    </div>
  );
};

export default CompanyList;