import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import CompanyJobList from './Jobs/CompanyJobList';
import CompanyJobDetails from './Jobs/CompanyJobDetails';
import CompanyForumList from './Forum/CompanyForumList';
import CompanyForumDetails from './Forum/CompanyForumDetails';
import CompanyForumPost from './Forum/CompanyForumPost';

import CompanyProfile from './Profile/CompanyProfile';
import NavbarCompany from '../../Common/NavbarCompany';
import Footer from '../../Common/FooterCompany';
import UserHome from '../User/UserHome';
import PrivacyPolicy from '../Main/Guidelines-Page/PrivacyPolicy';
import Guidelines from '../Main/Guidelines-Page/Guidelines';
import About from '../Main/About-Page/About';
import Legal from '../Main/Guidelines-Page/Subscription'
import Terms from '../Main/Guidelines-Page/Refunds';
import Cancellation from '../Main/Guidelines-Page/Cancellation';

import UserComments from './Forum/UserComments';
import TermsAndConditions from '../Main/Guidelines-Page/Guidelines';
import CompanyJobPost from './Jobs/CompanyJobPost';

import CompanyMyPostedJobsEdit from './Profile/CompanyMyPostedJobsEdit';
import CompanyJobsAppliedList from './Profile/CompanyJobsAppliedList';
import CompanyMyPostedJobs from './Profile/CompanyMyPostedJobLists';
import CompanyGetAppliedJobList from './Profile/CompanyGetAppliedJobList';
import CompanyJobSeekers from './Profile/CompanyJobSeekers';
import CompanyGetJobseekerDetails from './Profile/CompanyGetJobseekerDetails';
import CompanyProfileUpdate from './Profile/CompanyProfileUpdate';
import CompanySubscription from './Profile/CompanySubscription';
import CompanySubscriptionList from './Profile/CompanySubscriptionList';
import CompanyList from './Company/CompanyList';
import CompanyDetails from './Company/CompanyDetails';
import MyCompanyForumList from './Profile/MyCompanyForumList';
import MyCompanyForumEdit from './Profile/MyCompanyForumEdit';
import { ToastContainer } from 'react-toastify';
import { AuthContext } from '../../AuthContext';
import apiClient from '../../ApiClient';
import { IoIosLogOut } from 'react-icons/io';
import DeleteAccount from './Profile/DeleteAccount';
import AccountChecker from '../../AccountStatusMonitor';
import ContactUs from '../../Common/ContactUs';
import { Helmet } from 'react-helmet';
import UserList from './Users/UsersList';
import UserDetails from './Users/UserDetails';
import LogoutLoader from '../../Common/LogoutLoader';
import { CustomToastContainer } from '../../CustomToastContainer';

function CompanyDashboard() {
  const [trialVisible, setTrialVisible] = useState(false);
  const { user, setUser } = useContext(AuthContext)
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');
  const navigate = useNavigate()
  const [isLoggingOut, setIsLoggingOut] = useState(false)


  useEffect(() => {
    const checkTrialStatus = async () => {
      try {
        const response = await apiClient.post("/getCompanyDetails", {
          command: "getCompanyDetails",
          company_id: user.company_id,
        });
        const { alert_message } = response.data.status_message;
        if (alert_message) {
          setTrialVisible(true);
          // console.log("true")
        } else {
          setTrialVisible(false);
        }
        const subscriptionExpiresOn = response.data.status_message.subscription_expires_on;
        // console.log("subscriptionExpiresOn",subscriptionExpiresOn)
        setFormattedDate(new Date(subscriptionExpiresOn * 1000).toLocaleString());
        const currentTime = Math.floor(Date.now() / 1000);
        // console.log("currentTime",currentTime)

        if (subscriptionExpiresOn < currentTime) {
          // setSubscriptionExpired(true);
          // console.log("setSubscriptionExpired(true)")
        }

      } catch (error) {
        console.error('Error fetching alert_message from API:', error);
        setTrialVisible(false);
      }
    };

    const checkUserSession = async () => {
      const session_id = localStorage.getItem("session_id")
      // console.log("session_id", session_id)
      try {
        const response = await apiClient.post("/checkUserSession", {
          command: "checkUserSession",
          session_id: session_id
        })
        // console.log(response)
        if (response?.data?.data?.isActive === false) {
          handleLogout()
        }

      } catch (error) {
        console.log("Error while check user Session", error)
      }
    }

    checkUserSession()
    checkTrialStatus();
    const intervalId = setInterval(() => {
      checkUserSession();
      checkTrialStatus();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [])

  const updateTrialStatus = async () => {
    try {
      const response = await apiClient.post("/updateCompanyProfile", {
        command: "updateCompanyProfile",
        company_id: user.company_id,
        alert_message: false,
      });
      if (response.status === 200) {
        setTrialVisible(false);
      }
    } catch (error) {
      console.error('Error updating alert_message to false:', error);
    }
  };

  const handleSubscribe = () => {
    navigate('/companysubscribe')
    setSubscriptionExpired(false)

  };

  const handleLogout = () => {
    setIsLoggingOut(true)
    setTimeout(() => {
      setIsLoggingOut(false)
      localStorage.removeItem("user");
      localStorage.removeItem("session_id");
      setUser(null);
      navigate("/homepage");
    }, 5000);
  };

  if (isLoggingOut) {
    return <LogoutLoader/>
  }

  return (
    <div>
      <Helmet>
        <title>BME Bharat | Company dashboard</title>
        <meta property="og:title" content="BME Bharat | Company dashboard"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard" />
      </Helmet>


      <AccountChecker />
      {/* bg-[#f6f6f5] */}

      <div className="main-content" >
        <NavbarCompany />

        <Routes>
          <Route path="/" element={<UserHome />} />

          <Route path="jobs" element={<CompanyJobList />} />
          <Route path="jobs/user-job-detail/:post_id" element={<CompanyJobDetails />} />
          <Route path="jobs/user-job-post" element={<CompanyJobPost />} />
          <Route path="forums" element={<CompanyForumList />} />
          <Route path="forums/details/:forum_id" element={<CompanyForumDetails />} />
          <Route path="forums/post" element={<CompanyForumPost />} />
          <Route path="profile" element={<CompanyProfile />} />
          <Route path="profile/subscription" element={<CompanySubscription />} />
          <Route path="profile/subscription-list" element={<CompanySubscriptionList />} />
          <Route path="profile/update" element={<CompanyProfileUpdate />} />
          <Route path="profile/my-forum-list" element={<MyCompanyForumList />} />
          <Route path="profile/my-forum-edit" element={<MyCompanyForumEdit />} />
          <Route path="profile/my-post-jobs" element={<CompanyMyPostedJobs />} />
          <Route path="profile/my-post-jobs-edit" element={<CompanyMyPostedJobsEdit />} />
          <Route path="profile/my-post-jobs-Apply" element={<CompanyJobsAppliedList />} />
          <Route path="profile/my-post-jobs-seekers-details/:user_id" element={<CompanyGetJobseekerDetails />} />
          <Route path="profile/my-post-jobs-seekers" element={<CompanyJobSeekers />} />
          <Route path="profile/my-post-jobs-Apply-details" element={<CompanyGetAppliedJobList />} />
          <Route path="about" element={<About />} />
          <Route path="guidelines" element={<Guidelines />} />
          <Route path="guidelines/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="guidelines/cancellation" element={<Cancellation />} />
          <Route path="guidelines/legalpolicy" element={<Legal />} />
          <Route path="guidelines/termsandconditions" element={<Terms />} />
          <Route path="company" element={<CompanyList />} />
          <Route path="forums/comments/:forum_id" element={<UserComments />} />
          <Route path="company/details/:company_id" element={<CompanyDetails />} />
          <Route path="company" element={<CompanyList />} />


          {/* Users List and details */}
          <Route
            path="users/list"
            element={<UserList />}
          ></Route>
          <Route
            path="users/user_details/:user_id"
            element={<UserDetails />}
          ></Route>
          {/* Users List and details */}

          {/* delete profile */}
          <Route path='profile/deleteaccount' element={<DeleteAccount />} />

          {/* contact-us */}
          <Route path='contact-us' element={<ContactUs />} />

        </Routes>
        <Footer />
        {trialVisible && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg max-w-sm w-full text-center">
              <div className="font-bold text-lg">🎉 Enjoy Your 15-Day Free Trial! 🎉</div>
              <div className="mt-2">Experience all the premium features of our app at no cost for 15 days. Dive in and explore everything we have to offer.</div>
              <button
                onClick={() => {
                  setTrialVisible(false)
                  updateTrialStatus()
                }}
                className="mt-4 w-full custom-btn">
                OK
              </button>
            </div>
          </div>
        )}
        {subscriptionExpired && (
          <div className="fixed inset-0 flex items-center justify-center bg-red-600 bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded shadow-lg mx-3 md:mx-0">
              <h2 className="text-lg font-bold "><span className="text-red-600 text-lg font-bold">Attention:</span> Subscription Expired</h2>
              <p>Your subscription expired on: {formattedDate}.<br></br> Don’t miss out! Renew now to keep enjoying uninterrupted service.</p>

              <div className="mt-2 flex ">
                <button
                  onClick={handleSubscribe}
                  className="custom-btn mr-2"
                >
                  Subscribe
                </button>
                <button
                  onClick={handleLogout}
                  className="flex items-center custom-btn text-red-600 justify-center"
                >
                  <IoIosLogOut className="mr-2" /> Logout
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomToastContainer/>
    </div>
  );
}

export default CompanyDashboard;

