import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Login from './components/Auth/Login';

import UsersDashboard from './components/Dashboard/User/UsersDashboard';
import CompanyDashboard from './components/Dashboard/Company/CompanyDashboard';

import LoginVerifyOtp from './components/Auth/LoginVerifyOtp';
import UserSignUp from './components/Auth/UserSignup';
import CompanySignup from './components/Auth/CompanySignUp';
import { AuthContext } from './components/AuthContext';
import ProfileType from './components/Auth/ProfileType';
import EnterPhone from './components/Auth/EnterPhone';
import VerifyOtp from './components/Auth/VerifyOtp';
import PrivacyPolicy from './components/Dashboard/Main/Guidelines-Page/PrivacyPolicy'
import Guidelines from './components/Dashboard/Main/Guidelines-Page/Guidelines'
import PdfViewer from './components/Common/PdfViewer';
import Loader from './components/Common/Loader';
import { ToastContainer } from 'react-toastify';
import UserSubscription from './components/Dashboard/User/Profile/UserSubscription';
import CompanySubscription from './components/Dashboard/Company/Profile/CompanySubscription';
import AccountChecker from './components/AccountStatusMonitor';
import ContactUs from "./components/Common/ContactUs"
import { Helmet } from 'react-helmet';
import { CustomToastContainer } from './components/CustomToastContainer';
import AdminDashboard from './components/Dashboard/Admin/AdminDashboard';
import UserHomeLoginPage from './components/Dashboard/User/UserHomeLoginPage';
import About from './components/Dashboard/Main/About-Page/About';
import Cancellation from './components/Dashboard/Main/Guidelines-Page/Cancellation';
import Refunds from './components/Dashboard/Main/Guidelines-Page/Refunds';
import Subscription from './components/Dashboard/Main/Guidelines-Page/Subscription';
import DeleteAccountGlobal from './components/Common/DeleteAccount';
import GlobalForumPage from './components/Global/GlobalForumPage';

function App() {
  const { user, setUser, loading } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [path, setPath] = useState('')

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setIsLoading(false);
  }, [setUser]);

  // navigate the shared llink based on user 
  useEffect(() => {
    const url = window.location.href;
    const dashboardIndex = url.indexOf("-dashboard");

    if (dashboardIndex !== -1) {
      const newUrl = url.slice(dashboardIndex);
      setPath(newUrl);
    } else {
      setPath("");
    }
  }, [window.location.href]);



  if (isLoading || loading) {
    return <div><Loader /></div>;
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content="BME Bharat" />
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://web.bmebharat.com" />
        <meta property="og:site_name" content="BME Bharat" />
      </Helmet>
      <CustomToastContainer />
      <Routes>

        {/* terms and condition Route */}
        <Route path='/guidelines' element={<Guidelines />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/cancellation' element={<Cancellation />} />
        <Route path='/termsandconditions' element={<Refunds />} />
        <Route path='/legalpolicy' element={<Subscription />} />

        <Route path='/usersubscribe' element={<UserSubscription />} />
        <Route path='/companysubscribe' element={<CompanySubscription />} />

        {/* contact page */}
        <Route path='/contact-us' element={<ContactUs />} />

        {/* global  */}
        <Route path='/delete_account' element={<DeleteAccountGlobal />} />
        <Route path='/Forum/:forum_id' element={<GlobalForumPage />} />

        {/* {path && path.length > 6 && (
          <Route
            path="/"
            element={
              user ? (
                user.user_type === 'users' ? (
                  <Navigate to={`user${path}`} />
                ) : user.user_type === 'company' ? (
                  <Navigate to={`company${path}`} />
                ) : (
                  <Navigate to={`admin${path}`} />
                )
              ) : (
                <Navigate to="/homepage" />
              )
            }
          />
        )} */}

        {/* Default route: main page */}
        <Route
          path="/"
          element={
            user ? (
              user.user_type === 'users' ? (
                <Navigate to="/user-dashboard" />
              ) : user.user_type === 'company' ? (
                <Navigate to="/company-dashboard" />
              ) : (
                <Navigate to="/admin-dashboard" />
              )
            ) : (
              <UserHomeLoginPage />
            )
          }
        />


        {/* homepage route */}
        <Route
          path="/"
          element={
            user ? (
              user.user_type === 'users' ? (
                <Navigate to="/user-dashboard" />
              ) : user.user_type === 'company' ? (
                <Navigate to="/company-dashboard" />
              ) : (
                <Navigate to="/admin-dashboard" />
              )
            ) : (
              <UserHomeLoginPage />
            )
          }
        />

        <Route
          path="/login"
          element={
            user ? (
              user.user_type === 'users' ? (
                <Navigate to="/user-dashboard" />
              ) : user.user_type === 'company' ? (
                <Navigate to="/company-dashboard" />
              ) : (
                <Navigate to="/admin-dashboard" />
              )
            ) : (
              <Login />
            )
          }
        />

        <Route
          path="/login-verify"
          element={
            user ? (
              user.user_type === 'users' ? (
                <Navigate to="/user-dashboard" />
              ) : user.user_type === 'company' ? (
                <Navigate to="/company-dashboard" />
              ) : (
                <Navigate to="/admin-dashboard" />
              )
            ) : (
              <LoginVerifyOtp />
            )
          }
        />

        {/* Profile Type Route */}
        <Route path="/profile-type" element={<ProfileType />} />

        {/* Enter Phone and OTP Verification */}
        <Route path="/enter-phone" element={<EnterPhone />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />

        {/* Signup Routes for User and Company */}
        <Route path="/signup-user" element={<UserSignUp />} />
        <Route path="/signup-company" element={<CompanySignup />} />

        {/* Admin Dashboard route */}
        <Route
          path="/admin-dashboard/*"
          element={user?.user_type === 'BME_ADMIN' || user?.user_type === 'BME_EDITOR' ? <AdminDashboard /> : <Navigate to="/" />}
        />

        {/* User Dashboard route */}
        <Route
          path="/user-dashboard/*"
          element={user?.user_type === 'users' ? <UsersDashboard /> : <Navigate to="/" />}
        />

        {/* Company Dashboard route */}
        <Route
          path="/company-dashboard/*"
          element={user?.user_type === 'company' ? <CompanyDashboard /> : <Navigate to="/" />}
        />

        <Route
          path="/about"
          element={<About />}
        />

        {/* Default fallback */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>

  );
}

export default App;