import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';


import CompanyForumList from './Forum/AdminForumList';
import CompanyForumDetails from './Forum/AdminForumDetails';
import CompanyForumPost from './Forum/AdminForumPost';
import UserComments from './Forum/UserComments';

import JobList from './Jobs/JobList';
import JobDetails from './Jobs/JobDetails';

import CompanyProfile from './Profile/AdminProfile';
import NavbarAdmin from '../../Common/NavbarAdmin';

import Footer from '../../Common/FooterCompany';
import UserHome from '../User/UserHome';


import AdminProfileUpdate from './Profile/AdminProfileUpdate';
import AdminHomeBanner from './Profile/AdminHomeBanner';

import CompanyList from './Company/CompanyList';
import CompanyDetails from './Company/CompanyDetails';

import MyCompanyForumList from './Profile/MyAdminForumList';
import MyCompanyForumEdit from './Profile/MyAdminForumEdit';

import { ToastContainer } from 'react-toastify';
import { AuthContext } from '../../AuthContext';
import { Helmet } from 'react-helmet';
import AllTransactionListScreen from './Profile/AllTransactionList';
import AdminAdsBanner from './Profile/AdminAdsBanner';
import AdminAdsBanner02 from './Profile/AdminAdsBanner02';
import AdminCustomNotifications from './Profile/AdminCustomNotifications';
import AdminUserDetails from './Users/UserDetails';
import AdminUserList from './Users/UsersList';
import { CustomToastContainer } from '../../CustomToastContainer';
import CreateUser from './Administrator/CreateUser';
import CreateCompany from './Administrator/CreateCompany';
import CreateJobPost from './Administrator/CreateJobPost';
import AdminPostedJobsEdit from './Jobs/AdminPostedJobsEdit'
import AdminPostedJobList from './Jobs/AdminPostedJobList';

function AdminDashboard() {

  return (
    <div>
      <Helmet>
        <title>BME Bharat | Admin dashboard</title>
        <meta property="og:title" content="BME Bharat | Admin dashboard"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://web.bmebharat.com/admin-dashboard" />
      </Helmet>

      <div className="main-content" >
        <NavbarAdmin />

        <Routes>
          <Route path="/" element={<UserHome />} />
          <Route path="forums" element={<CompanyForumList />} />
          <Route path="forums/details/:forum_id" element={<CompanyForumDetails />} />
          <Route path="forums/post" element={<CompanyForumPost />} />
          <Route path="profile" element={<CompanyProfile />} />
          <Route path="profile/transaction_list" element={<AllTransactionListScreen />} />
          <Route path="banners/home_banner" element={<AdminHomeBanner />} />
          <Route path="banners/ads_banner" element={<AdminAdsBanner />} />
          <Route path="banners/ads_banner02" element={<AdminAdsBanner02 />} />

          {/* Users List and details */}
          <Route
            path="users/list"
            element={<AdminUserList />}
          ></Route>
          <Route
            path="users/user_details/:user_id"
            element={<AdminUserDetails />}
          ></Route>
          {/* Users List and details */}

          <Route path="/send_notification" element={<AdminCustomNotifications />} />

          <Route path="profile/update" element={<AdminProfileUpdate />} />

          <Route path="profile/my-forum-list" element={<MyCompanyForumList />} />
          <Route path="profile/my-forum-edit" element={<MyCompanyForumEdit />} />

          <Route path="company" element={<CompanyList />} />
          <Route path="forums/comments/:forum_id" element={<UserComments />} />
          <Route path="company/details/:company_id" element={<CompanyDetails />} />
          <Route path="company" element={<CompanyList />} />

          {/* administrator */}
          <Route path="administrator/create_user" element={<CreateUser />} />
          <Route path="administrator/create_company" element={<CreateCompany />} />
          <Route path="administrator/create_job_post" element={<CreateJobPost />} />

          {/* job */}

          <Route path="jobs" element={<JobList />} />
          <Route path="jobs/user-job-detail/:post_id" element={<JobDetails />} />
          <Route path="jobs/admin_posted_jobs" element={<AdminPostedJobList />} />
          <Route path="jobs/edit_job" element={<AdminPostedJobsEdit />} />



        </Routes>
        <Footer />
      </div>
      <CustomToastContainer />

    </div>
  );
}

export default AdminDashboard;

