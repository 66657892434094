import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaComment, FaThumbsUp, FaShareAlt, FaEdit, FaImages } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { AuthContext } from '../AuthContext';
import apiClient from '../ApiClient';
import defaultImage from "../../assets/images/defaultImage.jpg"
import logo from "../../assets/images/logo-removebg-preview.png"
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Loader from '../Common/Loader';

const GlobalForumPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const user_id = user?.user_id || user?.company_id;
  const user_type = user?.user_type
  const { forum_id } = useParams()
  const [forum, setForum] = useState([]);
  const [post, setPost] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [fileKey, setFileKey] = useState();
  const [imageUrlsProfile, setImageUrlsProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [noPostsFound, setNoPostsFound] = useState(true);
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [latestPosts, setLatestPosts] = useState([]);
  const [imageUrl, setImageUrl] = useState()

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await apiClient.post("/getAllForumPosts", {
        command: "getAllForumPosts",
      });
      const Allforums = response.data.response
      setForum(Allforums)

      const forumData = [response.data.response.find(post => post?.forum_id === forum_id)];
      setPost(forumData);
      setNoPostsFound(forumData?.length ===  0 || forumData[0] === undefined);
      const urlsObject = {};
      const urlsObjectProfile = {};
      await Promise.all(
        forumData.map(async (post) => {
          if (post?.fileKey) {
            try {
              const res = await apiClient.post("/getObjectSignedUrl", {
                command: "getObjectSignedUrl",
                key: post?.fileKey,
              });
              const img_url = res.data;
              // console.log(img_url)
              if (img_url) {
                urlsObject[post?.forum_id] = img_url;
                setImageUrl(img_url)
              }
            } catch (error) {
              console.error("Error getting signed URL for post:", error);
            }
          }
          if (post?.author_fileKey) {
            try {
              const res = await apiClient.post("/getObjectSignedUrl", {
                command: "getObjectSignedUrl",
                key: post?.author_fileKey,
              });
              const img_url = res.data;
              // console.log(img_url)
              if (img_url) {
                urlsObjectProfile[post?.forum_id] = img_url;
              }
            } catch (error) {
              console.error("Error getting signed URL for post:", error);
            }
          }
        })
      );
      setImageUrls(urlsObject);
      setImageUrlsProfile(urlsObjectProfile);

    } catch (error) {
      console.log("Error fetching posts", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchTrendingPosts = () => {
    const trending = forum
      ?.filter(post => post.viewsCount > 10)
      ?.sort((a, b) => b.viewsCount - a.viewsCount)
      ?.slice(0, 5);
    setTrendingPosts(trending);
  };

  const fetchLatestPosts = () => {
    const latest = forum
      ?.slice(0, 5) // Get top 5 most recent posts
      ?.sort((a, b) => b?.posted_on - a?.posted_on);
    setLatestPosts(latest);
  };

  useEffect(() => {
    if (forum?.length > 0) {
      fetchTrendingPosts();
      fetchLatestPosts();
    }
  }, [forum]);

  const timeAgo = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp * 1000;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) return 'Just now';
    if (minutes < 60) return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    if (hours < 24) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (days < 30) return `${days} day${days > 1 ? 's' : ''} ago`;

    return new Date(timestamp * 1000).toLocaleDateString();
  };

  const handleShare = (postUrl) => {
    navigator.clipboard.writeText(postUrl).then(() => {
      toast.info("Link copied to clipboard!");
    }).catch((error) => {
      console.error("Failed to copy link: ", error);
    });
  };

  const incrementViewCount = async (forumId) => {
    try {
      const res = await apiClient.post('/forumViewCounts', {
        command: 'forumViewCounts',
        forum_id: forumId,
      });
    } catch (error) {
      console.error("Error incrementing view count", error);
    }
  };

  const navigateToUserDetails = (user_id, user_type_commentor) => {
      if (user_type) {
        if (user_type === 'users') {
          const path = user_type_commentor === 'company'
          ? `/user-dashboard/company/details/${user_id}`
          : `/user-dashboard/users/user_details/${user_id}`;
          return navigate(path)
        } else if (user_type === 'company') {
          const path = user_type_commentor === 'company'
          ? `/company-dashboard/company/details/${user_id}`
          : `/company-dashboard/users/user_details/${user_id}`;
          return navigate(path)
        } else {
          const path = user_type_commentor === 'company'
          ? `/admin-dashboard/company/details/${user_id}`
          : `/admin-dashboard/users/user_details/${user_id}`;
          return navigate(path)
        }
      }
      return navigate(`/login`)

    
  };

  const navigateToCommentsPage = (forum_id) => {
    if (user_type) {
      if (user_type === 'users') {
        return navigate(`/user-dashboard/forums/comments/${forum_id}`)
      } else if (user_type === 'company') {
        return navigate(`/company-dashboard/forums/comments/${forum_id}`)
      } else {
        return navigate(`/admin-dashboard/forums/comments/${forum_id}`)
      }
    }
    return navigate(`/login`)
  }

  const handleEditForum = (item, imageUrls) => {
    navigate("${window.location.origin}/user-dashboard/profile/my-forum-edit", {
      state: { post: item, imageUrls },

    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const navigateToForumPost =() => {
    if (user_type) {
      if (user_type === 'users') {
        return navigate(`/user-dashboard/forums`)
      } else if (user_type === 'company') {
        return navigate(`/company-dashboard/forums`)
      } else {
        return navigate(`/admin-dashboard/forums`)
      }
    }
    return navigate(`/login`)
  };

  if (loading) {
    return <Loader/>;
  }

  const RenderPostItem = ({ item }) => {
    const imageUrl = imageUrls[item?.forum_id];
    const imageUrlProfile = imageUrlsProfile[item?.forum_id];
    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm"];
    const isVideo = item?.fileKey && videoExtensions.some(ext => item?.fileKey?.endsWith(ext));
    const postUrl = `${window.location.origin}/Forum/${item?.forum_id}`;
    return (
      <div
        onClick={() => {
          incrementViewCount(item?.forum_id)
        }}
        className="w-full flex flex-col bg-white rounded-lg shadow-md mb-6"
      >
        <div className="flex p-4 border-b">

          <img
            src={imageUrlProfile || defaultImage}
            alt="Author image"
            onClick={() => {
              navigateToUserDetails(item?.user_id, item?.user_type)
            }}
            className="w-10 h-10 rounded-full object-cover "
          />
          <div className="ml-4 flex-1">
            <div className="flex justify-between">
              <div className="inline-flex flex-col justify-start mb-2 cursor-pointer" >
                <span onClick={(e) => {
                  e.stopPropagation()
                  navigateToUserDetails(item?.user_id, item?.user_type)
                }}
                  className="font-bold text-base">{item?.author || "N/A"}</span>
                <span onClick={(e) => {
                  e.stopPropagation()
                  navigateToUserDetails(item?.user_id, item?.user_type)
                }} className="text-sm text-gray-500 -mt-1">{item?.author_category}</span>
              </div>
              <div>

                {item?.user_id === user_id && (
                  <div className="flex space-x-1 items-end justify-end">
                    <button
                      className=" "
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditForum(item, imageUrl);
                      }}
                    >
                      <FaEdit className="w-5 h-5" />
                    </button>
                    {/* <button onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(item);
                    }} className="flex items-center">
                     <MdDelete className="w-6 h-6"  />
                    </button> */}
                  </div>
                )}
                <div className="text-sm text-gray-500">{timeAgo(item?.posted_on)}</div>
              </div>
            </div>

          </div>
        </div>


        {isVideo ? (
          <video
            src={imageUrl}
            alt={item?.title}
            className="w-full h-auto object-contain rounded-t-lg -mt-5 mb-3"
            controls
            muted
          />
        ) : imageUrl ? (
          <img
            src={imageUrl}
            alt={item?.title}
            onContextMenu={(e) => e.preventDefault()}
            className="w-full h-auto object-contain rounded-t-lg"
          />
        ) : null}


        <div className="p-4">
          <p style={{ whiteSpace: "pre-wrap" }}>
            {item?.forum_body}
          </p>
          <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
            {/* First Row - Views and Comments */}
            <div className="flex justify-between w-full sm:w-auto sm:space-x-6">
              <span>{item?.viewsCount || 0} Views</span>
              <div className="flex items-center space-x-3">
                <span onClick={() => navigateToCommentsPage(item?.forum_id)} className="no-underline cursor-pointer text-black flex items-center">
                  <FaComment className="mr-1" /> {item?.comments_count || 0} Comments
                </span>
              </div>
            </div>

            {/* Second Row - Share Buttons */}
            <div className="flex  space-y-3 sm:space-y-0 sm:space-x-4 items-center justify-center sm:ml-4 mt-3 sm:mt-0">


              <div className="flex space-x-2 items-center justify-center">
                <FaShareAlt onClick={() => {
                  const postUrl = `${window.location.origin}/Forum/${item?.forum_id}`;
                  navigator.clipboard.writeText(postUrl).then(() => {
                    toast.info("Link copied to clipboard!"); // Or use a toast notification
                  }).catch((error) => {
                    console.error("Failed to copy link: ", error);
                  });
                }} className="text-gray-600 cursor-pointer" />
                <FacebookShareButton url={postUrl} quote={item?.title}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <WhatsappShareButton url={postUrl} title={item?.title}>
                   <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TwitterShareButton url={postUrl} title={item?.title}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton url={postUrl} title={item?.title}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  };



  const postUrl = `${window.location.origin}/Forums/${post?.forum_id}`;

  return (
    <div className="flex flex-col md:flex-row">
      <Helmet>
        <title>{`BME Bharat | ${post?.forum_body?.slice(0, 25)}`}</title>
        <meta 
    property="og:image" 
    content={imageUrl || "https://bmebharat.com/logo_og(1200_630).png"} 
  />
        <meta
          property="og:title"
          content={`BME Bharat | ${post?.forum_body?.slice(0, 25)}`}
        />
        <meta
          property="og:description"
          content={post?.forum_body?.slice(0, 200)}
        />
        <meta
          property="og:url"
          content={postUrl}
        />
      </Helmet>


      {/* Left Sidebar: Login/SignUp */}

      
      <div className="w-full md:w-1/4 bg-gray-100 p-6 h-auto hidden md:block md:h-screen sticky top-0 space-y-6">
      {!user_id && (
        <>
        <div className="text-center">
          <img
            src={logo}
            alt="Logo"
            onContextMenu={(e) => e.preventDefault()}
            className="mx-auto mb-2 h-[100px]"
          />
          <h2 className="text-xl font-semibold mt-4">New to BME Bharat?</h2>
          <p className="mb-4">Sign up to participate in the discussion.</p>
          <button className="w-fit py-2 px-4 bg-bme_blue text-white rounded-lg" onClick={() => navigate('/login')}>Login</button>
          <button className="w-fit ml-2 py-2 px-4 border border-bme_blue bg-bme_blue text-white rounded-lg" onClick={() => navigate('/profile-type')}>Sign Up</button>
          <hr></hr>
        </div>
        </>
      )}
       
        <div>
    <h2 className="text-lg font-semibold mb-2">Trending Posts 🔥</h2>


    <div className="space-y-4 cursor-pointer">
      {trendingPosts.map((item) => (

        <div onClick={() => {navigateToForumPost() }} className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow">

          <div className="font-semibold text-sm text-gray-900">{item?.forum_body?.slice(0, 85)} {item?.forum_body?.length > 85 && ".."}</div>
          <div className="text-xs text-gray-500 mt-2">
            {item?.comments_count || 0} Comments | {item?.viewsCount || 0} Views
          </div>
        </div>
      ))}
    </div>
  </div>

  {/* Latest Posts */}

  <div>
    <h2 className="text-lg font-semibold mb-4">Latest Posts ℹ️</h2>
    <div className="space-y-4 cursor-pointer">
      {latestPosts.map((item) => (
        <div key={item?.forum_id} onClick={() => { navigateToForumPost()}} className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <div className="font-semibold text-sm text-gray-900">{item?.forum_body?.slice(0, 85)} {item?.forum_body?.length > 85 && ".."}</div>
          <div className="text-xs text-gray-500 mt-2">
            {item?.comments_count || 0} Comments | {item?.viewsCount || 0} Views
          </div>
        </div>
      ))}
    </div>
  </div>
</div>
     

      {/* Main Content: Post Details */}
      <div className="w-full md:w-3/4 p-6">

      {!user_id && (
        <>
        <div className="text-center md:hidden">
          <img
            src={logo}
            alt="Logo"
            onContextMenu={(e) => e.preventDefault()}
            className="mx-auto mb-2 h-[100px]"
          />
          <h2 className="text-xl font-semibold mt-4">New to BME Bharat?</h2>
          <p className="mb-4">Sign up to participate in the discussion.</p>
          <button className="w-fit py-2 px-4 bg-bme_blue text-white rounded-lg" onClick={() => navigate('/login')}>Login</button>
          <button className="w-fit ml-2 py-2 px-4 border border-bme_blue bg-bme_blue text-white rounded-lg" onClick={() => navigate('/profile-type')}>Sign Up</button>
          <hr></hr>
        </div>
        </>
      )}

      {/* create post text area  */}
      <div onClick={() => {
          }} className="flex relative items-center space-x-4 w-full max-w-xl bg-white p-0 mb-2 mx-auto">
            <textarea
              className="w-full p-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
              placeholder="Write your post here..."
              rows="1"
            />
            <button
              onClick={() => navigateToForumPost()}
              className="hidden lg:flex items-center absolute right-20 justify-center px-2 py-2 bg-bme_blue text-white rounded-lg  focus:outline-none transition-all"
            >
              Import media <FaImages className="font-bold ml-2" style={{ fill: 'white' }} />
            </button>
            {/* small screen */}
            <button
              onClick={() => navigateToForumPost()}
              className="flex lg:hidden items-center absolute right-2 justify-center px-3 py-2 bg-bme_blue text-white rounded-3xl  focus:outline-none transition-all"
            >
              Import <FaImages className="font-bold ml-2" style={{ fill: 'white' }} />
            </button>
            <button
              onClick={() => navigateToForumPost()}
              className="hidden lg:flex absolute right-2 items-center justify-center px-3 py-2 bg-bme_blue text-white rounded-3xl focus:outline-none transition-all"
            >
              Post
            </button>
          </div>
      {/* create post text area  */}


        {!noPostsFound && post?.map((item) => (
          <RenderPostItem key={item?.forum_id} item={item} />
        ))}
        {noPostsFound && (
          
          <div className='flex flex-col justify-center items-center h-[80vh]'>
          <div className="text-center py-3 text-xl text-gray-500">Oops, no post found😓</div>
          <button className="w-fit py-2 px-4 bg-bme_blue text-white rounded-3xl" onClick={() => navigate('/login')}>Create a post</button></div>

        )}
      </div>
 
    </div>
  );
};

export default GlobalForumPage;
